import React, { useEffect, useState } from "react";
import { EmptyData } from "./EmptyData";
import {message, Modal} from "antd";
import { Loading } from "./Loading";
import { useDispatch, useSelector } from "react-redux";
import { MessageTwoTone} from "@ant-design/icons";
import { toolsAction } from "../../Tools/state/actions";
import axios from "axios";

const EmailTemplateModal = ({ Email_templatevisible, setEmail_templatevisible, handleselectemail, handleEmail_templateCancel }) => {
  const dispatch = useDispatch();
  const { getEmail_TemplateData, getEmail_TemplateLoading, getEmail_TemplatesError, getEmail_TemplateErrorMsg } = useSelector((state) => state.ToolReducers.DocumentReducer);
  const [Email_templateCount, setEmail_templateCount] = useState(8);
  let handleLoadMore = () => {
    setEmail_templateCount(Email_templateCount + 6);
  };

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(()=>{
    if(Email_templatevisible){
      dispatch(toolsAction.getEmail_Template({unmounted, source}));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  },[Email_templatevisible])

  return (
    <>
      <Modal width={800} size="100px" visible={Email_templatevisible} onCancel={handleEmail_templateCancel} footer={false}>
        {getEmail_TemplateLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "70px",
            }}
          >
            <Loading />
          </div>
        ) : getEmail_TemplatesError ? "" : (
          <div
            style={{
              width: "100%",
              // height: "calc(100vh - 260px)",
              maxHeight: "800px",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "#fff",
              position: "relative",
              zIndex: "30",
              marginTop: "20px",
            }}
            onScroll={(e) => {
              if (Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) < 800) {
                handleLoadMore();
              }
            }}
          >
            {getEmail_TemplateData?.length > 0 ? (
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <table
                  className="licenseTable"
                  style={{
                    marginTop: "-5px",
                  }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: -2,
                      left: "0px",
                      zIndex: 100,
                      height: "50px",
                    }}
                  >
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getEmail_TemplateData?.map((el, i) => (
                      <tr key={el._id} style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleselectemail(el.name)}>
                        <td
                          style={{
                            fontWeight: "bolder",
                            width: "20%",
                            textAlign: "center",
                          }}
                        >
                          {el.title}
                        </td>
                        <td
                          style={{
                            width: "60%",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: el.name }}
                            style={{
                              textOverflow: "ellipsis",
                              overflowY: "auto",
                              maxHeight: "200px",
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80%",
                      textAlign: "center",
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <MessageTwoTone style={{ fontSize: "24px" }} />
                    </div>
                    <div>
                      <p style={{ color: "#1890ff", fontSize: "18px", fontWeight: "bold", marginBottom: "15px", textDecoration: "underline" }}>
                        No Email Templates Found!
                      </p>
                      <p style={{ fontSize: "16px", color: "#777" }}>
                      Currently, there are no email templates available. Please navigate to the 'Tools' section to create an email template.
                      </p>
                    </div>
                  </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmailTemplateModal;
