/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { accountHandle } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin, Tag, message } from 'antd';
import styles from "../styles/Delegates.module.css";

export default function DelegateTo({setDelegateSelected, handlePartialDelegate}) {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [delegateTo, setDelegateTo] = useState([]);
    const { getDelegateData, getDelegateDataLoading, getDelegateDataError, accessData } = useSelector((state) => state.account);

    const activeKey = (pathname === "/account/delegates") ? "1" : "2";
    useEffect(()=>{
        if(activeKey === "2"){
            dispatch(accountHandle.getDelegateData("DELEGATES_TO"))
        }
    },[pathname, accessData]);

    useEffect(() => {
        let postDelegateTo = [];
        if(getDelegateData?.delegateTo?.length){
            getDelegateData?.delegateTo?.forEach((element) => {
            postDelegateTo.push({
                userDetails: {
                fullName: element?.fullName,
                email: element?.email,
                profileImg: element?.profileImg
                },
                _id: element._id,
                delegateAssignedId: element?.assignedDetails?._id,
                switchAccount: element?.assignedDetails?.isUsingAccount,
                officeName: element?.brokerageData?.brokerageName || "-",
                specificTransaction: element?.assignedDetails?.specificTransaction,
                action: element?.assignedDetails?.isUsingAccount,
            });
            });
            setDelegateTo(postDelegateTo);
        }
    }, [getDelegateData]);

    const goToAccount = (data) => {
        // confirm({
        //   title: `Are you sure you want to go to the account of ${data.userDetails.fullName}?`,
        //   content: "Note: You will be logged out from your current account",
        //   okText: "Yes",
        //   cancelText: "No",
        //   icon: <ExclamationCircleOutlined />,
        //   onOk() {
            dispatch(accountHandle.loginAnotherAccount({ delegateOwnerId: data._id}));
        //   },
        //   onCancel() {},
        // });
    };

    const handleExitAccount = (data) => {
        // confirm({
        //   title: `Are you sure you want to exit from account of ${data.userDetails.fullName}?`,
        //   content: "",
        //   okText: "Yes",
        //   cancelText: "No",
        //   icon: <ExclamationCircleOutlined />,
        //   onOk() {
            dispatch(accountHandle.updateAccessProfile({removeAdditionalAccount: data._id}));
        //   },
        //   onCancel() {},
        // });
    };

    const handleAccessAccount = (data) => {
        // confirm({
        //   title: `Are you sure you want to go to the account of ${data.userDetails.fullName}?`,
        //   content: "Note: You will be logged out from your current account",
        //   okText: "Yes",
        //   cancelText: "No",
        //   icon: <ExclamationCircleOutlined />,
        //   onOk() {
            dispatch(accountHandle.updateAccessProfile({delegateOwnerId: data._id,}, true));
        //   },
        //   onCancel() {},
        // });
    };

    return (
        <div>
            {getDelegateDataLoading ?(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Spin size="large"/>
                </div>
            ):getDelegateDataError ? (
                <div>
                    { message.error("Oops!. Please try again later.")}
                </div>
            ):(
                <div className={styles.tableContainer}>
                    <table
                        style={{
                            borderCollapse: "collapse",
                            border: "1px solid #bfbfbf"
                        }}
                    >
                        <thead
                            style={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                                zIndex: 100
                            }}
                        >
                            <tr>
                                <th
                                style={{
                                    width: "15%",
                                    background: "#2171B9",
                                    color: "whitesmoke",
                                }}
                                >
                                USERS
                                </th>
                                <th
                                style={{
                                    width: "15%",
                                    background: "#2171B9",
                                    color: "whitesmoke",
                                }}
                                >
                                OFFICE NAME
                                </th>
                                <th
                                style={{
                                    width: "15%",
                                    background: "#2171B9",
                                    color: "whitesmoke",
                                }}
                                >
                                STATUS
                                </th>
                                <th
                                style={{
                                    width: "15%",
                                    background: "#2171B9",
                                    color: "whitesmoke",
                                }}
                                >
                                ACTION
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {delegateTo?.map((el, ind) => (
                                <tr key={ind}>
                                <td>
                                    <div>
                                    <div
                                        style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "grey",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        }}
                                    >
                                        <p>{el?.userDetails?.fullName}</p>
                                        {!el?.specificTransaction ? (
                                        <Tag
                                            color="green"
                                            style={{
                                            height: "25%",
                                            width: "25%",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setDelegateSelected(el?.userDetails);
                                                handlePartialDelegate(el?._id, "delegateOwnerId")
                                            }}
                                        >
                                            Full
                                        </Tag>
                                        ) : (
                                        <Tag
                                            color="blue"
                                            style={{
                                            height: "25%",
                                            width: "25%",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setDelegateSelected(el?.userDetails);
                                                handlePartialDelegate(el?._id, "delegateOwnerId")
                                            }
                                            }
                                        >
                                            Limited
                                        </Tag>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                        fontSize: "11px",
                                        color: "#787474",
                                        marginTop: "-5%",
                                        }}
                                    >
                                        {el?.userDetails?.email}
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                    <div
                                        style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "grey",
                                        textAlign: "center",
                                        }}
                                    >
                                        {el?.officeName}
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ textAlign: "center" }}>
                                    {el?.switchAccount ? (
                                        <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                        >
                                        <div style={{ fontSize: "14px" }}>
                                            <p
                                            style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                paddingTop: "5px",
                                            }}
                                            >
                                            ACTIVE
                                            </p>
                                        </div>
                                        <Button
                                            type="primary"
                                            ghost
                                            onClick={() => goToAccount(el)}
                                            style={{ fontSize: "14px", cursor: "pointer" }}
                                        >
                                            Go to account{" "}
                                            <span style={{ marginLeft: "5px" }}>
                                            &#10148;
                                            </span>
                                        </Button>
                                        </div>
                                    ) : (
                                        <div style={{ fontSize: "14px" }}>
                                        <p style={{ color: "grey", fontWeight: "bold" }}>
                                            INACTIVE
                                        </p>
                                        </div>
                                    )}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ textAlign: "center" }}>
                                    {el?.action ? (
                                        <Button
                                            type="danger"
                                            style={{ fontSize: "14px", cursor: "pointer" }}
                                            onClick={() => handleExitAccount(el)}
                                        >
                                            Logout
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            onClick={() => handleAccessAccount(el)}
                                            style={{ fontSize: "14px", cursor: "pointer" }}
                                        >
                                            Access Account
                                        </Button>
                                    )}
                                    </div>
                                </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
