import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function PaymentSuccess() {
  const history = useHistory();
  const handleHomeClick = () => {
    history.push("/");
  };

  return (
    <Result
      status="success"
      title={"Congratulations! subscription has been Created."}
      subTitle={"Please check you email for the invoice of this transcation."}
      extra={
        <Button type="primary" key="console" onClick={handleHomeClick}>
          Home
        </Button>
      }
    />
  );
}

export { PaymentSuccess };
