export const bugListingPageConstants = {
  BUG_LISTING_PAGE_LOADING: "BUG_LISTING_PAGE_LOADING",
  BUG_LISTING_PAGE_SUCCESS: "BUG_LISTING_PAGE_SUCCESS",
  BUG_LISTING_PAGE_FAILURE: "BUG_LISTING_PAGE_FAILURE",

  BUG_LISTING_PAGE_WITHOUT_SUCCESS: "BUG_LISTING_PAGE_WITHOUT_SUCCESS",
  BUG_LISTING_PAGE_WITHOUT_FAILURE: "BUG_LISTING_PAGE_WITHOUT_FAILURE",

  BUGS_LOGIN_UPDATE_PAGE: "BUGS_LOGIN_UPDATE_PAGE",
  BUGS_LOGIN_UPDATE_PAGE_FAIL: "BUGS_LOGIN_UPDATE_PAGE_FAIL",

  BUG_LIKE__SUCCESS: "BUG_LIKE__SUCCESS",
  BUG_LIKE_FAILURE: "BUG_LIKE_FAILURE",

  BUG_COMMENT__SUCCESS: "BUG_COMMENT__SUCCESS",
  BUG_COMMENT_FAILURE: "BUG_COMMENT_FAILURE",

  GET_BUG_COMMENTS_LOADING: "GET_BUG_COMMENTS_LOADING",
  GET_BUG_COMMENTS_SUCCESS: "GET_BUG_COMMENTS_SUCCESS",
  GET_BUG_COMMENTS_ERROR: "GET_BUG_COMMENTS_ERROR",

  SEARCH_BUG:"SEARCH_BUG",

  MY_BUG_LISTING_PAGE__LOADING: "MY_BUG_LISTING_PAGE__LOADING",
  MY_BUG_LISTING_PAGE__SUCCESS: "MY_BUG_LISTING_PAGE__SUCCESS",
  MY_BUG_LISTING_PAGE_FAILURE: "MY_BUG_LISTING_PAGE_FAILURE",

  POST_BUG_SUCCESS: "POST_BUG_SUCCESS",
  POST_BUG_FAILURE: "POST_BUG_FAILURE",
  POST_BUG_LOADING: "POST_BUG_LOADING",

  RESET_LISTING_PAGE: "RESET_LISTING_PAGE",
  RESET_IMAGE_UPLOAD_PAGE: "RESET_IMAGE_UPLOAD_PAGE",
  RESET_BUG: "RESET_BUG",
  RESET_SEARCH:"RESET_SEARCH",

  LOGIN_UPDATE_PAGE: "LOGIN_UPDATE_PAGE",
  LOGOUT_PAGE: "LOGOUT_PAGE",

  DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",

  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  RENDER_COMPONANT_LOADING:"RENDER_COMPONANT_LOADING",
  RENDER_COMPONANT_SUCESS:"RENDER_COMPONANT_SUCESS",
  RENDER_COMPONANT_FAILURE:"RENDER_COMPONANT_FAILURE",

  SUGGESTIONS_DATA_LOADING:"SUGGESTIONS_DATA_LOADING",
  SUGGESTIONS_DATA_SUCCESS:"SUGGESTIONS_DATA_SUCCESS",
  SUGGESTIONS_DATA_ERROR:"SUGGESTIONS_DATA_ERROR",

  SUPPORT_DATA_LOADING:"SUPPORT_DATA_LOADING",
  SUPPORT_DATA_SUCCESS:"SUPPORT_DATA_SUCCESS",
  SUPPORT_DATA_ERROR:"SUPPORT_DATA_ERROR",

  FILTERED_DATA_LOADING : "FILTERED_DATA_LOADING",
  FILTERED_DATA_SUCCESS : "FILTERED_DATA_SUCCESS",
  FILTERED_DATA_ERROR : "FILTERED_DATA_ERROR",
  RESET_FILTERED_DATA : "RESET_FILTERED_DATA",

  CHANGE_FILTER_STATUS: "CHANGE_FILTER_STATUS",
  CHANGE_FILTER_LIKE: "CHANGE_FILTER_LIKE",
  CHANGE_FILTER_PRIORITY: "CHANGE_FILTER_PRIORITY",
  CHANGE_FILTER_START_DATE: "CHANGE_FILTER_START_DATE",
  CHANGE_FILTER_END_DATE: "CHANGE_FILTER_END_DATE",

  GET_USERS_DATA_LOADING : "GET_USERS_DATA_LOADING",
  GET_USERS_DATA_SUCCESS : "GET_USERS_DATA_SUCCESS",
  GET_USERS_DATA_ERROR : "GET_USERS_DATA_ERROR",

  RESET_ALL_DATA : "RESET_ALL_DATA",
  RESET_CMNTS_DATA:"RESET_CMNTS_DATA"
};
