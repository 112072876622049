import { useDispatch, useSelector } from "react-redux";
import { pricingPageAction } from "../state/action";
import { Menu } from "antd";
import { GrView } from "react-icons/gr";
export const MenuItem = ({setPlanDetailsModalVisible}) => {
    const dispatch = useDispatch();
    const { getUserDetailsData, } = useSelector(
        (state) => state.account
    );
    const handleViewTransactionHistory = () => {
        dispatch(
          pricingPageAction.viewTransHistoryFun({
            email: getUserDetailsData?.email,
          })
        );
      };
    return (
      <Menu size="large">
        <Menu.Item
          icon={
            <GrView
              style={{
                color: "hsl(211deg 77% 48%)",
              }}
            />
          }
          key="trans"
          onClick={() => setPlanDetailsModalVisible(true)}
        >
          View Subscription Details
        </Menu.Item>
      </Menu>
    );
  };