import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Logo from "../../Assets/Icons/newLogo.png";
import { Link } from "react-router-dom";
import {
  Avatar,
  Menu,
  Dropdown,
  Typography
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "../styles/Navbar.module.css";
import dotEnv from "dotenv";
import { appStatusChange } from "../state/actions";
import { logoutAndInWithBroadcast } from "./Broadcast";
import { generateInitialsFromObj } from "../../Common/utils/extraFunctions";
dotEnv.config();
const { Text } = Typography;

/**
 * @function ClientNavbar
 * @returns {Object} JSX.element
 * Navbar component which is rendered for all protected routes or redirects if user is not logged in
 */
function ClientNavbar({source, urlData}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getUserDetailsData } = useSelector((state) => state.account);

  const logout = () => {
    localStorage.removeItem("clientAuth");
    localStorage.removeItem("clientToken");
    dispatch(appStatusChange.setClientscreenLogoutFun());
    logoutAndInWithBroadcast();
  };


  const menu = (
    <>
      {(
        <Menu>
          <Menu.Item key="0" disabled style={{ fontSize: "16px" }}>
            Hi{" "}
            {source === 'ReadOnlyClientScreen' ? (urlData?.fullName || '') : getUserDetailsData && getUserDetailsData?.firstName
              ? getUserDetailsData?.firstName
              : ""}
          </Menu.Item>
          {source !== 'ReadOnlyClientScreen' && getUserDetailsData?.currentWebVersion && <Menu.Item key="9" style={{ fontSize: "16px" }}>
            Ver {getUserDetailsData?.currentWebVersion}
          </Menu.Item>}
          {source !== 'ReadOnlyClientScreen' && <Menu.Item key="3" onClick={logout} style={{ fontSize: "16px" }}>
            Logout
          </Menu.Item>}
        </Menu>
      )}
    </>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "hsl(208deg 89% 30%)",
          height: "50px",
          lineHeight: "50px",
          padding: "10px 30px 10px 30px",
          position: "sticky",
          top: 0,
          zIndex: 10,
          width: "100%",
          transition: "all .5s ease-in-out",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            transition: "all .5s ease-in-out",
            color: "rgb(78, 115, 223)",
          }}
        >
          {/* <Link> */}
            <img
              style={{ width: "90px", height: "50px", paddingBottom: "5px" }}
              src={Logo}
              alt="Logo"
            />
          {/* </Link> */}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#fff",
          }}
        >
            <Dropdown
                key="4"
                overlay={menu}
                trigger={["click", "hover"]}
                style={{ border: "1px solid white" }}
            >
                <span style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    <Text
                        key="7"
                        className={styles.realterName}
                        style={{ color: "white",maxWidth:"500px" }}
                        ellipsis
                    >
                        <span style={{ fontWeight: 500 }}>
                        {(getUserDetailsData && getUserDetailsData?.fullName) || (source === 'ReadOnlyClientScreen' ? (urlData?.fullName || '') : "")}
                        </span>
                    </Text>
                    {getUserDetailsData &&
                      getUserDetailsData?.profileImg &&
                      getUserDetailsData?.profileImg?.original
                        ? <Avatar
                        key="5"
                        src={getUserDetailsData?.profileImg?.original}
                        style={{ cursor: "pointer",marginLeft:"-5px" }}
                        size="large"
                        icon={<UserOutlined style={{ fontSize: "24px" }} />}
                      /> : <Avatar key="5" style={{ cursor: "pointer",marginLeft:"-5px",color:"#085190",border:"1px solid #085190",fontSize:"18px" }}
                        size="large">
                      {generateInitialsFromObj(source === 'ReadOnlyClientScreen' ? urlData : getUserDetailsData)}
                      </Avatar>
                    }
                </span>
            </Dropdown>
        </div>
      </div>
    </>
  );
}
export { ClientNavbar };
