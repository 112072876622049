import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { transactionLaneDataHandle } from '../state/actions';
import { Loading } from '../../Common/components/Loading';
import { PlusOutlined, CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, message, Modal, Popconfirm, Tooltip, Typography } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import imageCompression from 'browser-image-compression';
import axios from 'axios';

const CompanyTitle = ({ setCompanyModalStep, companyModalStep}) => {
  const [addCompany, setAddCompany] = useState(false);
  const [bufferData, setBufferData] = useState();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { getTitleCompanyData, getTitleCompanyLoading, getTitleCompanyError }=useSelector(
    (state) => state.rtdTransactionLane
  );

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(()=>{
    dispatch(transactionLaneDataHandle.getTitleCompany({type: "COMPANY", unmounted, source}));
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [])

  const handleSelectedValues=(value)=>{
    setCompanyModalStep({
      ...companyModalStep,
      titleCompany: value,
      inBuilt: true,
      titleOffice: "",
      titleCloser: "",
      salesRep: ""
    })
  }

  const reset = (event)=>{
    event.stopPropagation();
    setCompanyModalStep({
      ...companyModalStep,
      titleCompany: "",
      inBuilt: true,
      titleOffice: "",
      titleCloser: "",
      salesRep: ""
    })
  }

  const handleAdd = () => {
    setAddCompany(!addCompany)
    setBufferData(null);
  }

  async function compressImage(file) {
    const maxSizeMB = 3; 
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size <= maxSizeBytes) {
      return file;
    }
  
    const initialQuality = 0.7;
    let currentQuality = initialQuality;
  
    
    while (true) {
      try {
        const compressedFile = await imageCompression(file, { quality: currentQuality });
        if (compressedFile.size <= maxSizeBytes || currentQuality <= 0.1) {
          return compressedFile;
        }
        currentQuality -= 0.1;
      } catch (error) {
        throw error;
      }
    }
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = async (data) => {
    const image = await compressImage(data.file)
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      const newFile = {
        name: data.file.name,
        size: bytesToSize(image.size),
        file: reader.result,
        origin: "notBase64",
      };
      setBufferData(newFile);
    };
    reader.onerror = (error) => {
      return error;
    };
  };

  const onFinish = (values) => {
    setCompanyModalStep({
      ...companyModalStep,
      inBuilt: false,
      titleCompany: {
        name: values?.companyName,
        officeLogo: bufferData?.file || ""
      },
      titleOffice: "",
      titleCloser: "",
      salesRep: ""
    })
    setAddCompany(!addCompany)
  }
 
  return (
    <div style={{width:"100%",margin:"auto",textAlign:"center"}}>
      {
        getTitleCompanyLoading ? (
          <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Loading />
          </div>
        ) : 
        (
            getTitleCompanyError ? "" :
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: "10px",
                }}>
                    {getTitleCompanyData?.map((element, index) => {
                        return (
                        <div
                            style={{
                            height: "130px",
                            cursor :"pointer", 
                            padding:"10px",
                            borderRadius:"8px",
                            background: (companyModalStep?.titleCompany?._id == element?._id)  ? "rgb(132, 187, 238)" : "white"
                            }}
                            onClick={() => handleSelectedValues(element)}
                        >
                            {
                            (companyModalStep?.titleCompany?._id == element?._id) && <div style={{position: "absolute", marginTop: "-4px", marginLeft: "170px"}}>
                                <CloseCircleOutlined
                                style={{ fontSize: "20px",  }}
                                onClick={reset}
                                />
                            </div>
                            }
                            <img
                            src={element.officeLogo}
                            style={{
                                minWidth: "100px",
                                minHeight: "100px",
                                objectFit: "contain",
                                maxWidth: "100%",
                                maxHeight:"110px"
                            }}
                            alt=""
                            />
                        </div>
                        );
                    })}
                    {
                        companyModalStep?.inBuilt ? <div
                        style={{
                            height: "120px",
                            padding:"20px",
                            borderRadius:"8px"
                        }}
                        >
                        <Tooltip title="Add Company">
                            <PlusOutlined style={{cursor :"pointer", fontSize: "30px", padding: "35px", border: "1px dashed grey"}} onClick={handleAdd}/>
                        </Tooltip>
                        </div> :
                        <div
                        style={{
                            height: "120px",
                            cursor :"pointer", 
                            borderRadius:"8px",
                            background: "rgb(132, 187, 238)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        >
                        <div style={{position: "absolute", marginTop: "-80px", marginRight: "-170px"}}>
                            <CloseCircleOutlined
                            style={{ fontSize: "20px",  }}
                            onClick={reset}
                            />
                        </div>
                        {
                            companyModalStep?.titleCompany?.officeLogo ? <img
                            src={companyModalStep?.titleCompany?.officeLogo}
                            style={{
                                minWidth: "100px",
                                minHeight: "100px",
                                padding:"20px",
                                objectFit: "contain",
                                maxWidth: "100%",
                                maxHeight:"110px"
                            }}
                            alt={companyModalStep?.titleCompany?.name}
                            /> : <Text style={{ fontSize: "20px" }}>{companyModalStep?.titleCompany?.name}</Text>
                        }
                        </div>
                    }
                </div>
        )
      }
      {
        addCompany && <Modal
          title={<div>Add Title Company</div>}
          open={true}
          onCancel={handleAdd}
          style={{ marginTop: "-50px" }}
          footer={false}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label={<div style={{fontSize: "15px"}}>Enter Company Name</div>}
              name="companyName"
              rules={[{ required: true, message: "Please Enter Company Name!" }]}
            >
              <Input placeholder="Enter Company Name" allowClear autoFocus/>
            </Form.Item>
            <Form.Item
              label={<div style={{fontSize: "15px"}}>Add Company Image</div>}
            >
              {bufferData ? (
                <div
                  style={{
                    display: "flex",
                    maxWidth: "100%",
                    overflow: "auto",
                    minHeight: "100px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <Image
                      width={100}
                      src={bufferData.file}
                      preview={{
                        visible: false,
                        mask: (
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              zIndex: 21,
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <Popconfirm
                                title="Are you sure you want to delete this image?"
                                onConfirm={() => {
                                  setBufferData(null);
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <CloseCircleOutlined
                                  style={{ fontSize: "30px" }}
                                />
                              </Popconfirm>
                            </div>
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <Dragger
                  accept="image/*"
                  showUploadList={false}
                  customRequest={handleCustome}
                >
                  <div style={{ height: "100px" }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined
                        style={{ position: "relative", top: "-25px" }}
                      />
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ position: "relative", top: "-55px" }}
                    >
                      Click or drag file to this area to upload
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ position: "relative", top: "-55px" }}
                    >
                      Strictly prohibit from uploading company data or other banned files
                    </p>
                  </div>
                </Dragger>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: "100%"
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
    </div>
  )
}
export {CompanyTitle}