import { userSignUpConstants } from "./action-types";

const initialState = {
  userAuth: null,
  userSignupError: null,
  userSignupLoading: false,
  userSignupData: null,
  userLogInData: null,
  userLogInLoading: false,
  userSessionId: null,
  showNotificationPopUp: false,

  userForgetPasswordData: null,
  userForgetPasswordDataEmail: null,
  userForgetPasswordLoading: false,
  userForgetPasswordError: null,

  verificationData: null,
  verificationLoading: false,
  verificationError: null,

  resetPasswordNewPasswordData: null,
  resetPasswordNewPasswordLoading: false,
  resetPasswordNewPasswordError: false,

  userLogInError: null,
  roleType: null,

  auth: null,
  authError: null,
  userData: null,
  authLoading: false,
  username: null,
  currentUser: null,
  alert: "",

  associationDataLoading : false,
  associationDataError : false,
  associationData : null,

  getPricesDataLoading: false,
  getPricesDataError: false,
  getPricesData: null,
};

const userSignup = (state = initialState, action) => {
  switch (action.type) {
    case userSignUpConstants.USER_SIGN_UP_LOADING:
      return {
        ...state,
        userAuth: null,
        userSignupLoading: true,
        userSignupError: false,
      };
    case userSignUpConstants.USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        userAuth: action.userAuth,
        userSignupLoading: false,
        userSignupError: false,
        userSignupData: action.userSignupData,
      };
    case userSignUpConstants.USER_SIGN_UP_FAILURE:
      return {
        ...state,
        userAuth: null,
        // userSignupError: action.userSignupError,
        userSignupLoading: false,
      };
    // ForgetPassword request
    case userSignUpConstants.USER_FORGET_PASSWORD_LOADING:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: true,
        alert: "",
      };
    case userSignUpConstants.USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: false,
        alert: "",
      };
    case userSignUpConstants.USER_FORGET_PASSWORD_CLEAR:
      return {
        ...state,
        userForgetPasswordData: action.userForgetPasswordData,
        userForgetPasswordDataEmail: action.userForgetPasswordDataEmail,
        userForgetPasswordLoading: false,
        alert: "",
      };
    case userSignUpConstants.USER_FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        userForgetPasswordError: action.userForgetPasswordError,
        userForgetPasswordLoading: false,
        alert: action.userForgetPasswordError.response.data.message,
      };

    // ForgetPassword Verification request
    case userSignUpConstants.FORGET_PASSWORD_VERIFICATION_LOADING:
      return {
        ...state,
        verificationLoading: true,
        verificationError: false,
      };
    case userSignUpConstants.FORGET_PASSWORD_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationData: action.verificationData,
        verificationLoading: false,
      };
    case userSignUpConstants.FORGET_PASSWORD_VERIFICATION_FAILURE:
      return {
        ...state,
        verificationError: action.verificationError,
        verificationLoading: false,
        alert: action.verificationError.response.data.message,
      };

    // Reset password save new password request
    case userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordNewPasswordLoading: true,
        resetPasswordNewPasswordError: false,
      };
    case userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordNewPasswordData: action.resetPasswordNewPasswordData,
        resetPasswordNewPasswordLoading: false,
        resetPasswordNewPasswordError: false,
      };
    case userSignUpConstants.RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordNewPasswordError: action.resetPasswordNewPasswordError,
        resetPasswordNewPasswordLoading: false,
      };

    case userSignUpConstants.USER_LOG_IN_LOADING:
      return {
        ...state,
        userLogInLoading: true,
      };
    case userSignUpConstants.USER_LOG_IN_SUCCESS:
      return {
        ...state,
        userSessionId: action.userSessionId,
        roleType: action.roleType,
        userLogInData: action.userLogInData,
        userLogInLoading: false,
        userAuth: action.userAuth,
        showNotificationPopUp:true,
      };
    case userSignUpConstants.HANDLE_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotificationPopUp:action.showNotificationPopUp
      };
    case userSignUpConstants.USER_LOG_IN_FAILURE:
      return {
        ...state,
        userLogInError: action.userLogInError,
        userLogInLoading: false,
      };
    case userSignUpConstants.LOGOUT_ACTION:
      return {
        ...state,
        username: action.username,
        userAuth: action.userAuth,
      };
    case userSignUpConstants.RESET_ERROR:
      return {
        ...initialState,
      };
    case userSignUpConstants.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordNewPasswordData: null,
        resetPasswordNewPasswordLoading: false,
        resetPasswordNewPasswordError: null,
        verificationData: null,
        verificationLoading: false,
        verificationError: null,
        userForgetPasswordData: null,
        userForgetPasswordDataEmail: null,
        userForgetPasswordLoading: false,
        userForgetPasswordError: null,
      };
    case userSignUpConstants.RESET_ALERT:
      return {
        ...state,
        alert: "",
      };
    case userSignUpConstants.ASSOCIATION_LOADING:
      return {
        ...state,
        associationDataLoading : true,
        associationDataError : false,
      };
    case userSignUpConstants.ASSOCIATION_ERROR:
      return {
        ...state,
        associationDataLoading : false,
        associationDataError : true,
      };
    case userSignUpConstants.ASSOCIATION_SUCCESS:
      return {
        ...state,
        associationData : [...action.associationData],
        associationDataLoading : false,
        associationDataError : false,
      };
      case userSignUpConstants.GET_PRICES_LOADING:
        return {
          ...state,
          getPricesDataLoading : true,
          getPricesDataError : false,
        };
      case userSignUpConstants.GET_PRICES_ERROR:
        return {
          ...state,
          getPricesDataLoading : false,
          getPricesDataError : true,
        };
      case userSignUpConstants.GET_PRICES_SUCCESS:
        return {
          ...state,
          getPricesData : action.getPricesData,
          getPricesDataLoading : false,
          getPricesDataError : false,
        };
    default:
      return state;
  }
};
export { userSignup };
