/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useRef } from "react";

function EmailDetails({ data = {}, onFinish, enterEmailModal }) {
  const inputRef = useRef();
  const [form] = Form.useForm();



  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return ()=>{
        form.resetFields();
    }
  }, []);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{
        email: enterEmailModal?.selectedClient?.personId?.email || data?.email || "",
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        hasFeedback
        rules={[
          {
            type: "email",
            message: "Not a valid Email!",
          },
          {
            required: true,
            message: "Please Enter Email!",
          },
          { whitespace: true },
        ]}
      >
        <Input ref={inputRef} placeholder="Enter Email" allowClear autoFocus />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { EmailDetails };
