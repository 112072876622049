import React from "react";
import { FileTextOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { Dropdown } from "antd";
import styles from "../styles/Client.module.css";
import ClientDocsMenuItem from "./ClientDocsMenuItem";
import { EmptyData } from "../../Common/components/EmptyData";

const ClientDocsPanelContent = ({ dataSource, highlightedDocId, parentClientdocItem, viewDocumentFunc, createNewDocument, handleViewAmendExtend, handleDeleteConfirm, setCurrentDocData, setNestedModalOpen }) => {
  return (
    <>
      {dataSource?.length >= 0 ? (
        dataSource.map((el, i) => (
          <table key={el._id} className={styles.documentTable}>
            <tbody>
              {/* Main document */}
              <tr>
                <td onClick={() => viewDocumentFunc({ item: el })} style={{ width: "5%", cursor: "pointer" }}>
                  <FileTextOutlined style={{ fontSize: "20px" }} />
                </td>
                <td style={{ width: "70%", cursor: "pointer" }} onClick={() => viewDocumentFunc({ item: el, cursor: "pointer" })}>
                  {["ERTB", "ERTL", "ETCT"].includes(el.contractType) ? (
                    <>
                      <span style={{ marginRight: "20px", display: "inline-block", width: "30px" }}>{el.contractType}</span>
                      {el.transactionData && el.transactionData.ListingPeriodBeginDate ? <span style={{ display: "inline-block" }}> {moment(el.transactionData.ListingPeriodBeginDate).format("MM/DD/YYYY")} </span> : ""}
                      {el.transactionData && el.transactionData.ListingPeriodBeginDate && el.transactionData.ListingPeriodEndByDate ? ` - ${moment(el.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}` : null}
                      {el._id === highlightedDocId ? <span style={{ fontSize: "20px", marginLeft: "5px", fontWeight: "bold", lineHeight: "20px", paddingTop: "20px", color: "blue" }}>*</span> : null}
                    </>
                  ) : el?.createdAt ? (
                    <>
                      {moment(el?.createdAt).format("MM/DD/YYYY, h:mm A")}
                      {el._id === highlightedDocId ? <span style={{ fontSize: "20px", marginLeft: "5px", fontWeight: "bold", lineHeight: "20px", paddingTop: "20px", color: "blue" }}>*</span> : null}
                    </>
                  ) : (
                    ""
                  )}
                </td>

                <td>
                  <div className={styles.deleteIcon}>
                    <Dropdown trigger={["click", "hover"]} overlay={<ClientDocsMenuItem createdDocItem={el} source={"PARENT_DOC"} parentClientdocItem={parentClientdocItem} createNewDocument={createNewDocument} handleViewAmendExtend={handleViewAmendExtend} handleDeleteConfirm={handleDeleteConfirm} setCurrentDocData={setCurrentDocData} setNestedModalOpen={setNestedModalOpen} />}>
                      <MoreOutlined
                        key="ellipsis"
                        style={{
                          fontSize: "25px",
                          color: "#08c",
                        }}
                      />
                    </Dropdown>
                  </div>
                </td>
              </tr>

              {/* Associated documents */}
              {el.matchedDocuments?.length > 0 &&
                el.matchedDocuments.map((ele) => (
                  <tr key={ele._id}>
                    <td style={{ width: "5%" }}></td>
                    <td onClick={() => viewDocumentFunc({ item: ele })} style={{ width: "70%", cursor: "pointer" }}>
                      <div>
                        <span style={{ display: "inline-block", width: "30px", marginRight: "20px" }}>
                          {" "}
                          <FileTextOutlined style={{ fontSize: "20px" }} />
                        </span>
                        {["LCAE", "LCAES_ERTL", "LCAES_ETCT"].includes(ele.contractType)? (
                          <>
                            <span style={{ display: "inline-block", marginRight: "5px" }}>Amend/Extend</span>
                            {ele.transactionData  && ele.transactionData.ListingPeriodEndByDate ? ` - ${moment(ele.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}` : null}
                          </>
                        ) : ele?.createdAt ? (
                          moment(ele?.createdAt).format("MM/DD/YYYY, h:mm A")
                        ) : (
                          ""
                        )}
                      </div>
                    </td>

                    <td>
                      <div className={styles.deleteIcon}>
                        <Dropdown trigger={["click", "hover"]} overlay={<ClientDocsMenuItem createdDocItem={ele} source={"CHILD_DOC"} parentClientdocItem={parentClientdocItem} createNewDocument={createNewDocument} handleViewAmendExtend={handleViewAmendExtend} handleDeleteConfirm={handleDeleteConfirm} setCurrentDocData={setCurrentDocData} setNestedModalOpen={setNestedModalOpen} />}>
                          <MoreOutlined
                            key="ellipsis"
                            style={{
                              fontSize: "25px",
                              color: "#08c",
                            }}
                          />
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ))
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <EmptyData />
        </div>
      )}
    </>
  );
};

export default ClientDocsPanelContent;
