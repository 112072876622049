import React, { useEffect, useRef } from 'react';

const FacebookTimeline = () => {
  const fbRef = useRef(null);

  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div ref={fbRef} className="fb-page" 
         data-href="https://www.facebook.com/resure.re" 
         data-tabs="timeline" 
         data-width="500"
         data-height="1200"
         data-small-header="true" 
         data-adapt-container-width="true" 
         data-hide-cover="true" 
         data-show-facepile="true">
    </div>
  );
};

export default FacebookTimeline;