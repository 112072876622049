import { calendarConstants } from "./action-types";

const initialState = {
  urlShorthandData: null,
  urlShorthandLoading: true,
  urlShorthandError: null,
  fromCalendarDate: null,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    // url shorthand
    case calendarConstants.SHORTHAND_URL_LOADING: {
      return {
        ...state,
        urlShorthandLoading: true,
        urlShorthandError: false,
      };
    }
    case calendarConstants.SHORTHAND_URL_SUCCESS: {
      return {
        ...state,
        urlShorthandData: action.urlShorthandData,
        urlShorthandLoading: false,
        urlShorthandError: null,
      };
    }
    case calendarConstants.SHORTHAND_URL_FAILURE:
      return {
        ...state,
        urlShorthandData: [],
        urlShorthanLoading: false,
        urlShorthandError: null,
      };
    case calendarConstants.SAVE_FROM_CALENDAR_DATE:
      return {
        ...state,
        fromCalendarDate: action.fromCalendarDate,
      };
    default:
      return state;
  }
};
export { calendarReducer };
