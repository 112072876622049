import React, { useState } from 'react';
import { Tabs, Select } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { EmailCampaign } from './Component/EmailCampaign';

const { TabPane } = Tabs;
const { Option } = Select;

const Setting = () => {
 return (
    <div>
      
      <Tabs tabPosition={"left"} style={{width:"200px"}}  size="small">
        <TabPane tab="Email Campaign" key="1">
          
<EmailCampaign/>
         
        </TabPane>
       
      </Tabs>
    </div>
  );
};

export {Setting};
