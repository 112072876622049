import { clientConstants } from "../action-types";

const initialState = {
  updatePhoneDetails: null,
  updatePhoneLoading: false,
  updatePhoneError: null,
};

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    // update && post phone
    case clientConstants.UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        updatePhoneDetails: action.updatePhoneDetails,
        updatePhoneLoading: action.updatePhoneLoading,
      };
    case clientConstants.UPDATE_PHONE_FAILURE:
      return {
        ...state,
        updatePhoneError: action.updatePhoneError,
        updatePhoneLoading: action.updatePhoneLoading,
      };
    case clientConstants.PHONE_RESET:
      return {
        ...state,
        updatePhoneDetails: null,
        updatePhoneLoading: true,
        updatePhoneError: null,
      };
    default:
      return state;
  }
};
export { phoneReducer };
