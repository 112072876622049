import { Modal, ModalLayout, ModalContent, ModalTitle } from "./index.style";
import { useRef } from "react";
import useOnClickOutside from "../hooks/use-on-cick-outside";

const CustomModalLayout = ({
  handleModalClose,
  title,
  children,
  isModalOpen,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, handleModalClose);

  return (
    <ModalLayout active={isModalOpen}>
      <Modal ref={ref}>
        {/* <ModalCloseButton onClick={handleModalClose}>&times;</ModalCloseButton> */}
        {title && <ModalTitle>{title}</ModalTitle>}
        <ModalContent
          style={{
            background: "#fbfbfb",
            borderRadius: "20px",
            textAlign: "center",
          }}
        >
          {children}
        </ModalContent>
      </Modal>
    </ModalLayout>
  );
};
export default CustomModalLayout;
