import React from "react";
import { List, Avatar } from "antd";
import { UserOutlined, DeleteTwoTone, } from "@ant-design/icons";
import { generateFullNameFromObj, generateInitialsFromObj } from "../utils/extraFunctions";
import { useMediaQuery } from "react-responsive";

const PersonsList = ({ dataSource, onItemClick, onDelete, hoverable = false, showDeleteIcon = false }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const renderAgentItem = (agent) => {
    const initials = generateInitialsFromObj(agent);
    return (
      <List.Item style={{cursor:hoverable? "pointer":"context-menu"}} key={agent._id} hoverable={hoverable} onMouseEnter={(e) => hoverable && (e.currentTarget.style.background = "#a8c5dc")} onMouseLeave={(e) => hoverable && (e.currentTarget.style.background = "white")} onClick={() => hoverable && onItemClick(agent)}>
        <List.Item.Meta
          avatar={
            agent.profileImg?.original ? (
              <Avatar size={55} src={agent.profileImg.original} style={{ border: "3px solid #085191", background: "#347FC0" }} />
            ) : initials ? (
              <Avatar size={55} style={{ background: "#085191" }}>
                {initials}
              </Avatar>
            ) : (
              <Avatar size={55} icon={<UserOutlined />} style={{ background: "#085191" }} />
            )
          }
          title={<span style={{ fontSize: "20px" }}>{generateFullNameFromObj(agent)}</span>}
          description={agent.email && <span>{agent.email}</span>}
        />
        {showDeleteIcon && (
          <span onClick={() => onDelete(agent)} style={{ cursor: "pointer" }}>
            <DeleteTwoTone style={{ fontSize: "medium" }} />
          </span>
        )}
        {/* {!showDeleteIcon && dataSource.some((el) => el._id === agent._id) && <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} />} */}
      </List.Item>
    );
  };

  return <List style={{ margin: isMobile ? "0px 0px" : "0px 2%", width: isMobile ? "85%" : "89%" }} dataSource={dataSource} renderItem={renderAgentItem} />;
};

export default PersonsList;
