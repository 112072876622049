import { clientConstants } from "../action-types";

const initialState = {
  updateBirthdayDetails: null,
  updateBirthdayLoading: false,
  updateBirthdayError: null,
};

const birthdayReducer = (state = initialState, action) => {
  switch (action.type) {
    // update && post BIRTHDAY
    case clientConstants.UPDATE_BIRTHDAY_SUCCESS:
      return {
        ...state,
        updateBirthdayDetails: action.updateBirthdayDetails,
        updateBirthdayLoading: action.updateBirthdayLoading,
      };
    case clientConstants.UPDATE_BIRTHDAY_FAILURE:
      return {
        ...state,
        updateBirthdayError: action.updateBirthdayError,
        updateBirthdayLoading: action.updateBirthdayLoading,
      };
    case clientConstants.BIRTHDAY_RESET:
      return {
        ...state,
        updateBirthdayDetails: null,
        updateBirthdayLoading: true,
        updateBirthdayError: null,
      };
    default:
      return state;
  }
};
export { birthdayReducer };
