import axios from "axios";
import dotEnv from "dotenv";
// import fetch from 'node-fetch';
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
const environment = process.env.REACT_APP_ENV;
const bugUrl = process.env.REACT_APP_BUGS_SCREEN;

// without access token
const buglistingPageLoadRequest = (page,type) => {
 if(!page)
 {
  page=1
 }
 let accessTokan = localStorage.getItem("userAuth");
 const config = {
   method: "GET",
   params: {
     type,
     page:page,
     limit:10,
     filterByStatus:["ACTIVE","IN_PROGRESS","AWAITING_INPUT","INPUT_RECEIVED","NEEDS_TESTING","COMPLETED"].join(","),
},
   headers: {
     accept: "application/json",
     "Content-Type": "application/json",
     authorization: `bearer ${accessTokan}`,
   },
 };
 return axios(`${apiURL}/bugReports`, config);
};

// without access token
const buglistingPageLoadRequestWithout = (type) => {
  let accessTokan = localStorage.getItem("userAuth");
  const config = {
    method: "GET",
    params: {
      page:1,
      limit:10
},
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/bugReports`, config);
};

// my bugs

const myBuglistingPageLoadRequest = (type,boolean) => {
  let accessTokan = localStorage.getItem("userAuth");

  return axios.get(`${apiURL}/bugReports`, {
    params: {
      myBugs: boolean,
      type

    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const updateReportBugRequest = (data,img) => {
  let accessTokan = localStorage.getItem("userAuth");
  // let images = [];
  // for(let el of img){
  //   handleData[0].imageUrls.push(el)
  // }
  return axios.put(`${apiURL}/bugReport/${data?._id}`,

     {
      title:data.title,
      content:data.content,
      platform:data.platform,
      imageUrls:img,
      priority:data.priority,
      assignedTo:data.assignedTo,
      type: data?.type,
    },
    {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Change the status
const updateStatus = (id,status) => {
  let accessTokan = localStorage.getItem("userAuth");
  let urlData = status?.length ? {status:status} : {status:"CLOSED"};
  return axios.put(`${apiURL}/bugReport/${id}`,
    // {
    //   status:"CLOSED"
    // },
    {...urlData},
    {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}

// bug like
const bugLikeRequest = (id, status) => {
  let accessTokan = localStorage.getItem("userAuth");
  const config = {
    method: "PUT",
    data: {
      like: status,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/bugReport/${id}`, config);
};

// bug coment
const bugCommentRequest = (id, urlData,ImageUrls) => {
  let accessTokan = localStorage.getItem("userAuth");
  const config = {
    method: "POST",
    data: {
      ...urlData,
      imageUrls: ImageUrls
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/bugReport/${id}/comment`, config);
};

const bugPostRequest = (updateData, imgUrl,type) => {
  let accessTokan = localStorage.getItem("userAuth");
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: {
      title:updateData.title,
      content:updateData.content,
      platform:updateData.platform,
      priority:updateData.priority,
      imageUrls:imgUrl,
      assignedTo:updateData.assignedTo,
      status: "ACTIVE",
      type,
    },
  };
  return axios(`${apiURL}/bugReport`, config);
};

// Profile Image upload
const uploadImageforProfileRequest = (data, origin) => {
  let accessTokan = localStorage.getItem("userAuth");
  if (origin === "base64") {
    return axios.post(
      `${apiURL}/common/uploadDocsBase64`,
      new URLSearchParams({
        fileBase64: data,
        fileType: "IMAGE",
        folderOf: "PROPERTY_DOCS_IMAGE",
      }),
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }

  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "BUGS");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const bugsLoginModuleRequest = (data) => {
  return axios.post(
    // `${apiURL}/admin/login`,
    `${apiURL}/person/login`,
    {
      ...data,
    },
    {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  );
};

// Notification
const sendNotificationRequest = (data) => {
  let item = data.data.info;
  let parser = new DOMParser();
  let doc = parser.parseFromString(item?.content[0]?.description, "text/html");
  
  let id = `You have a new Bug:\n*Bug Id: ${item._id}*`;
  let newTitle = `*Title:*\n${item.title.toUpperCase()}`;
  let newContent = `*Content:*\n${doc?.body?.textContent}`;
  let newPriority = `*Priority:*\n${item?.priority}`
  let newStatus = `*Status:*\n${item.status}`;
  let env = `*Environment:*\n${environment}`;
  let platForm = `*PlatForm:*\n${item?.platform?.join(", ")}`;
  let type = `*Type:*\n${item?.type}`;
  let newCreated = `*Created By:*\n${
    item.createdBy.fullName ? item.createdBy.fullName : "Not yet integratted"
  }`;
  let newEmail = `*Email:*\n${
    item.createdBy.email ? item.createdBy.email : "Not yet integratted"
  }`;

  let urlParamsData = [
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: id,
      },
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: newTitle,
        },
        {
          type: "mrkdwn",
          text: newContent,
        },
        {
          type: "mrkdwn",
          text: newStatus,
        },
        {
          type: "mrkdwn",
          text: newPriority,
        },
        {
          type: "mrkdwn",
          text: newCreated,
        },
        {
          type: "mrkdwn",
          text: newEmail,
        },
        {
          type: "mrkdwn",
          text: platForm,
        },
        {
          type: "mrkdwn",
          text: type,
        },
        {
          type: "mrkdwn",
          text: env,
        },
      ],
    },
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: "Navigate to bug.",
      },
      accessory: {
        type: "button",
        text: {
          type: "plain_text",
          text: "Navigate to bug",
          emoji: true,
        },
        value: "click_me_123",
        url: bugUrl,
        action_id: "button-action",
      },
    },
  ];

  item?.imageUrls?.forEach((image)=>{
    urlParamsData.push({
      type: "image",
      title: {
        type: "plain_text",
        text: "image1",
        emoji: true,
      },
      image_url: image,
      alt_text: "Bug",
    });
  })


  // const url = `https://hooks.slack.com/services/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`; //old workspace
  const url = `https://hooks.slack.com/services/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`; //new workspace
  const params = {
    blocks: urlParamsData,
  };

  return axios.post(url, JSON.stringify(params), {
    withCredentials: false,
    transformRequest: [
      (data, headers) => {
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });
};
 const deleteBug=(id)=>{
  let accessTokan = localStorage.getItem("userAuth");

  return axios.delete(`${apiURL}/bugReport/${id}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
 }
//  const postbugComment=(id,comment)=>{
//   let accessTokan = localStorage.getItem("userAuth");
//   let data ={
//     Comment :"comment"
//   }

//   return axios.post(`${apiURL}/bugReport/${id}/comment`, {...data},{
//     headers: {
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
//  }
 const searchBug=(value,type)=>{

  let accessTokan = localStorage.getItem("userAuth");
  if(value)
  {
  return axios.get(`${apiURL}/bugReports?search=${value}&type=${type}`, {

    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}
else{
  return axios.get(`${apiURL}/bugReports`, {

    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}

 }

 const filteredData = (status,like,priority,startDate,endDate,type) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(status.length > 0 && like === null && startDate.length <= 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(like !== null && status.length <= 0 && startDate.length <= 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(status.length > 0 && like !== null && startDate.length <= 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like === null && status.length <= 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && endDate.length > 0 && like === null && status.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&endingDate=${endDate}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && status.length > 0 && like === null && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && status.length > 0 && like === null && endDate.length > 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&endingDate=${endDate}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length <= 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&liked=${like}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length > 0 && endDate.length <= 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&liked=${like}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length <= 0 && endDate.length > 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&endingDate=${endDate}&liked=${like}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length <= 0 && like === null && status.length <= 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like === null && status.length <= 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length <= 0 && like !== null && status.length <= 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length <= 0 && like !== null && status.length > 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}&priority=${priority}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length <= 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&startingDate=${startDate}&liked=${like}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length <= 0 && like === null && status.length > 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&filterByStatus=${status}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like === null && status.length > 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&filterByStatus=${status}&priority=${priority}&startingDate=${startDate}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length > 0 && endDate.length <= 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&filterByStatus=${status}&priority=${priority}&startingDate=${startDate}&liked=${like}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like === null && status.length <= 0 && endDate.length > 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&priority=${priority}&startingDate=${startDate}&endingDate=${endDate}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length <= 0 && endDate.length > 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&priority=${priority}&startingDate=${startDate}&endingDate=${endDate}&liked=${like}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like === null && status.length > 0 && endDate.length > 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&priority=${priority}&startingDate=${startDate}&endingDate=${endDate}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length > 0 && endDate.length > 0 && priority.length <= 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}&startingDate=${startDate}&endingDate=${endDate}&filterByStatus=${status}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }else if(startDate.length > 0 && like !== null && status.length > 0 && endDate.length > 0 && priority.length > 0){
    return axios.get(`${apiURL}/bugReports?type=${type}&liked=${like}&filterByStatus=${status}&startingDate=${startDate}&endingDate=${endDate}&priority=${priority}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
 }

 const getUsersData = (page,search) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(search?.length > 0){
    return axios.get(`${apiURL}/person?querySource=BUG_REPORT&limit=15&nameEmailSearch=${search}`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }else{
    return axios.get(`${apiURL}/person?querySource=BUG_REPORT&page=${page}&limit=15`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }
 }

 const bugCommentLoadRequest =(id)=>{
    let accessTokan = localStorage.getItem("userAuth");
    const config = {
      method: "GET",
      params: {
        page:1,
        limit:10
  },
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    };
    return axios(`${apiURL}/bugReport/${id}/comments`, config);
 }

export const bugDataApi = {
  sendNotificationRequest,
  buglistingPageLoadRequest,
  bugPostRequest,
  deleteBug,
  searchBug,
  uploadImageforProfileRequest,
  updateReportBugRequest,
  bugsLoginModuleRequest,
  buglistingPageLoadRequestWithout,
//   postbugComment,
  myBuglistingPageLoadRequest,
  bugLikeRequest,
  bugCommentRequest,
  bugCommentLoadRequest,
  filteredData,
  getUsersData,
  updateStatus,
};
