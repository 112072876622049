import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bugReportHandle } from "../state/actions";

const InputSearch = () => {
  const {renderComp} = useSelector(state=>state.bugReport);
  const dispatch = useDispatch();
  const [type,setType] = useState("")
  const inputRef = useRef(null)

  useEffect(() => {
    if(renderComp === "listings"){
      setType("BUGS")
    }else if(renderComp === "suggestions"){
      setType("SUGGESTIONS")
    }else{
      setType("SUPPORT")
    }
  },[renderComp])

  const handleSearch = (value) => {
    value = value?.trim();
    if(value){
        dispatch(bugReportHandle.searchbug(value,type));
    }else{
        dispatch(bugReportHandle.resetSearchData());
        dispatch(bugReportHandle.resetAllData(type));
        if(renderComp === "listings"){
          dispatch(bugReportHandle.getBugListingDataWith())
        }else if(renderComp === "suggestions"){
          dispatch(bugReportHandle.getSuggestionsData());
        }else{
          dispatch(bugReportHandle.getSupportData());
        }
    }
  };

  // useEffect(()=>{
  //   if(renderComp){
  //     inputRef.current && inputRef.current.focus();
  //   }
  // },[renderComp])

  return (
    <Input.Search
    // ref={inputRef}
    // autoFocus
      style={{
        width: 500,
      }}
      size="middle"
      onSearch={handleSearch}
      placeholder="Search bugs by Title "
      enterButton
      allowClear
    />
  );
};

export { InputSearch };
