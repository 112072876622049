import { documentConstants } from "../action-types";

const initialState = {
  getPropertySearchData: [],
  propertySearchNextPage: false,
  propertySearchPreviousPage: false,
  getPropertySearchLoading: false,
  getPropertySearchError: null,
  backKey: false,
  addPropertyLoading:false,
  addPropertyError:false,
  addedProperty:[],

  ownwersData :[],
  ownwersDataLoading:false,
  ownwersDataError:null,
};

const PropertySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.SEARCH_PROPERTY_SUCCESS:
      return {
        ...state,
        getPropertySearchData: action.getPropertySearchData,
        propertySearchNextPage: action.propertySearchNextPage,
        propertySearchPreviousPage: action.propertySearchPreviousPage,
        getPropertySearchError: action.getPropertySearchData.length === 0 ? true : false,
        getPropertySearchLoading: false,
        backKey: action.backKey,
        personError: null,
      };

    case documentConstants.SEARCH_PROPERTY_FAILURE:
      return {
        ...state,
        getPropertySearchData: [],
        backKey: action.backKey,
        getPropertySearchLoading: false,
        getPropertySearchError: true,
      };

    case documentConstants.SEARCH_PROPERTY_LOADING:
      return {
        ...state,
        getPropertySearchLoading: true,
        getPropertySearchError: false,
      };
      case documentConstants.ADD_PROPERTY_LOADING:
        return {
          ...state,
          addPropertyLoading:true,
          addPropertyError:false
        }
        case documentConstants.ADD_PROPERTY_ERROR:
          return {
            ...state,
            addPropertyLoading:false,
            addPropertyError:true
          }
          case documentConstants.ADD_PROPERTY_SUCCESS:
            return {
              ...state,
              addPropertyLoading:false,
              addedProperty:action.addedProperty
            }
        case documentConstants.RESET_ADD_PROPERTY:
            return {
                ...state,
                addPropertyLoading:false,
                addedProperty:[]
        }
        case documentConstants.RESET_SEARCH_PROPERTIES:
             return {
                ...state,
                getPropertySearchData:[],
            }
    case documentConstants.PROPERTY_SEARCH_BACK_BUTTON:
      return {
        ...state,
        backKey: false,
      };

    case documentConstants.GET_OWNERS_DETAILS_LOADING:
        return{
            ...state,
            ownwersData:[],
            ownwersDataLoading:true,
        }

    case documentConstants.GET_OWNERS_DETAILS_SUCCESS:
            return{
                ...state,
                ownwersData:action.ownersData,
                ownwersDataLoading:false,
            }
    case documentConstants.GET_OWNERS_DETAILS_ERROR:
                return{
                    ...state,
                    ownwersData:[],
                    ownwersDataLoading:false,
                    ownwersDataError:action.error,
                }
        default:
      return state;
  }
};
export { PropertySearchReducer };
