import { commonConstants } from "./action-types";

const initialState = {
  isEmailModalVisible: false,

};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SET_SEND_EMAIL_STATES:
      return {
        ...state,
       ...action.payload,
      };
      
    default:
      return state;
  }
};

export { commonReducer };
