import { documentConstants } from "../action-types";

const initialState = {
  buildContractData: [],
  buildContractLoading: true,
  buildContractError: null,

  getOfferData: [],
  getOfferLoading: true,
  getOfferError: null,
};

const buildContractReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.BUILD_OFFER_SHORTHAND_URL_SUCCESS:
      return {
        ...state,
        buildContractData: action.buildContractData,
        buildContractLoading: true,
        buildContractError: null,
        getOfferData: [],
      };
    case documentConstants.BUILD_OFFER_SHORTHAND_URL_FAILURE:
      return {
        ...state,
        buildContractData: [],
        buildContractLoading: false,
        buildContractError: null,
      };

    default:
      return state;
  }
};
export { buildContractReducer };
