import { documentConstants } from "../action-types";

const initialState = {
  getOfferData: [],
  getOfferLoading: true,
  getOfferError: null,
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.CONTRACT_OFFER_CHECK_SUCCESS:
      return {
        ...state,
        getOfferData: action.getOfferData,
        getOfferLoading: true,
        getOfferError: null,
      };
    case documentConstants.RESET_OFFER_CHECK_DATA:
      return {
        ...state,
        getOfferData: action.getOfferData,
      };
    case documentConstants.CONTRACT_OFFER_CHECK_FAILURE:
      return {
        ...state,
        getOfferData: [],
        getOfferLoading: false,
        getOfferError: null,
      };

    default:
      return state;
  }
};
export { offerReducer };
