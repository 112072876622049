import { pricingPageConstants } from "./actionTypes";

const initialState = {
  checkoutUrl: "",
  checkoutError: null,
  checkoutLoading: false,
  getProductsData: [],
  getProductsLoading: "",
  redeemSubscriptionLoading: false,
  redeemSubscriptionData: false,
  redeemSubscriptionError:false,
};

const pricingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case pricingPageConstants.CREATE_CHEKOUT_LOADING:
      return {
        ...state,
        checkoutError: null,
        checkoutLoading: true,
      };
    case pricingPageConstants.CREATE_CHEKOUT_SUCCESS:
      return {
        ...state,
        checkoutUrl: action.payload,
        checkoutError: null,
      };
    case pricingPageConstants.CREATE_CHEKOUT_FAILURE:
      return {
        ...state,
        checkoutUrl: "",
        checkoutError: true,
        checkoutLoading: false,
      };
    case pricingPageConstants.VIEW_CUSTOMER_PORTAL_LOADING:
      return {
        ...state,
        checkoutLoading: true,
      };
    case pricingPageConstants.VIEW_CUSTOMER_PORTAL_SUCCESS:
      return {
        ...state,
        checkoutError: null,
      };
    case pricingPageConstants.VIEW_CUSTOMER_PORTAL_FAILURE:
      return {
        ...state,
        checkoutUrl: "",
        checkoutError: true,
        checkoutLoading: false,
      };
    case pricingPageConstants.GET_PRODUCTS_LOADING:
      return {
        ...state,
        getProductsData: [],
        getProductsLoading: true,
      };
    case pricingPageConstants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        getProductsData: action.payload,
        getProductsLoading: false,
      };
    case pricingPageConstants.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        getProductsData: [],
        getProductsLoading: "",
      };
      case pricingPageConstants.REDEEM_SUBSCRIPTION_LOADING:
        return {
          ...state,
          redeemSubscriptionLoading: true,
          redeemSubscriptionError:false,
        };
      case pricingPageConstants.REDEEM_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          redeemSubscriptionLoading: false,
          redeemSubscriptionData: true,
          redeemSubscriptionError:false,
        };
        case pricingPageConstants.REDEEM_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        redeemSubscriptionLoading: false,
        redeemSubscriptionData: false,
        redeemSubscriptionError:true,
      };
    default:
      return state;
  }
};

export { pricingPageReducer };
