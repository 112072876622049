import styles from "../styles/statusLabel.module.css";
import { commonStatusFunc, getBackgroundColor } from "../../Common/commondata/commonData";
import PanelMenuItem from "./PanelMenuItem";

import { Dropdown } from "antd";
import React from "react";

import { MoreOutlined } from "@ant-design/icons";

const StatusLabel = ({ currentStatus, actualStatus, item, filterSort, limited, section }) => {
  const commonStatus = commonStatusFunc({key: currentStatus});
  let statusColor = getBackgroundColor({ key: commonStatus?.key });
  let statusBorder = getBackgroundColor({ key: commonStatus?.key, type: "OUTLINE" });
  let statusLabel= getBackgroundColor({ key: commonStatus?.key, type: "TEXT" });
  const home = (section === "HOME");

  return (
    <div className={home ? styles.homeStatusWrapper : styles.statusWrapper} style={{ background: statusColor, border: `2px solid ${statusBorder}`, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <span style={{ flex: 1, display: "flex", justifyContent: "center" }}>{statusLabel?.value}</span>
      <span onClick={(e)=>e.stopPropagation()}>
        {!limited && (
          <Dropdown overlay={<PanelMenuItem item={item} filterSort={filterSort} currentStatus={currentStatus} actualStatus={actualStatus} section={section}/>} trigger={["hover", "click"]}>
            <MoreOutlined className={styles.statusMenuIcon} style={{ margin: "4px", fontWeight: "800", color: "blue", transform: "scale(1.2)" }} />
          </Dropdown>
        )}
      </span>
    </div>
  );
};

export default StatusLabel;
