import { landingPageConstants } from "../action-types";

const initialState = {
  savedNotes: "",
  notesPanelLoading:true,
  notesSavingStatus: false,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case landingPageConstants.NOTES_PANEL_LOADING:
      return {
        ...state,
        notesPanelLoading: action.notesPanelLoading
      };
    case landingPageConstants.NOTES_SAVING_STATUS:
      return {
        ...state,
        notesSavingStatus: action.payload
      };
    case landingPageConstants.GET_SAVED_NOTES_SUCCESS:
      // console.log("action.payload?.data?.[0].notes", action.payload?.data?.[0]?.notes)
      return {
        ...state,
        notesPanelLoading: false,
        getNotesLoading:false,
        savedNotes: action.payload?.data?.[0]?.notes,
        totalRecords: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { notesReducer };
