import { Col, DatePicker, Form, Popover, Modal } from "antd";
import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
const { confirm } = Modal;
const SelectDate = ({ handleOnfocusChange, handleOnBlurChange, fieldEmpty, onChange, redirectFromClient }) => {
  const minimum = new Date();
  const content = (
    <div style={{ borderRadius: "8px", width: "200px", fontWeight: "bold" }}>
      <p style={{ color: "grey" }}>The recommended Closing Date would be at least 30 days from current date.</p>
    </div>
  );

  const handleClosingDateChange = (value) => {
    if (value) {
      const selectedDate = moment(value);
      const thirtyDaysLater = moment().add(30, 'days');
  
      if (selectedDate.isBefore(thirtyDaysLater, 'day')) {
        confirm({
          title: `The selected date is less than 30 days from the current date. By doing this, you have to fill all the CBSR Table 3.1 dates manually.`,
          okText: "Yes",
          cancelText: "No",
          icon: <ExclamationCircleOutlined />,
          content: "Do you still want to proceed?",
          onOk() {
            onChange(value, "closingDateType");
          },
          onCancel() {
            handleOnBlurChange({
              closingDateType: false,
              closingDateTypeColor: "blue",
            });
            redirectFromClient?.form?.setFieldsValue({
              closingDateType: null,
            });
          },
        });
        return;
      }
      onChange(value, "closingDateType");
    } else {
      onChange(null, "closingDateType");
    }
  };

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="lbl"
          name="closingDateType"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              Closing Date
              <Popover borderRadius="10px" content={content} placement="top" trigger="hover">
                <AiOutlineExclamationCircle style={{ color: "hsl(209deg 72% 51%)", marginLeft: "5px", fontSize: "25px" }} />
              </Popover>
            </div>
          }
          rules={[{ required: true, message: "Please select Closing date!" }]}
          onFocus={() =>
            handleOnfocusChange({
              closingDateType: true,
              closingDateTypeColor: "yellow",
            })
          }
          onBlur={() =>
            handleOnBlurChange({
              closingDateType: fieldEmpty.closingDateType === null ? false : true,
              closingDateTypeColor: "green",
            })
          }
        >
          <DatePicker
            allowClear
            disabledDate={(d) => !d || d.isBefore(minimum)}
            placeholder="Select Closing date"
            onChange={(value) => {
              if (!value) {
                handleOnBlurChange({
                  closingDateType: false,
                  closingDateTypeColor: "blue",
                });
              }
              handleClosingDateChange(value);
            }}
            style={{ width: "100%" }}
            format={"MM-DD-YYYY"}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export { SelectDate };

