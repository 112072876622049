import React from "react";
import styles from "../style/DocumentSection.module.css";
import "../style/DocumentForm.css"
import { Col, Form } from "antd";
import NumberFormat from "react-number-format";

const PriceInput = ({
  handleOnfocusChange,
  handleOnBlurChange,
  fieldEmpty,
  onPriceChange,
}) => {
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={12}
      xl={12}
      className={styles.priceAlignment}
    >
      <Form.Item
        className="lbl"
        name="price"
        label="Price"
        rules={[
          {
            required: true,
            message: "Please Enter Price!",
          },
        ]}
        onFocus={() =>
          handleOnfocusChange({
            priceType: true,
            priceTypeColor: "yellow",
          })
        }
        onBlur={() =>
          handleOnBlurChange({
            priceType: fieldEmpty.priceType ? true : false,
            priceTypeColor: "green",
          })
        }
      >
        <NumberFormat
          className="newNumberInput"
          inputmode="numeric"
          displayType={"number"}
          // placeholder="Enter Price"
          thousandSeparator={true}
          prefix={"$ "}
          suffix={".00"}
          allowNegative={false}
          onValueChange={(values, sourceInfo) => {
            const { value } = values;
            onPriceChange(value, "priceType");
          }}
        />
      </Form.Item>
    </Col>
  );
};

export { PriceInput };
