import { landingPageConstants } from "../action-types";

const initialState = {
  getSchedulesData: [],
  getSchedulesError: null,
  getScheduleLoading: false,

  renderSchedule: []
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case landingPageConstants.RENDER_SCHEDULE:
      return {
        ...state,
        renderSchedule: []
      };
    case landingPageConstants.GET_SCHEDULE_LIST_LOADING:
      return {
        ...state,
        getScheduleLoading: true,
        getSchedulesError: null,
      };

    case landingPageConstants.GET_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        getScheduleLoading: false,
        getSchedulesData: action.payload.data,
        totalRecords: action.payload.totalRecords,
      };

    case landingPageConstants.GET_SCHEDULE_LIST_FAILURE:
      return {
        ...state,
        getScheduleLoading: false,
        getSchedulesError: true,
      };

    default:
      return state;
  }
};

export { scheduleReducer };
