import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { Button, Layout, Result, Spin } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navbar } from "./Navbar";
import { SideBar } from "./SideBar";
import { SideBarRight } from "./SideBarRight";
import styles from "../styles/TestingRoute.module.css";
import { bugReportHandle } from "../state/actions";
import { YourBugsMobile } from "./YourBugsMobile";
import { accountHandle } from "../../Account/state/actions";

const { Content } = Layout;

/**
 * @function PrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

export const TestingRoute = ({ component: Component, ...rest }) => {
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const { getUserDetailsLoading, getUserDetailsError } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  let userKey = new URLSearchParams(rest.location.search).get(
    "app_request_key"
  );
  let loggedInUser;
  if (userKey !== undefined && userKey !== null) {
    loggedInUser = userKey;
    localStorage.setItem("userAuth", userKey);
  } else {
    loggedInUser = localStorage.getItem("userAuth");
  }
  const breakpoint = 985;
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    dispatch(accountHandle.getUserDetails());
  }, []);

  if (loggedInUser === null && (userKey === undefined || userKey === null)) {
    return <Redirect to="/bugsLogin" />;
  }

  const handleLogin = () => {
    localStorage.removeItem("userAuth");
    window.location.href = "/login";
  };

  let toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        getUserDetailsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : getUserDetailsError ? (
          <Result
            status="404"
            title="404"
            subTitle="Not Authorized"
            extra={
              <Button type="primary" onClick={handleLogin}>
                Login
              </Button>
            }
          />
        ) :
        <div>
          <Layout
            style={{
              height: "100vh",
              background: "rgb(144, 182, 195)",
              paddingTop: "4rem",
            }}
          >
            <div className={styles.disableComponent}>
              <SideBar toggle={toggle} collapsed={true} />
            </div>
            <Navbar />
            <Layout>
            <div className={styles.contentWrapper}>
              <Content
                style={{
                  background: "#fff",

                }}
              >
                 {location.pathname === "/yourBugs" ? (
                  width < breakpoint ? (
                    <YourBugsMobile type={'BUGS'} />
                  ) : (
                     <Redirect to="/error" />
                   )
                 ) : location.pathname === "/yourSuggestions" ? (
                    width < breakpoint ? (
                        <YourBugsMobile type={'SUGGESTIONS'} />
                      ) : (
                         <Redirect to="/error" />
                       )
                 ) : location.pathname === "/yourTickets" ? (
                  width < breakpoint ? (
                      <YourBugsMobile type={'SUPPORT'} />
                    ) : (
                       <Redirect to="/error" />
                     )
               ) : (
                   <Component {...props} />
                 )}
              </Content>
              </div>
            </Layout>
            <div className={styles.disableComponent}>
              <SideBarRight toggle={toggle} collapsed={false} />
            </div>
          </Layout>
        </div>
      )}
    />
  );
};
