import { clientConstants } from "../action-types";

const initialState = {
  getClientData: [],
  totalCount: 0,
  getClientLoading: false,
  getClientError: null,
  activeClients:[],
  inValidEmailLoading: false,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientConstants.CLIENT_LIST_LOADING:
      return {
        ...state,
        getClientLoading: true,
        getClientError: false,
      };
    case clientConstants.CLIENT_LIST_SUCCESS:
      return {
        ...state,
        getClientData: (action.page === 1) ? action.getClientData : [ ...state.getClientData, ...action.getClientData],
        activeClients:action.activeClients,
        totalCount: action.totalCount,
        getClientLoading: false,
        getClientError: false,
      };
    case clientConstants.CLIENT_LIST_CLEAR:
      return {
        ...state,
        getClientData: [],
        totalCount: 0,
      };
    case clientConstants.CLIENT_LIST_FAILURE:
      return {
        ...state,
        getClientError: action.getClientError,
        getClientLoading: false,
      };
      case clientConstants.FILTER_CLIENT_LIST_LOADING:
        return {
          ...state,
          getClientLoading: true,
          getClientError: false,
        };
      case clientConstants.FILTER_CLIENT_LIST_SUCCESS:
        return {
          ...state,
          getClientData: action.getClientData,
          totalCount: action.totalCount,
          getClientLoading: false,
          getClientError: false,
        };
      case clientConstants.FILTER_CLIENT_LIST_FAILURE:
        return {
          ...state,
          getClientError: action.getClientError,
          getClientLoading: false,
        };
        case clientConstants.SEARCH_CLIENT_LIST_LOADING:
        return {
          ...state,
          getClientLoading: true,
          getClientError: false,
        };
      case clientConstants.SEARCH_CLIENT_LIST_SUCCESS:
        return {
          ...state,
          getClientData: action.getClientData,
          totalCount: action.totalCount,
          getClientLoading: false,
          getClientError: false,
        };
      case clientConstants.SEARCH_CLIENT_LIST_FAILURE:
        return {
          ...state,
          getClientError: action.getClientError,
          getClientLoading: false,
        };
      case clientConstants.UPDATE_INVALID_EMAIL_LOADING:
        return {
          ...state,
          inValidEmailLoading:true,
        }
      case clientConstants.UPDATE_INVALID_EMAIL_SUCCESS:
        return {
          ...state,
          inValidEmailLoading:false,
        }
      case clientConstants.UPDATE_INVALID_EMAIL_ERROR:
        return {
          ...state,
          inValidEmailLoading:false,
        }
    default:
      return state;
  }
};
export { clientReducer };
