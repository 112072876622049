import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const deleteAccount = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(
    `${apiURL}/realtor/deleteAccount`,
    { data },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const deleteAccountApi = { deleteAccount };
