import { landingPageConstants } from "../action-types";

const initialState = {
    renderCompLoading: false,
    renderComp:"NEWS",
    renderCompError: false,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    //render specific componant
    case landingPageConstants.RENDER_SUPPORT_COMPONANT_LOADING:{
        return {
            ...state,
            renderCompLoading:true,
            renderCompError:false,
        }
    }

    case landingPageConstants.RENDER_SUPPORT_COMPONANT_SUCESS:{
        return {
            ...state,
            renderCompLoading:true,
            renderCompError:false,
            renderComp:action.data,
        }
    }

    case landingPageConstants.RENDER_SUPPORT_COMPONANT_FAILURE: {
      return {
        ...state,
        renderCompError: false,
      };
    }

    default:
      return state;
  }
};

export { supportReducer };
