import { Button,  Input,  Menu,  Modal, Row, Tooltip, Typography, message } from "antd";
import React, { useRef, useState,useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toolsAction } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/Tool.module.css"
import {CaretRightOutlined, CheckCircleFilled, SyncOutlined} from "@ant-design/icons"
import EditorToolbar, { formats,modules } from "../../Landing/components/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import {formatAddress, formatPhoneNumber} from "../../Common/utils/extraFunctions";
import { Loading } from "../../Common/components/Loading";

const DocsClauseModal = ({ visible, setVisble, pathname}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [componantDidmount, setcomponantDidmount] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [issendEmailModal, setIssendEmailModal] = useState({visible:false,source:""});
  const {getUserDetailsData} = useSelector((state) => state.account);
  const inputRef = useRef();
  const { TextArea } = Input;


  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleButtonClick = () => {
    setIsPanelCollapsed(!isPanelCollapsed);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleQuillDescriptionChange = (value) => {
    setDescription(value);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (pathname === "email_template" && ((!title || title?.trim()?.length === 0))) {
        message.error('Please fill title.');
        return;
    }
    if (!description || description?.trim()?.length === 0) {
      message.error('Please fill description.');
      return;
    }
    if (pathname === "email_template" && description === "<p><br></p>") {
      message.error("Please fill all the fields");
      return;
    }

    let payload = {
      name: description,
      type:
        pathname === "documents"
          ? "DOCUMENT"
          : pathname === "email_template"
          ? "EMAIL_TEMPLATE"
          : "CLAUSE",
    };
    if(title && title.length > 0) {
        payload.title = title;
    }
    dispatch(toolsAction.addDocAndClause(payload));
    setVisble(false);
    setTitle("");
    setDescription("");

  };

  const quillRef = useRef();

  const handleInsertFirstName = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill?.getSelection().index;

    // Insert the desired text at the cursor position
    quill?.insertText(cursorPosition, "${CUST_F_NAME}");

  };

  const handleInsertLastName = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill?.getSelection()?.index;

    // Insert the desired text at the cursor position
    quill?.insertText(cursorPosition, "${CUST_L_NAME}");

  };

  const handleInsertPropertyAddress = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill?.getSelection()?.index;

    // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${PROP_ADDRESS}");

  };
  const handleInsertDate = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill.getSelection().index;

    // Insert the desired text at the cursor position
    quill?.insertText(cursorPosition, "${CURR_DATE}");

  };
  const handleInsertMiddleName = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill?.getSelection().index;

    // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_M_NAME}");

  };
  const handleInsertTime = () => {
    const quill = quillRef?.current?.getEditor();
    const cursorPosition = quill?.getSelection().index;

    // Insert the desired text at the cursor position
    quill?.insertText(cursorPosition, "${CURR_TIME}");

  };

  useEffect(() => {
    if (visible) {
      const focusTimeout = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [visible]);

  useEffect(() => {
    if(visible===true){
        setcomponantDidmount(true);
    }
    return () => {
      setcomponantDidmount(false);
    }
  }, [visible])

  const MenuItem = () => {
    return (
    <Menu size="large">
       <Menu.Item
          key="fjgg"
          onClick={() => {
            setIssendEmailModal({visible:true})
            dispatch(toolsAction.getFJGGAccess());
            if(false){
                handleSendMail()
            }
        }}
        >
          <span style={{ color: "04396B" }}>Request to Access Frascona Joiner Docs And Clauses</span>
        </Menu.Item>
    </Menu>
  );
};
let templateEssentials ={};
        templateEssentials.fullName = getUserDetailsData && getUserDetailsData?.firstName && getUserDetailsData?.lastName ? getUserDetailsData?.firstName + " " + getUserDetailsData?.lastName : "";
        templateEssentials.phoneNumber =  formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber) || "";
        templateEssentials.brokerageAddress = getUserDetailsData?.brokerageData?.brokerageAddress|| "";
        templateEssentials.brokerageName =getUserDetailsData?.brokerageData?.brokerageName ? getUserDetailsData?.brokerageData?.brokerageName : "";
        templateEssentials.userEmail = getUserDetailsData && getUserDetailsData?.email ? getUserDetailsData?.email:"";


const {SenMailforClauseLoading,FJGGAcessData, FJGGAcessDataLoading} = useSelector(state=>state.ToolReducers.DocumentReducer);

const handleSendMail =()=>{
    dispatch(toolsAction.sendMailForClauses(setVisble,setIssendEmailModal,issendEmailModal?.source,templateEssentials))
}

  return (<>
    <Modal
     title={<span style={{display:"flex",justifyContent:"center",color:"grey"}}>
               REQUEST TO ACCESS FJGG DOCS AND CLAUSES
            </span>}
     footer={false}
     visible={issendEmailModal.visible}
     onCancel={()=>setIssendEmailModal({visible:false})}
    >
    {
     FJGGAcessDataLoading ? <div style={{ height: "300px" }}><Loading /></div>:
     FJGGAcessData?.isEmailRequestSent ? (
        <div style={{ height: "300px" }}>
        {FJGGAcessData?.isApproved ? (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", maxWidth: "350px", margin: "auto" }}>
            <CheckCircleFilled style={{ fontSize: '4em', color: "green" }} />
            <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "grey" }}>
                Congrats!
            </Typography.Text>
            <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "grey", textAlign: "center" }}>
                Your request to access FJGG documents and clauses have been approved.
            </Typography.Text>
            </div>
        ) : (
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
                margin: "auto",
                padding: "20px",
                borderRadius: "10px",
              }}>
                <SyncOutlined style={{ fontSize: '4em', color: "#555", marginBottom: "20px" }} spin />
                <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "#555", textAlign: "center", marginBottom: "10px" }}>
                  Your request to access FJGG Docs and Clauses has been successfully submitted and is now under process.
                </Typography.Text>
                <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "#555", textAlign: "center" }}>
                  Your existing data will be updated automatically once they approve the request.
                </Typography.Text>
              </div>

        )}
        </div>
    ) : (
        <>
        {SenMailforClauseLoading ? (
            <div style={{ height: "300px" }}>
            <Loading />
            </div>
        ) : (
            <div style={{ textAlign: "center", height: "300px", display: "flex", flexDirection: "column", gap: '20px', justifyContent: "center", alignItems: "center", marginTop: "-20px", marginBottom: "10px" }}>
            <Typography.Text style={{ fontSize: "20px", color: "grey", fontWeight: "bold" }}>
                Send Request To Access FGJJ Docs And Clauses
            </Typography.Text>
            <Button type="primary" style={{ width: "150px" }} onClick={handleSendMail}>
                Send email
            </Button>
            </div>
        )}
        </>
    )
    }

    </Modal>
    <Modal
      visible={visible}
      title={
        <Typography.Text style={{ fontSize: "25px",marginLeft:"20px", color: "grey" }}>
         {pathname === "email_template"?"Create Email Template": pathname === "documents" ?
         <span style={{display:"flex",justifyContent:"space-between",height:"20px"}}>
         <p>Add Document</p>
         {/* <Dropdown
         trigger={["click", "hover"]}
         overlay={<MenuItem />}
         placement="left"
       >
         <MenuOutlined
           key="ellipsis"
           style={{color: "hsl(209deg 72% 51%)" }}
         />
       </Dropdown> */}
     </span> :
         <span style={{display:"flex",justifyContent:"space-between",height:"20px"}}>
            <p>Add Clause</p>
            {/* <Dropdown
            trigger={["click", "hover"]}
            overlay={<MenuItem />}
            placement="left"
          >
            <MenuOutlined
              key="ellipsis"
              style={{color: "hsl(209deg 72% 51%)" }}
            />
          </Dropdown> */}
        </span>}
       </Typography.Text>
      }
      onOk={() => {}}
      footer={false}
      onCancel={() => {
        setVisble(false);
        setTitle("")
        setDescription("")
      }}
    > <Row justify="center">
        <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
          {pathname === "email_template" ? (
            <Input
              style={{width:"100%",fontWeight:"bold",marginBottom:"10px"}}
              rows="2"
              ref={inputRef}
              name="title"
              value={title}
              onChange={handleTitleChange}
              placeholder={"Enter Email Title"}
              autoFocus={true}
              required
            ></Input>
          ) : (
            ""
          )}
          {pathname === "email_template" ? (
            <>
        <div>
         <button
        type="button"
        className={styles.buttonfontsize}
        style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
        onClick={handleInsertFirstName}
        >
        First Name
        </button>
        <button
         type="button"
         style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
         className={styles.buttonfontsize}
         onClick={handleInsertLastName}
         >
         Last Name
         </button>
         <button
        type="button"
       style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
       className={styles.buttonfontsize}
      onClick={handleInsertMiddleName}
      >
      Middle Name
      </button>
        <button
        type="button"
       style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
       className={styles.buttonfontsize}
        onClick={handleInsertPropertyAddress}
        >
        Property Address
        </button>

     <button
     type="button"
     style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
    onClick={handleInsertDate}
    className={styles.buttonfontsize}
    >
    Date
    </button>
    <button
     type="button"
     style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
    onClick={handleInsertTime}
    className={styles.buttonfontsize}
    >
    Time
    </button>
    </div>
    {componantDidmount &&
        <div id="editTemp" style={{width:"100%",height:"300px"}}>
    <span style={{display:"flex",justifyContent:"space-between"}}>

            <div><p style={{fontWeight:"bold",marginTop:"5px",color:"grey"}}>Create Your Template Here.</p></div>
            <Tooltip title="Editor Toolbar" trigger={['hover','click']}>
            <Button
                    style={{border:"none",marginRight:"10px"}}
                    icon={<CaretRightOutlined style={{fontSize:"25px",color:"#085191"}} rotate={isPanelCollapsed ? 90 : 0} />}
                    onClick={handleButtonClick}
                 />
            </Tooltip>
            </span>
            <div style={isPanelCollapsed ? {display:"block"}:{display:"none"}}>
                <EditorToolbar toolbarId="createTemp" />
            </div>
            <ReactQuill
              value={description}
              onChange={handleQuillDescriptionChange}
              ref={quillRef}
              className={styles.reactquill}
              modules={modules}
              formats={formats}
         />
         </div>
    }
            </>
          ) : (
            <div style={{display:"flex",flexDirection:"column",fontWeight:"bold",color:"grey",marginBottom:"20px",gap:"20px"}}>
            {pathname === "documents" ? null :
            <div>
                <label>Title</label>
                <Input
                ref={inputRef}
                name="title"
                value={title}
                onChange={handleTitleChange}
                placeholder="Enter Title Here..."/>
            </div>}
            <div>
                <label style={{marginBottom:"5px"}}>{pathname === "documents" ? 'Enter Document Name' : 'Enter Clause'}</label>
                <span style={{color: 'red'}}>&nbsp;*</span>
                <TextArea
                rows="4"
                cols="60"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                placeholder={
                    pathname === "documents"
                    ? "Enter Document Here..."
                    : "Enter Clause Here..."
                }
                />
            </div>
            </div>
          )}

          <Button
           type="primary"
           size="large"
          style={{border:"none"}} onClick={handlesubmit}>Save</Button>
        </form>
      </Row>
    </Modal>
    </>
  );
};

export { DocsClauseModal };
