import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Image, Popconfirm, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined, LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Modal } from "antd";
import { useSelector } from "react-redux";

const { confirm } = Modal;

const DraggerComp = ({ uploadFile, uploadTemp, handleCancel, toggle, source, imageUrl, openFor, setIsmodal, accept }) => {
  const [showInputFileName, setShowInputFileName] = useState(false);
  const [fileSizeOfDocUpload, setFileSizeOfDocUpload] = useState("");
  const [fileNametext, setFileNametext] = useState("");
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileText, setFileText] = useState({ name: null, size: null });
  const [disabledSaveButton, setSisabledSaveButton] = useState(false);

  const { PropertyImageDataLoading } = useSelector(
    (state) => state.PropertyReducer,
  );

  function isUrlOrBlob(input) {
    const urlPattern = /^(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]$/i;

    if (urlPattern.test(input)) {
      return 'url';
    }

    const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*$/;

    if (base64Pattern.test(input)) {
      return 'blob';
    }

    return 'unknown';
  }


  const deleteImg=()=>{
    setPreviewUrl(null);
    setFileText({});
    uploadFile.current = "";
    if (setIsmodal) {
        setIsmodal((prev) => ({ ...prev, isDeleteImage: true }));
        setTimeout(() => {
            setIsmodal((prev) => ({ ...prev, isDeleteImage: false, isImagePasted:false }));
        }, 1000);
      }

  }

  const handleCancelInputFileName = () => {
    if(handleCancel){
        handleCancel();
        setFileText({name:"",size:""});
        setLoading(false);
        setPreviewUrl(null);
    }
    setFileNametext("");
    setShowInputFileName(false);
    setFileSizeOfDocUpload("");
    setLoading(false);
  };

  // Handling PropertyDocs

  function beforeUpload(file) {
    let supportedFormats = file?.type;

    if (supportedFormats?.includes("image")) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/avif" || file.type === "image/gif" || file.type === "image/bmp" || file.type === "image/tiff" || file.type === "image/svg";
      if (!isJpgOrPng) {
        message.error("You can't only upload this image");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    } else if (supportedFormats?.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
      }
      return isPdf && isLt2M;
    } else {
      message.error("You can upload only image and pdf");
    }
  }

  let handleCustome = (event) => {
      setFileText({ name: event?.file?.name, size: bytesToSize(event?.file?.size) });
      setLoading(true);
      setFileNametext(event?.file?.name);
      let fileSize = bytesToSize(event?.file?.size);
      setFileSizeOfDocUpload(fileSize);
      const formData = new FormData();
      formData.append("file", event?.file);
      var reader = new FileReader();
    reader.readAsDataURL(event.file);
    reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setPreviewUrl(base64String);
        setLoading(false);
      };
    if(!toggle){
    if (event?.file?.name?.includes(".pdf")) {
      setShowInputFileName(true);
      formData.append("fileType", "PDF");
    } else if (event?.file?.name?.toLowerCase().match(/\.(png|jpe?g|gif|bmp|tiff|avif|svg)$/)) {
      setShowInputFileName(true);
      formData.append("fileType", "IMAGE");
    } else {
      formData.append("fileType", "other");
    }
    }
    uploadFile.current = formData;
  };

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handleCloseSetFileNameModalConfirm = (docId) => {
    confirm({
      title: `Are you sure you don't want to upload this document?`,
      // content: "Note: This action is irreversible. Deleting this document will permanently remove it from your account.",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleCancelInputFileName();
      },
      onCancel() {},
    });
  };

  const handleUploadFunc =()=>{
    uploadTemp(setShowInputFileName,fileNametext,setFileNametext);
  }

  useEffect(() => {
    if(openFor==='EDIT' && imageUrl){
        setPreviewUrl(imageUrl);
    }

    return () => {
      setPreviewUrl(null);
    }
  }, [imageUrl, openFor])


//   for paste event
useEffect(() => {
    const PasteEventFunction = (event) => {
      setLoading(true);
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      const fileItem = Array.from(items).find(item => item.kind === "file");
      if (fileItem) {
        const blob = fileItem.getAsFile();
        try {
          const checkImage = beforeUpload(blob);
          if (!checkImage) {
            message.error("File type not supported");
            return;
          }
        } catch (err) {
          message.error(err.message);
          return;
        }
        const reader = new FileReader();
        const ide = Math.floor(10000 + Math.random() * 90000);
        const imageName = "pastedImage" + ide;
        const updatedBlob = new File([blob], imageName, { type: blob.type });
        reader.onload = () => {
          const base64String = reader.result.replace(/^data:.+;base64,/, "");
          setPreviewUrl(base64String);
          setLoading(false);
        };
        reader.readAsDataURL(blob);
        setShowInputFileName(true);
        setFileNametext(blob?.name);
        const fileSize = bytesToSize(blob?.size);
        setFileSizeOfDocUpload(fileSize);
        const formData = new FormData();
            formData.append("file", updatedBlob);
        if (!toggle) {
          formData.append("fileType", blob?.type?.includes("image") ? "IMAGE" : "PDF");
        }
        uploadFile.current = formData;
        if(setIsmodal){
            setIsmodal(prev=>({...prev, isImagePasted:true}));
        }
      } else {
        setLoading(false);
      }
    };

    const getModal = document.querySelector("#uploaddiv");
    getModal?.addEventListener("paste", PasteEventFunction);

    return () => {
      getModal?.removeEventListener("paste", PasteEventFunction);
    };
  }, [beforeUpload, toggle]);


  useEffect(() => {
    if(previewUrl === null){
        setSisabledSaveButton(true);
    }else{
        setSisabledSaveButton(false);

    }

    return () => {
    setSisabledSaveButton(false);
    }
  }, [previewUrl])


  return (
    <>
      <div id="uploaddiv">
        <div  style={{ display: (loading || previewUrl) ? "none" : "flex" }}>
        <Dragger
          progress={false}
          style={{ width: "400px" }}
          accept={accept}
          showUploadList={false}
          customRequest={handleCustome}
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
         <>
         <p className="ant-upload-text">Paste, Click or drag file to this area to upload</p>
         {source ==="EMAIL_SIGN" ?
          <p className="ant-upload-hint">The recommended aspect ratio for the image is 600x300 pixels.</p>:
          <p className="ant-upload-hint">Support for a single Image upload. Strictly prohibit from uploading company data or other band files</p>}
          </>
        </Dragger>
        </div>
        {toggle?null:
        <Modal
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "grey",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Enter File Name
            </div>
          }
          footer={
            <Button type="primary"
            onClick={handleUploadFunc}
             disabled={fileNametext?.length > 0 ? false : true}>
              Upload
            </Button>
          }
          open={showInputFileName}
          onCancel={handleCloseSetFileNameModalConfirm}
        >
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>File Name :</span> {fileNametext}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>File Size :</span> {fileSizeOfDocUpload}
            </p>
            <Input
              value={showInputFileName ? fileNametext : ""}
              defaultValue={""}
              placeholder="Enter File Name"
              onChange={(e) => {
                setFileNametext(e.target.value);
              }}
            />
          </div>
        </Modal>}
        {toggle?
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column",marginTop:"20px"}}>
         <div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  display: loading ? "flex" : "none",
                }}
              >
                <LoadingOutlined
                  style={{
                    fontSize: 64,
                  }}
                  spin
                />
              </div>


              <div
                style={{
                  display: (previewUrl) ? "flex" : "none",
                  width: "40%",
                  margin: "auto",
                }}
              >
                {(previewUrl) && (
                  <Image
                   src={isUrlOrBlob(previewUrl)==="url" ? previewUrl :
                   `data:image/png;base64,${previewUrl}`}
                   loading="lazy"
                   preview={{
                    visible: false,
                    mask: (
                      <div
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          zIndex: 21,
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        <div
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <Popconfirm
                            title="Are you sure you want to delete this image?"
                            onConfirm={() => {
                              deleteImg();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <CloseCircleOutlined
                              style={{ fontSize: "30px" }}
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    ),
                  }}
                   />
                )}
              </div>
            </div>

            {fileText?.name && (
              <div>
                <div style={{ textAlign: "center" }}>
                  <Typography.Text type="secondary">
                    <strong>File Name:</strong> {fileText.name}
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    <strong>Size:</strong> {fileText.size}
                  </Typography.Text>
                </div>
              </div>
            )}
            {((source !== "EMAIL_SIGN") &&
            <div
              style={{ display:"flex",justifyContent:"center",marginTop:"10px" }}
              wrapperCol={{ offset: 10, span: 14 }}
            >
              <Button
                disabled={disabledSaveButton}
                loading={PropertyImageDataLoading}
                type="primary"
                htmlType="submit"
                style={{ marginRight: "15px", marginLeft: "-20px" }}
                onClick={handleUploadFunc}
              >
                Save
              </Button>
              <Button type="primary" onClick={handleCancelInputFileName}>
                Cancel
              </Button>
            </div>)}
            </div> : null}
      </div>
    </>
  );
};

export { DraggerComp };
