import React from "react";
import styles from "../styles/PhotoComp.module.css";
import AvatarImage from "./AvatarImage";
import { Button, Image, Input, Modal, Popconfirm } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const UploadBtnBox = ({
  id,
  memories,
  handleImageClick,
  handleDelete,
  imageModalVisible,
  handleCancel,
  handleCustome,
  editDataNotess,
  handleChange,
  onFinish,
  images,
}) => {
  return (
    <div className={styles.uploadButtonContainer}>
      <div>
        {" "}
        <AvatarImage id={id} />
      </div>
      {/* below div not showing in the browser, using bcz without this paste event not working */}
      <div
        style={{
          width: 170,
          height: 170,
          display: memories?.length === 0 ? "flex" : "none",
        }}
      ></div>
      {memories?.length > 0 &&
        memories.map((ele) => {
          return (
            <div key={ele._id}>
              <Image
                width={170}
                height={170}
                style={{objectFit:"contain"}}
                className={styles.uploadedImage}
                src={ele.imageUrl}
                preview={{
                  visible: false,
                  mask: (
                    <div
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 21,
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Popconfirm
                          title="Are you sure to delete this Image?"
                          onConfirm={() => {
                            handleDelete(ele);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <CloseCircleOutlined style={{ fontSize: "30px" }} />
                        </Popconfirm>
                      </div>
                    </div>
                  ),
                }}
                onClick={() => {
                  handleImageClick(ele);
                }}
              />
            </div>
          );
        })}
      <Modal
        visible={imageModalVisible}
        onOk={[]}
        onCancel={handleCancel}
        footer={[]}
        width={450}
        customRequest={handleCustome}
      >
        <div
          style={{
            marginTop: "30px",
            width: 300,
            height: 300,
            alignText: "center",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            alignContent: "center",
            margin: "auto",
          }}
        >
          <Image
            src={images.imageUrl}
            style={{objectFit:"contain"}}
            preview={true}
            width={290}
            height={290}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "500px",
            }}
          >
            <Input.TextArea
              style={{ fontSize: "22px", marginBottom: "0px" }}
              value={editDataNotess}
              placeholder="Add Notes"
              onChange={handleChange}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <Button onClick={() => onFinish()} type="primary">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { UploadBtnBox };
