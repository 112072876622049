
export const getCategoryDataHandle = ({ notificatonData }) => {
  if (notificatonData) {
    let { type, openFor, offerId, propertyId, _id } = notificatonData || {};
    let category, categoryId, status;
    if (openFor === "BUYER_AGENT") {
      category = "BUYER";
      categoryId = offerId;
      status= type==="OFFER_TERMINATED" ? "TERMINATED" : "";
    } else {
      category = "SELLER";
      categoryId = propertyId ? propertyId : _id;
    }

    return { category, categoryId, status };
  } else {
    return { category: null, categoryId: null, status: null };
  }
};

// export const isDisclosureDocPresent = (propertyUploadDocs) => {
//   return propertyUploadDocs && propertyUploadDocs.some(element => 
//     element.offerDocument && 
//     !element?.offerId && 
//     !["ERTS", "LCAES"].includes(element.contractType)
//   );
// }

const mapSigners = (coClient, role) =>
coClient.signers?.map(signer => ({
  ...signer,
  corpKey: coClient.key,
  openFor: role,
  isCorp: true
})) || [];

export const mapClientsForSendEmail = ({clients, role, agents}) => {
  const mergedClients = [...(clients?clients:[]), ...(agents?agents:[])];
  return (
    mergedClients?.map((coClient) => ({
      ...coClient,
      openFor: coClient.isAgent ? coClient.openFor : role,
      isReadOnly: false,
      isAddedByUser: false,
      ...(coClient.isCorp
        ? {
            signers: mapSigners(coClient, role),
            isCorp: true,
          }
        : { isCorp: false }),
    })) || []
  );
};
  

