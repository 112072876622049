import { clientConstants } from "../action-types";

const initialState = {
  updateNotesDetails: null,
  updateNotesLoading: false,
  updateNotesError: null,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    // update && post Notes
    case clientConstants.UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        updateNotesDetails: action.updateNotesDetails,
        updateNotesLoading: action.updateNotesLoading,
      };
    case clientConstants.UPDATE_NOTES_FAILURE:
      return {
        ...state,
        updateNotesError: action.updateNotesError,
        updateNotesLoading: action.updateNotesLoading,
      };
    case clientConstants.UPDATE_NOTES_LOADING:
      return {
        ...state,
        updateNotesLoading: action.updateNotesLoading,
      };
    case clientConstants.NOTES_RESET:
      return {
        ...state,
        updateNotesDetails: null,
        updateNotesLoading: true,
        updateNotesError: null,
      };
    default:
      return state;
  }
};
export { notesReducer };
