import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toolsAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import { Button, Modal, Popconfirm, Row, Select, Typography, Input, Tooltip } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { EmptyData } from "../../Common/components/EmptyData";
import Highlighter from "react-highlight-words";
import styles from "../styles/Tool.module.css"
import "../styles/highlighter.css"

const DocumentsList = ({searchText}) => {
  const [documentCount, setdocumentCount] = useState(11);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();
  const [selectedData, setSelectedData] = useState("");
  const {Text} = Typography;
  const { TextArea } = Input;

  const { getClausesLoading, getDocsData } = useSelector(
    (state) => state.ToolReducers.DocumentReducer,
    shallowEqual
  );

  useEffect(() => {
      dispatch(toolsAction.getDocuments());
  }, []);

  let handleLoadMore = () => {
    setdocumentCount(documentCount + 6);
  };
  // updating the docs name and clauses
  const onFinish = () => {
    dispatch(toolsAction.updateDocsClause(selectedData, "DOCUMENT"));
    setShowModal(false);
  };
  const handleChange =(option,values)=>{
    values.creationType  = option;
    dispatch(toolsAction.updateDocsClause(values, "DOCUMENT"));
  }
  const handleEdit = (value) => {
    setSelectedData(value);
    setShowModal(true);
  };
  const handleDelete = (id) => {
    dispatch(toolsAction.deleteDocsClause(id, "DOCUMENT"));
  };

  useEffect(() => {
    if (showModal) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [showModal]);

  return (
    <>
      <Modal
        visible={showModal}
        title={
          <Typography.Text style={{ fontSize: "25px", marginLeft:"20px", color: "grey" }}>
          Update Document Name
        </Typography.Text>
        }
        onOk={() => {}}
        footer={false}
        onCancel={() => {
          setShowModal(false);
        }}
      >

        <Row justify="center">
            <div style={{width:"100%"}}>
            <label style={{fontWeight:"bold",color:"grey", textAlign:"left",width:"100%",marginBottom:"10px"}}>Description</label>
            <TextArea
                ref={inputRef}
                name="name"
                autoFocus={true}
                rows="4"
                cols="60"
                value={selectedData?.name}
                onChange={(e) =>
                setSelectedData({ ...selectedData, name: e.target.value })
                }
                />
            </div>

          <Button
            type="primary"
            onClick={onFinish}
            style={{ marginTop: "20px" }}
            size="large"
          >
            Save
          </Button>
        </Row>
      </Modal>
      {getClausesLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "70px",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            position: "relative",
            zIndex: "30",
            marginTop: "20px",
          }}
          className={styles.clauselist}
          onScroll={(e) => {
            if (
              Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) <
              800
            ) {
              handleLoadMore();
            }
          }}
        >
          {getDocsData?.length > 0 ? (
            <table
              className="licenseTable"
              style={{
                width: "90%",
                marginTop: "-5px",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: -2,
                  left: "0px",
                  zIndex: 100,
                  height: "50px",
                }}
              >
                <tr>
                  <th>S.No</th>
                  <th>Documents Name</th>
                  <th>Type</th>
                </tr>
              </thead>

              <tbody>
                {getDocsData?.map((el, i) => (
                  <tr key={i} style={{ marginTop: "20px" }}>
                    <td
                      style={{
                        fontWeight: "bolder",
                        width: "8%",
                        textAlign: "center",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        fontWeight: "bolder",
                        width: "80%",
                      }}
                    >
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                      <Text copyable={{text:el?.name}} style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                          <span style={{width:"95%"}}>
                          {el && el?.name &&
                          <Highlighter
                            highlightClassName="highlightClass"
                            searchWords={[searchText]}
                            autoEscape={true}
                            textToHighlight={el && el?.name}
                            />}
                         </span>
                          <div style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                            {el?.creationType === 'FJGG' ? null :
                            <Tooltip trigger={'hover'} title="Edit">
                                <EditTwoTone style={{marginLeft:"15px"}} onClick={() => handleEdit(el)} />
                            </Tooltip>
                            }
                            <Popconfirm
                            title={<p style={{width:"250px"}}>Are you sure you want to delete this Document Name?</p>}
                            onConfirm={() => handleDelete(el._id)}
                            okText="Yes"
                            cancelText="No"
                        >
                        {el?.creationType === 'FJGG' ? null :
                            <Tooltip trigger={'hover'} title="Delete">
                                <DeleteTwoTone style={{margin:"0px 10px 0px 15px"}} />
                            </Tooltip>
                            }
                        </Popconfirm>
                        </div>
                      </Text>
                      </div>
                    </td>
                    <td style={{textAlign:"center"}}>
                    <p style={{fontWeight:"bold"}}>{el.creationType}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
                textAlign: "center",
              }}
            >
              <EmptyData />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentsList;
