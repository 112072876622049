import React, { useEffect, useState } from 'react'
import { ClientNavbar } from '../App/components/ClientNavbar';
import { useLocation } from "react-router-dom";
import { Calendar } from '../PropertyComp/components/Calendar';
import styles from "./styles/ClientScreen.module.css"
import { Spin, Tabs } from 'antd';
import { Help } from '../App/components/Help';
import { decryptQuery } from '../Common/utils/extraFunctions';
const { TabPane } = Tabs;

function ReadOnlyClientScreen() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const urlData = decryptQuery(query.get('urlData'));

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading time for CSS and other resources
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        isLoading ? (
            <Spin size="large" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} />
          ) : (
        <div style={{overflow: 'hidden'}}>
            <ClientNavbar source={'ReadOnlyClientScreen'} urlData={urlData} />
            <Tabs defaultActiveKey="1" centered size='small' style={{overflow: 'hidden'}}
                tabBarExtraContent={<div style={{ margin: "5px 5px 0 0" }}><Help tabName="CLIENT_SCREEN" /></div>}
            >
                <TabPane tab={<div>Calendar</div>} key="1">
                    {urlData?.fullAddress && <div className={styles.readOnlyFullAddress}>{urlData?.fullAddress}</div>}
                    <Calendar agentType={(urlData?.openFor === 'SELLER_AGENT') ? "SELLER" : (urlData?.openFor === 'BUYER_AGENT') ? 'BUYER' : urlData?.openFor} viewType={'month'} screen={"ReadOnlyClientScreen"}  offerId={urlData?.offerId} clientToken={urlData?.token} />
                </TabPane>
            </Tabs>
        </div>
    ))
}

export default ReadOnlyClientScreen
