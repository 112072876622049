import React, { useState } from "react";
import { SearchOutlined, PlusCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { Typography } from "antd";

const ReminderHeader = ({
  setShowSearchInput,
  showSearchInput,
  setShowModal,
  setEditCase,
  setTypeOfCreation,
  setSelectedClient,
  setReminderTypeNormal,
  setReminderType,
  setDefaultValues,
}) => {
  const { Title } = Typography;
  
  
 
  return (
    //  <-----------------------------------Reminders header section------------------------------------->

    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        top: "0",
        backgroundColor: "white",
        left: "0",
        width: "100%",
        zIndex: "1",
        padding: "10px 8px",
      }}
    >
     <div>
        <Title level={3}>REMINDERS</Title>
      </div>
      <div>
        <PlusCircleFilled
          style={{
            fontSize: "30px",
            fill: "#085191",
            color: "#085191",
          }}
          onClick={() => {
            setReminderType("REMINDER");
            setDefaultValues({
              title: "",
              description: "",
              type: false,
              reminderDate: moment(),
              scheduleTimeArray: moment(),
              timeInterval: 2,
            });
            setShowModal(true);
            setEditCase(false);
            setTypeOfCreation(false);
            setSelectedClient(null);
            setReminderTypeNormal(true);
            setReminderType("REMINDER");
          }}
        />
      </div>
    </div>
  );
};

export default ReminderHeader;
