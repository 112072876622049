import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();
const apiURL = process.env.REACT_APP_BASE_URL;

const createCheckOutRequest = (data) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/checkout`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const transationViewRequest = () => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/customer-portal`, {}, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const getProductsRequest = () => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/subscriptionTempelates`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};
const redeemSubscriptionRequest = (data) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/redeemPlanCode`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

export const pricingPageApi = {
  createCheckOutRequest,
  transationViewRequest,
  getProductsRequest,
  redeemSubscriptionRequest
};
