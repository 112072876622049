import React from 'react';
import Highlighter from 'react-highlight-words';
import { Popconfirm, Typography, Tooltip } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
const {Text} = Typography

const HighlightText = ({ el, searchText, handleDelete, handleEdit }) => {
  const highlightedText = (
    <Highlighter
      highlightClassName="highlightClass"
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={el.name.replace(/\n/g, '<br/>')}
    />
  );

  return (
    <Text copyable={{text: el?.name}} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
      <span style={{width: "90%"}}>
        <div dangerouslySetInnerHTML={{__html: highlightedText.props.textToHighlight}} />
      </span>
      <div style={{display: "flex", flexDirection: "row", marginTop: "5px"}}>
        {el.creationType === 'FJGG' ? null :
        <Tooltip trigger={'hover'} title="Edit">
          <EditTwoTone onClick={() => handleEdit(el)} style={{marginLeft: "15px"}} />
        </Tooltip>}
        <Popconfirm
          title="Are you sure you want to delete this Clause?"
          onConfirm={() => handleDelete(el._id)}
          okText="Yes"
          cancelText="No"
        >
          {el.creationType ==='FJGG' ? null :
          <Tooltip trigger={'hover'} title="Delete">
            <DeleteTwoTone style={{margin: "0px 10px 0px 15px"}} />
          </Tooltip>}
        </Popconfirm>
      </div>
    </Text>
  );
};

export default HighlightText;
