import React,{ useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteAccountDataHandle } from "./state/action";
import { Form, Modal, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";


const DeleteAccount = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { deleteAccountMessage } = useSelector((state) => state.deleteAccountReducer);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 5,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };

  const handleCloseModal = () => {
    history.push("/")
  }

  const handleDelete = (values) => {
    let data = { email: values?.email, password: values?.password };
    dispatch(deleteAccountDataHandle.deleteAccount(data));
  };

  const handleCancel = () => {
    form.resetFields();
    history.push("/");
  };

  useEffect(() => {
    if (deleteAccountMessage === "Success") {
      history.push("/");
    }
  },[deleteAccountMessage])

  return (
    <>
      <Modal open={true} title={<p style={{ fontSize: "28px", color: "gray", textAlign: "center" }}>Delete Account</p>} footer={false} onCancel={handleCloseModal}>
        <Form form={form} {...formItemLayout} onFinish={handleDelete}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
            <Input autoFocus placeholder="Enter email"/>
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
            style={{ marginTop: "4%" }}
          >
            <Input autoFocus placeholder="Enter password"/>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", gap: "20px", margin: isMobile ? "1vw 0 0 24vw" : "1vw 0 0 13vw" }}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { DeleteAccount };
