/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Col, Row,Typography, Button, Form } from "antd";
import { templateAction } from "../Template/state/actions";
import { documentAction } from "./state/actions";
import { DocumentForm } from "./components/DocumentForm";
import { TimeLine } from "./components/TimeLine";
import { CompletedPercentage } from "./components/CompletedPercentage";
import styles from "./style/DocumentSection.module.css";
import { accountHandle } from "../Account/state/actions";
const { Content } = Layout;
const { Title } = Typography;

function DocumentSection(props) {
  const dispatch = useDispatch();
  const [redirectToCreateOffer, setRedirectToCreateOffer] = useState(false);
  const [form] = Form.useForm();
  const [listingForm, setListingForm] = useState({
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    streetNumber: null,
    streetName: "",
    unit: "Unit",
    directionPrefix: "",
    directionSuffix: "",
    stateOrProvince: "CO",
    city: "",
    postalCode: null,
  });
  const [fieldEmpty, setFieldIsEmpty] = useState({
    templateType: "",
    propertyType: "",
    closingDateType: null,
    documentType: "66ab7a6a7bfcc2a4deedb08d", // new CBSR 2024
    priceType: null,
    searchBuyerType: "",
    clientsArray: [],
    showClientListModal: false,
    entityName: "",
    currentlySelectedCorp: {},
    delegateBuyer:null,
    property: {}
  });
  const [manualPropertyModal, setManualPropertyModal] = useState(false);
  const [propertySearch, setPropertySearch] = useState(false);

  let handlePropSearchModel = () => {
    setPropertySearch(!propertySearch);
  };

  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);

  const handleManualEntryBtn = () => {
    form.resetFields()
    setListingForm({
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      streetNumber: null,
      streetName: "",
      unitLabel: "Unit",
      unitNumber: "",
      directionPrefix: "",
      directionSuffix: "",
      stateOrProvince: "CO",
      city: "",
      postalCode: null,
    });
    setManualPropertyModal(true);
  };

  useEffect(() => {
    dispatch(templateAction.gettemplateData({contractType: "createOfferContractTypes"}));
    dispatch(documentAction.getDocumentData());
  }, []);
  return (
    <Layout style={{ minHeight: "100%", padding: "0px",marginTop:"-10px"  }}>
      <Content>
        <Row gutter={[12, 24]}  style={{
                height: "100%",
              }}>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <div
              className={styles.documentContainer}
              style={{
                height: "100%",
              }}
            >
              <div
                style={{
                  border: "1px solid  rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  padding: "0.5rem 0.5rem 0.5rem 1rem",
                  height: "100%",
                }}
              >
                <div>
                  <Title level={3}>Steps</Title>
                </div>
                <div className={styles.stepBox}>
                  <div>
                    <TimeLine delegate={props?.delegate} handlePropSearchModel={handlePropSearchModel} fieldEmpty={fieldEmpty}/>
                  </div>
                  <div className={styles.percentageCircle}>
                    <CompletedPercentage progress={props?.progress} />
                  </div>
                </div>
                <div style={{display:"flex", flexDirection:"column",alignItems:"center",marginTop :"20px"}}>
                  <span style={{ fontSize: "18px", color: "grey"}}>OR</span>
                  <Button type="primary" onClick={handleManualEntryBtn} style={{marginTop:"30px"}}>
                    Manual Entry
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={19} lg={19} xl={19}>
            <div className="offer_screenForm documentFormContainer" style={{height:"100%"}}>
              <DocumentForm setProgress={props?.setProgress} redirectToCreateOffer={redirectToCreateOffer} setRedirectToCreateOffer={setRedirectToCreateOffer} progress={props?.progress} redirectFromClient={props} delegate={props?.delegate} manualPropertyModal={manualPropertyModal} setManualPropertyModal={setManualPropertyModal} form={form} listingForm={listingForm} setListingForm={setListingForm} propertySearch={propertySearch} setPropertySearch={setPropertySearch} fieldEmpty={fieldEmpty} setFieldIsEmpty={setFieldIsEmpty}/>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
export { DocumentSection };
