import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import { accountHandle } from "../state/actions";

function NameDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const { updateNameDetails } = useSelector((state) => state.account);
  const inputRef = useRef()
  if (updateNameDetails !== null) {
    dispatch(accountHandle.reSetName());
  }

  const onFinish = (values) => {
    dispatch(accountHandle.updateName(values, selectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <Form
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        firstName: data.firstName,
        middleName:
          data.middleName && data.middleName !== "null" ? data.middleName : "",
        lastName: data.lastName,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "Please Enter First Name!" }]}
      >
        <Input ref={inputRef} placeholder="Enter First Name" allowClear autoFocus/>
      </Form.Item>
      <Form.Item label=" Middle Name" name="middleName">
        <Input placeholder="Enter Middle Name" allowClear />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Please Enter Last Name!" }]}
      >
        <Input placeholder="Enter Last Name" allowClear />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { NameDetails };
