import { Tooltip, Typography } from "antd";
import React from "react";
import { FaMedal } from "react-icons/fa";
import styles from "../styles/Client.module.css";
import { TitleDetails } from "./TitleDetails";

const Title = ({ setModelDrawer, selectedClient, personDetails }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Title"} placement="left" >
          <FaMedal className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <TitleDetails
                setModelDrawer={setModelDrawer}
                data={personDetails}
                selectedClient={selectedClient}
              />
            ),
            title: "TITLE DETAILS",
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-job.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
          {" "}
          {personDetails.designation
            ? personDetails.designation
            : "Enter Title"}{" "}
        </div>
      </div>
    </Text>
  );
};

export { Title };
