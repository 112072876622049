export const toolsConstants = {
  GET_TEMPLATE_JSX_SUCCESS: "GET_TEMPLATE_JSX_SUCCESS",
  GET_TEMPLATE_JSX_ERROR: "GET_TEMPLATE_JSX_SUCCESS",

  GET_CLAUSE_LOADING: "GET_CLAUSE_LOADING",
  GET_CLAUSE_SUCCESS: "GET_CLAUSE_SUCCESS",
  GET_CLAUSE_FAILURE: "GET_CLAUSE_FAILURE",

  GET_DOCUMENTS_LOADING: "GET_DOCUMENTS_LOADING",
  GET_DOCUMENTS_SUCCESS: "GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_FAILURE: "GET_DOCUMENTS_FAILURE",

  GET_EMAIL_TEMPLATE_LOADING: "GET_EMAIL_TEMPLATE_LOADING",
  GET_EMAIL_TEMPLATE_SUCCESS: "GET_EMAIL_TEMPLATE_SUCCESS",
  GET_EMAIL_TEMPLATE_FAILURE: "GET_EMAIL_TEMPLATE_FAILURE",

  ADD_DOCUMENTS_LOADING: "ADD_DOCUMENTS_LOADING",
  ADD_DOCUMENTS_SUCCESS: "ADD_DOCUMENTS_SUCCESS",
  ADD_DOCUMENTS_FAILURE: "ADD_DOCUMENTS_FAILURE",

  UPDATE_DOCUMENTS_LOADING: "UPDATE_DOCUMENTS_LOADING",
  UPDATE_DOCUMENTS_SUCCESS: "UPDATE_DOCUMENTS_SUCCESS",
  UPDATE_DOCUMENTS_FAILURE: "UPDATE_DOCUMENTS_FAILURE",

  DELETE_DOCUMENTS_LOADING: "DELETE_DOCUMENTS_LOADING",
  DELETE_DOCUMENTS_SUCCESS: "DELETE_DOCUMENTS_SUCCESS",
  DELETE_DOCUMENTS_FAILURE: "DELETE_DOCUMENTS_FAILURE",

  POST_REQUEST_FOR_EMAIL_BROADCASTE_LOADING : "POST_REQUEST_FOR_EMAIL_BROADCASTE_LOADING",
  POST_REQUEST_FOR_EMAIL_BROADCASTE_SUCCESS : "POST_REQUEST_FOR_EMAIL_BROADCASTE_SUCCESS",
  POST_REQUEST_FOR_EMAIL_BROADCASTE_FAILURE : "POST_REQUEST_FOR_EMAIL_BROADCASTE_FAILURE",

  UPDATE_FOR_EMAIL_DRIP_LOADING:"UPDATE_FOR_EMAIL_DRIP_LOADING",
  UPDATE_FOR_EMAIL_DRIP_SUCCESS:"UPDATE_FOR_EMAIL_DRIP_SUCCESS",
  UPDATE_FOR_EMAIL_DRIP_FAILURE:"UPDATE_FOR_EMAIL_DRIP_FAILURE",

  GET_REQUEST_FOR_EMAIL_DRIP_LOADING : "GET_REQUEST_FOR_EMAIL_DRIP_LOADING",
  GET_REQUEST_FOR_EMAIL_DRIP_SUCCESS : "GET_REQUEST_FOR_EMAIL_DRIP_SUCCESS",
  GET_REQUEST_FOR_EMAIL_DRIP_FAILURE : "GET_REQUEST_FOR_EMAIL_DRIP_FAILURE",

  GET_REQUEST_FOR_EMAIL_DRIP_REALTOR_SUCCESS : "GET_REQUEST_FOR_EMAIL_DRIP_REALTOR_SUCCESS",

  SEND_MAIL_FOR_CLAUSES_LOADING: "SEND_MAIL_FOR_CLAUSES_LOADING",
  SEND_MAIL_FOR_CLAUSES_SUCCESS: "SEND_MAIL_FOR_CLAUSES_SUCCESS",
  SEND_MAIL_FOR_CLAUSES_FAILURE: "SEND_MAIL_FOR_CLAUSES_FAILURE",

  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING",
  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS",
  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR",

  UPDATE_SUCCESS: "UPDATE_SUCCESS"
};
