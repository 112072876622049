import { clientConstants } from "../action-types";

const initialState = {
  updateNameDetails: null,
  updateNameLoading: false,
  updateNameError: null,
};

const nameReducer = (state = initialState, action) => {
  switch (action.type) {
    // edit name data
    case clientConstants.EDIT_NAME_SUCCESS:
      return {
        ...state,
        updateNameDetails: action.updateNameDetails,
        updateNameLoading: action.updateNameLoading,
      };
    case clientConstants.EDIT_NAME_FAILURE:
      return {
        ...state,
        updateNameError: action.updateNameError,
        updateNameLoading: action.updateNameLoading,
      };
    case clientConstants.CLIENT_NAME_RESET:
      return {
        ...state,
        updateNameDetails: null,
        updateNameLoading: true,
        updateNameError: null,
      };
    default:
      return state;
  }
};
export { nameReducer };
