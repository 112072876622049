import React from "react";
import { Loading } from "../../Common/components/Loading";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function WebSharing() {
  let urlParams = new URLSearchParams(window.location.search);
  let userId = urlParams.get("userId");
  let pwd = urlParams.get("pwd");
  let urlId = urlParams.get("urlId");

  if (userId !== "null") {
    window.location.replace(
      `${apiURL}/login?userId=${userId}&pwd=${pwd}&urlId=${urlId}`
    );
  }
  if (userId === "null") {
    window.location.replace(`${apiURL}/login`);
  }

  return (
    <div>
      <Loading />
    </div>
  );
}

export { WebSharing };
