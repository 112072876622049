import React from "react";
import { Input, List, Modal, Typography, Pagination, Avatar } from "antd";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
import { Loading } from "../../Common/components/Loading";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import styles from "../styles/Listing.module.css";

const UserModal = ({
  userModalVisible,
  setUserModalVisible,
  searchUserdata,
  setSearchUserData,
  setClientID,
  setInput,
  userDataPage,
  setUserDataPage,
}) => {
  const { Text } = Typography;
  const { usersData, usersDataLoading, totalUsersRecords } = useSelector(
    (state) => state.bugReport
  );

  const handleUserModalCancel = () => {
    setUserModalVisible(false);
  };

  return (
    <Modal
      title={<h2 style={{ color: "grey", textAlign: "center" }}>Users</h2>}
      visible={userModalVisible}
      onOk={() => {}}
      onCancel={handleUserModalCancel}
      footer={false}
      style={{ height: "90vh", top: "5vh" }}
    >
      <div>
        {usersDataLoading ? (
          <div style={{ marginTop: "50%" }}>
            <Loading />
          </div>
        ) : (
          <div style={{ marginTop: "-10px" }}>
            <Input.Search
              placeholder="Search user"
              value={searchUserdata}
              onChange={(e) => setSearchUserData(e.target.value)}
              allowClear
            />
            <List
              bordered={true}
              style={{ marginTop: "10px", height: "62vh", overflow: "auto" }}
              itemLayout="horizontal"
              dataSource={usersData}
              renderItem={(item) => {
                return (
                  <List.Item className={styles.clientListItem} key={item._id}>
                    <List.Item.Meta
                      onClick={() => {
                        setClientID(item?._id);
                        setInput(item?.fullName);
                        setUserModalVisible(false);
                      }}
                      avatar={
                        item.profileImg &&
                        item.profileImg.original &&
                        item.profileImg.original !== "test" ? (
                          <Avatar
                            style={{
                              border: "3px solid #085191",
                              background: "#347FC0",
                            }}
                            size={55}
                            className={styles.useravtar}
                            src={item.profileImg.original}
                          />
                        ) : item.firstName ? (
                          <Avatar size={55} style={{ background: "#085191" }}>
                            {(item.firstName
                              ? item.firstName[0].toUpperCase()
                              : "") +
                              "" +
                              (item.lastName
                                ? item.lastName[0].toUpperCase()
                                : "")}
                          </Avatar>
                        ) : (
                          <Avatar
                            size={55}
                            icon={<UserOutlined />}
                            style={{ background: "#085191" }}
                          />
                        )
                      }
                      title={
                        <span style={{ fontSize: "20px" }}>
                          {item?.fullName ? capitalizeStr(item?.fullName) : ""}
                        </span>
                      }
                      description={
                        item?.email ? <Text ellipsis>{item.email}</Text> : ""
                      }
                    />
                  </List.Item>
                );
              }}
            />
            <Pagination
              current={userDataPage}
              total={totalUsersRecords}
              pageSize={15}
              onChange={(page) => setUserDataPage(page)}
              style={{ marginTop: "20px", textAlign: "center" }}
              pageSizeOptions={[15]}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export { UserModal };
