import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const PaymentFailure = () => {
  const history = useHistory();
  const handleHomeClick = () => {
    history.push("/");
  };

  return (
    <Result
      status="error"
      title="Payment Failed"
      subTitle="Please try after some time."
      extra={[
        <Button type="primary" key="console" onClick={handleHomeClick}>
          Home
        </Button>,
      ]}
    ></Result>
  );
};

export default PaymentFailure;
