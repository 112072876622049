import React from 'react'
import { PropertyHandlers } from '../state/actions';
import Dragger from 'antd/lib/upload/Dragger';
import { Button, Modal, message } from 'antd';
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { getCoAgentsIds } from '../../Common/utils/extraFunctions';

export default function UploadPdfDoc({ selectedData, allData, setAllData, handleModalCancel, open, successFuncAll }) {
    const dispatch = useDispatch();
    function beforeUpload(file) {
        let supportedFormats = file?.type;
    
        if (supportedFormats?.includes("pdf")) {
          const isPdf = file.type === "application/pdf";
          if (!isPdf) {
            message.error("You can only upload PDF file!");
          }
          const isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) {
            message.error("File must be smaller than 5MB!");
          }
          return isPdf && isLt2M;
        }
    }
    
    function bytesToSize(bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }
    
    let handleCustome = (data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {};
        reader.onerror = (error) => {
          return error;
        };
        setAllData({
          ...allData,
          file: data.file
        });
    };
    
    const successFunc = () => {
        if(successFuncAll){
            successFuncAll();
        }
    }
    const handleSave = () => {
        let data = {
          openFor: (selectedData?.RTDInitiator==="SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
          RTDInitiator: (selectedData?.RTDInitiator==="SELLER") ? "SELLER" : "BUYER",
          contractId: selectedData?.contractType === "SPD" ? "64f97e26494d69c486381500" : (Array.isArray(selectedData?.contractId) ? selectedData?.contractId?.pop() : selectedData?.contractId),
          contractType: selectedData?.contractType,
          openForPropertyDoc: (selectedData?.RTDInitiator === "SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
          ...(selectedData?.offerId ? { offerId: selectedData?.offerId || "" } : {}),
          ...(selectedData && selectedData.propertyId ? { propertyId: selectedData.propertyId } : {}),
          ...(selectedData && selectedData.clientDocId ? { clientDocId: selectedData.clientDocId } : {}),
          sellerIds: selectedData?.sellerIds,
          buyerIds: selectedData?.buyerIds,
          buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
          sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
          ...(allData && {...allData} )
        };
        dispatch(PropertyHandlers?.uploadPdfDocumentFunc({data, successFunc}));
    }
      
    return (
        <Modal
            open={open}
            onCancel={handleModalCancel}
            style={{ marginTop: "-50px"}}
            footer={false}
            width={700}
        >
            <div style={{minHeight: "350px"}}>
            <div style={{textAlign: "center", fontSize: "1.2rem", padding: "0px 0px 30px 0px"}}>Upload a fully signed PDF document</div>
            <Dragger
                accept=".pdf"
                progress={false}
                showUploadList={false}
                customRequest={handleCustome}
                beforeUpload={beforeUpload}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Paste, Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                    Support for a single document/pdf upload.
                </p>
            </Dragger>
            <div style={{textAlign:"center",marginTop:"20px"}}>
                {allData?.file?.name && <h3>{allData?.file?.name}</h3>}
                {allData?.file?.size && <h6>Size:{" "}{bytesToSize(allData?.file?.size )}</h6>}
            </div>
            <div style={{display: "flex", justifyContent: "center"}}><Button type="primary" disabled={!allData?.file} onClick={handleSave}>Save</Button></div>
            </div>
        </Modal>
    )
}
