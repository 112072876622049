import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

function Failed() {
  return (
    <div>
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={[
          <Button type="primary" key="console">
            <Link to="/upload">Try again</Link>
          </Button>,
        ]}
      ></Result>
    </div>
  );
}

export { Failed };
