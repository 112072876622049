import { Typography } from "antd";
import React from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import moment from "moment";

const ScheduleHeader = ({
  setShowModal,
  setSelectedClient,
  setDefaultValues,
  setEditCase,
  section,
}) => {
  const { Title } = Typography;

  return (
    section !== "HOME" &&
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "sticky",
        top: "0px",
        background: "white",
        left: "0",
        width: "100%",
        zIndex: "10",
        padding: "10px 8px",
      }}
    >
      <div style={{ width: "60%" }}>
        <Title level={3}>Schedule</Title>
      </div>
      <div>
        <PlusCircleFilled
          style={{
            fontSize: "30px",
            fill: "#085191",
            color: "#085191",
          }}
          onClick={() => {
            setDefaultValues({
              title: "",
              description: "",
              type: false,
              reminderDate: moment(),
              scheduleTimeArray: moment(),
              timeInterval: 2,
            });
            setShowModal(true);
            setEditCase(false);
            setSelectedClient(null);
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleHeader;
