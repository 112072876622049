import { documentConstants } from "../action-types";

const initialState = {
  searchBuyerData: null,
  searchBuyerLoading: null,
  searchBuyerError: null,
};

const searchBuyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.SEARCH_BUYER_SUCCESS:
      return {
        ...state,
        searchBuyerData: action.searchBuyerData,
        searchBuyerLoading: action.searchBuyerLoading,
        personError: null,
      };
    case documentConstants.SEARCH_BUYER_FAILURE:
      return {
        ...state,
        searchBuyerData: [],
        searchBuyerLoading: action.searchBuyerLoading,
        searchBuyerError: action.searchBuyerError,
      };

    default:
      return state;
  }
};
export { searchBuyerReducer };
