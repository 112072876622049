import { Tooltip, Typography } from "antd";
import React from "react";
import styles from "../styles/Client.module.css";
import { BsClipboard } from "react-icons/bs";
import { NotesDetails } from "./NotesDetails";

const Notes = ({ setModelDrawer, selectedClient, personDetails }) => {

  const Text = Typography;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Notes"} placement="left" >
          <BsClipboard className={styles.textAlignment}  />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        style={{marginTop:"10px",}}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <NotesDetails
                setModelDrawer={setModelDrawer}
                data={personDetails}
                selectedClient={selectedClient}
              />
            ),
            title: "NOTES DETAILS",
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-notes.svg" alt="" />
        </div>
        <div
          className={styles.userfeild}
          style={{
            // whiteSpace: "nowrap",
            marginLeft: "-10%",
            marginTop:"-20px",
            width:"100%"
          }}
        >
          {" "}
          <Text
            className={styles.userfeild}
            style={{
                marginTop: '12px',
                overflow: 'hidden',
                maxHeight: '30px',
                width: '190px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
            }}
            >
            View Notes
            </Text>
        </div>
      </div>
    </div>
  );
};

export { Notes };
