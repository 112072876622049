import { Drawer } from 'antd';
import React from 'react';
import Notes from '../../Landing/components/Notes';

const NotesDrawer = React.memo(({ noteDrawervisible, closeDrawerOfNotes, detailsData, addressForNotes }) => {
    const closeDrwaer = () => {
        closeDrawerOfNotes();
    };

    return (
        <div>
            <Drawer
                width={720}
                onClose={closeDrwaer}
                open={noteDrawervisible}
                bodyStyle={{ padding: "23px" }}
            >
                <div style={{ color: "rgb(92 82 82 / 85%)" }}>
                    <p style={{
                        fontWeight: "bold",
                        fontSize: "21px",
                        marginBottom: "0px",
                        marginLeft: "10px"
                    }}>{addressForNotes}</p>
                    <Notes source="TRANSACTION" detailsData={detailsData} noteDrawervisible={noteDrawervisible} />
                </div>
            </Drawer>
        </div>
    );
});

export default NotesDrawer;
