import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const UpDownArrows = ({value, filterSort, type})=>{
    const hightlightTrack = ({order}) => {
        if(filterSort?.dateSort?.type == type){
          if(filterSort?.dateSort?.order == order){
            return true;
          }
          return false;
        }
        return false
    }
    return <div style={{display: "flex", gap: "5px"}}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <CaretUpOutlined style={{...(hightlightTrack({order: "DESC"}) ? {color: "#FFBF00"} : {})}}/>
        <CaretDownOutlined style={{marginTop: "-5px", ...(hightlightTrack({order: "ASC"}) ? {color: "#FFBF00"} : {})}}/>
      </div>
      <div style={{...(filterSort?.dateSort?.type === type) ? {color: "#ffc724"} : {}}}>{value}</div>
    </div>
}

export { UpDownArrows };