import { clientConstants } from "../action-types";

const initialState = {
  updateEmailDetails: null,
  updateEmailLoading: false,
  updateEmailError: null,
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    // update && post Email
    case clientConstants.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        updateEmailDetails: action.updateEmailDetails,
        updateEmailLoading: action.updateEmailLoading,
      };
    case clientConstants.UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        updateEmailError: action.updateEmailError,
        updateEmailLoading: action.updateEmailLoading,
      };
    case clientConstants.EMAIL_RESET:
      return {
        ...state,
        updateEmailDetails: null,
        updateEmailLoading: true,
        updateEmailError: null,
      };

    default:
      return state;
  }
};
export { emailReducer };
