import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { userSignUpAction } from "../state/actions";

const SignupPrices = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 650 });
  const spot1 = useMediaQuery({ minWidth: 767 });
  const spot2 = useMediaQuery({ maxWidth:440 });
  const spot3 = useMediaQuery({ maxWidth:767 });

  const { getPricesData, getPricesDataLoading } = useSelector((state) => state.userSignup);

  useEffect(() => {
    dispatch(userSignUpAction.getPriceDetailsData());
  }, []);

  return (
    <div style={{ minHeight: "300px" }}>
      {getPricesDataLoading ? (
        <Spin style={{ fontSize: "25px", margin: "20% 0 0 50%" }} />
      ) : (
        <div>
          <section
            style={{
              width: "95%",
              margin: "auto",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <Row>
              {getPricesData?.map((ele, i) => {
                return (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div
                      key={i}
                      style={{
                        width: "100%",
                        borderRight: i === 0 && spot1 ? "2px solid lightgrey" : "",
                        borderBottom: i === 0 && !spot1 ? "2px solid lightgrey" : "",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      <div
                        style={ spot3 ? {
                          marginTop:"15px",
                        } : {
                          marginTop: "20px",
                          display:"grid",
                          gridTemplateRows: isMobile ? "50px 60px 20px" : "50px 100px 20px",
                        }}
                      >
                        <h1
                          style={{
                            fontSize: "23px",
                          }}
                        >
                          {ele.name}
                        </h1>
                        <text
                          style={{
                            fontWeight: "500",
                            fontSize: "17px",
                          }}
                        >
                          {ele.description}
                        </text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "15px",
                          marginTop: spot3 ? "10px" : ""
                        }}
                      >
                        <h1
                          style={{
                            textDecoration: "line-through",
                          }}
                        >
                          {ele.discontedPrice}
                        </h1>
                        <h1
                          style={{
                            paddingLeft: "5px",
                            color: "#085191",
                          }}
                        >
                          {ele.percent_off ? (
                            <span>
                              <span style={{ textDecoration: "line-through", fontWeight: "bold" }}>${ele.monthlyOriginalPrice || ele.yearlyOriginalPrice}</span> <span style={{ fontWeight: "bold" }}>${ele.monthlyPrice || ele.yearlyPrice}</span> {ele.monthlyPrice ? "/month" : "/year"}{" "}
                            </span>
                          ) : (
                            <span>
                              <span style={{ fontWeight: "bold" }}>${ele.monthlyPrice}</span>/month
                            </span>
                          )}
                          <span className="astrikLegend">{ele.showSymbol}</span>
                        </h1>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </section>

          <div style={{ display: "flex", justifyContent: "center", color: "grey", alignItems: "center", fontWeight: "500", fontSize: "0.9rem" }}>
            <span className="astrikLegend" style={{ fontSize: "1.7rem", marginTop: spot2 ? "-12px" : "8px", marginRight: "5px" }}>
              *
            </span>{" "}
            Annual commitment required. Early cancellation fees apply.
          </div>
        </div>
      )}
    </div>
  );
};

export { SignupPrices };
