import React, { useState } from "react";
import { List, Divider, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  DeleteTwoTone,
  EditOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { CreateEvent } from "./CreateEvent";
import { clientAction } from "../state/actions";
import {EmptyData} from "../../Common/components/EmptyData"

function Events({ selectedClient }) {
  const dispatch = useDispatch();
  const [createEvent, setCreateEventmoc] = useState(false);
  const { personDetails } = useSelector(
    (state) => state.client.personDetailReducer
  );

  let handleDelete = (id) => {
    dispatch(clientAction.deleteEvent(id, selectedClient));
  };
  const [editEvent, setEditEvent] = useState({ status: false, id: "" });

  let setCreateEvent = (flag) => {
    setEditEvent({ status: false, id: "" });
    setCreateEventmoc(flag);
  };

  let handleEdit = (item) => {
    setEditEvent({ status: true, id: item });
    setCreateEventmoc(true);
  };

  return (
    <>
      {createEvent ? (
        <CreateEvent
          editEvent={editEvent}
          setCreateEvent={setCreateEvent}
          selectedClient={selectedClient}
        />
      ) : (
        <div style={{ padding: "1rem 4rem" }}>
          <List
          locale={{ emptyText: <EmptyData /> }}
            itemLayout="horizontal"
            dataSource={personDetails.eventData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  dayjs(item.timestamp).format("MM-DD-YYYY"),
                  <EditOutlined
                    onClick={() => handleEdit(item)}
                    style={{ cursor: "pointer" }}
                  />,
                  <Popconfirm
                    title="Are you sure to delete this Event?"
                    onConfirm={() => handleDelete(item._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteTwoTone style={{ cursor: "pointer" }} />
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  style={{ textTransform: "capitalize" }}
                />
              </List.Item>
            )}
          />
          <Divider style={{ margin: "0.3rem 0" }} />
          <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
            <PlusCircleTwoTone
              onClick={() => setCreateEvent(true)}
              style={{ fontSize: "32px", cursor: "pointer" }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export { Events };
