export const pricingPageConstants = {
  CREATE_CHEKOUT_LOADING: "CREATE_CHEKOUT_LOADING",
  CREATE_CHEKOUT_SUCCESS: "CREATE_CHEKOUT_SUCCESS",
  CREATE_CHEKOUT_FAILURE: "CREATE_CHEKOUT_FAILURE",

  VIEW_CUSTOMER_PORTAL_LOADING: "VIEW_CUSTOMER_PORTAL_LOADING",
  VIEW_CUSTOMER_PORTAL_SUCCESS: "VIEW_CUSTOMER_PORTAL_SUCCESS",
  VIEW_CUSTOMER_PORTAL_FAILURE: "VIEW_CUSTOMER_PORTAL_FAILURE",

  GET_PRODUCTS_LOADING: "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  REDEEM_SUBSCRIPTION_LOADING: "REDEEM_SUBSCRIPTION_LOADING",
  REDEEM_SUBSCRIPTION_SUCCESS: "REDEEM_SUBSCRIPTION_SUCCESS",
  REDEEM_SUBSCRIPTION_FAILURE: "REDEEM_SUBSCRIPTION_FAILURE",
};
