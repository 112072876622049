import React, { useState } from "react";
import { Tooltip, message } from "antd";
import { CopyOutlined, SnippetsOutlined } from "@ant-design/icons";
import style from "../style/rtd.module.css";

const TransactionEmail = ({ selectedData }) => {
  const [copyid, setCopyid] = useState("Copy Email");
  const [copyicon, setCopyIcon] = useState(true);
  const codecopied = () => {
    let code = document.getElementById("code_to_copy").innerHTML;
    setCopyid("Copied");
    setCopyIcon(false);

    navigator.clipboard?.writeText(code)?.then(
      () => {
        message.success("Transaction Email has been copied!");
      },
      () => {
        message.error("Sorry, Failed to copied transaction id.");
      }
    );

    setTimeout(() => {
      setCopyid("Copy Email");
      setCopyIcon(true);
    }, 5000);
  };
  return (
    <div className={style.trnxnDiv}>
      <div>
        <Tooltip title={copyid} placement="right">
          <button
            onClick={codecopied}
            style={{
              cursor: "pointer",
              display: "flex",
              borderRadius: "10px",
              alignItems: "center",
              border: "none",
              height: "30px",
            }}
          >
            <span id="code_to_copy" style={{ fontWeight: "bold" }}>
              {selectedData?.transactionId}@txn.resure.realestate
            </span>
            {copyicon ? (
              <CopyOutlined
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              />
            ) : (
              <SnippetsOutlined
                style={{
                  color: "hsl(209deg 72% 51%)",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              />
            )}
            <span style={{ paddingTop: "13px", paddingLeft: "10px" }}></span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default TransactionEmail;
