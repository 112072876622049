import React, { useState } from "react";
import { PageHeader, Typography, Form, Input, Button, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import { clientAction } from "../state/actions";
const { Title } = Typography;

function CreateEvent({ setCreateEvent, selectedClient, editEvent }) {
  const [disable, setDisable] = useState(true)
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let onFinish = (event) => {
    editEvent.status
      ? dispatch(clientAction.editEvent(event, editEvent.id))
      : dispatch(clientAction.postEvent(event, selectedClient));

    setCreateEvent(false);
  };
  const handleEventDateChange = (date) => {
    if (date) {
      setDisable(false); 
  }else{
    setDisable(true);
  }
  };
  const handleChange =(event)=>{
    if(event.target.value.length === 0) {
        setDisable(true);
    }else{
        setDisable(false);
    }
  }

  return (
    <div>
      <PageHeader
        style={{ padding: 0 }}
        onBack={() => setCreateEvent(false)}
        title={
          <Title
            style={{ color: "#1890ff", marginBottom: 0, fontWeight: 300, cursor:"pointer" }}
            level={5}
            onClick={() => setCreateEvent(false)}
          >
            Back
          </Title>
        }
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          layout={"vertical"}
          form={form}
          initialValues={{
            eventName: editEvent.id.name,
            eventDate: moment(editEvent.id.timestamp),
          }}
          onFinish={onFinish}
        >
          <Form.Item name="eventName" label="Event Name">
            <Input
              style={{ width: "300px" }}
              placeholder="Enter Event Name"
              onChange={handleChange}
              allowClear
            />
          </Form.Item>
          <Form.Item name="eventDate" label="Event Date">
            <DatePicker
              placeholder="Select Event Date"
              style={{ width: "300px" }}
              onChange={handleEventDateChange}
              allowClear
              format="MM-DD-YYYY"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 10, span: 12 }}>
            <Button disabled={disable} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export { CreateEvent };
