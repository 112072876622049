import { Badge, Tooltip, Typography } from "antd";
import React from "react";
import { BsCalendar2Plus } from "react-icons/bs";
import styles from "../styles/Client.module.css";
import { Events } from "./Events";

const AllEvents = ({ personDetails, selectedClient, setModelDrawer }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <Badge
        count={
          (personDetails.eventData && personDetails.eventData.length) || "0"
        }
        size="small"
        showZero
        style={{ background: "rgb(45, 183, 245)" }}
        offset={[10, -4]}
      >
        <Tooltip title={"Events"} placement="left" >
          <BsCalendar2Plus className={styles.textAlignment} />
        </Tooltip>
      </Badge>
      <div className={styles.iconsbox}>
        <div className={styles.iconsclient}></div>
        <div
          className={styles.userfeild}
          style={{ marginLeft: "-10%" }}
          onClick={() =>
            setModelDrawer({
              status: true,
              component: (
                <Events
                  data={personDetails.eventData}
                  selectedClient={selectedClient}
                />
              ),
              title: "EVENT DETAILS",
            })
          }
        >
          {"Events"}
        </div>
      </div>
    </Text>
  );
};

export { AllEvents };
