import { useMediaQuery } from "react-responsive";
import { Button, Col, Form, Modal, Collapse,  } from "antd";
import React, {  useState } from "react";
import { PlusCircleFilled, EditTwoTone,  ExclamationCircleOutlined } from "@ant-design/icons";
import { CorpSigners } from "./CorpSigners";
import { useSelector, useDispatch } from "react-redux";
import { documentAction } from "../state/actions";

const Signers = ({ entitySelected, setPage, onSearch, showClientListModal, setShowClientListModal, handleDeleteClientOrCorp, setClientsArray, entityName, setEntityName, setDrawerVisible, setSelectedRowKeys, corpBuyers, setProgress, progress, delegateOwnerId, delegate, onChange, fieldEmpty, handleOnBlurChange,handleOnfocusChange, }) => {
  const [corporationModalVisible, setCorporationModalVisible] = useState(false);
  const { clientsArray } = useSelector((state) => state.documentSection.CorpReducer);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { Panel } = Collapse;
  const dispatch = useDispatch();

  const handleSave = () => {
    setCorporationModalVisible(false);
  };

  const handleCancel = () => {
    if(clientsArray?.length){
      Modal.confirm({
        title: `Are you sure you want to save these changes?`,
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setCorporationModalVisible(false);
        },
        onCancel() {
          setProgress(delegate ? progress-17 : progress - 20);
          dispatch(documentAction.resetCorpState());
          setCorporationModalVisible(false);
        },
      });
    }else{
      setCorporationModalVisible(false);
    }
  }

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        {clientsArray?.length > 0 ? (
          <Form.Item
            name="buyer"
            label={
              <div style={{ display: "flex", alignItems: "center"}} onClick={() => setCorporationModalVisible(true)}>
                <div style={{ marginLeft: "10px", width: "100%", fontSize: "20px", color: "#1990ff", cursor: "pointer" }}>Edit Buyers</div>
                <span style={{ marginLeft: "10px" }}>
                  <EditTwoTone style={{ fontSize: "20px", color: "#085191", cursor: "pointer" }} />
                </span>
              </div>
            }
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <div style={{ display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflowX: "auto", marginLeft: "10px" }}>
            <div>
                {clientsArray
                  ?.filter((ele) => !ele?.isCorp)
                  ?.map((item, index) => {
                    return (
                      <span key={index} style={{ marginRight: "5px" }}>
                        <span style={{ fontSize: "17px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px",fontWeight:"bold" }}>{item?.fullName}</span>
                        {index !== clientsArray?.filter((ele) => !ele?.isCorp)?.length - 1 && ","}
                      </span>
                    );
                  })}
              </div>
              <div>
                {clientsArray
                  ?.filter((ele) => ele?.isCorp === true)
                  ?.map((item, index) => {
                    return (
                      <span key={index} style={{ marginRight: "10px" }}>
                        <span style={{ display: "inline-block", verticalAlign: "middle", lineHeight: "45px" }}>
                          <span style={{fontSize: "17px",fontWeight:"bold",marginRight:"3px"}}>{item?.fullName?.toUpperCase()}</span>(
                          {item?.signers?.length &&
                            item?.signers?.map((el, i) => {
                              return (
                                <span key={i} style={{ marginRight: "5px" }}>
                                  <span style={{ fontSize: "15px",fontWeight:"normal" }}>{el?.fullName}</span>
                                  {i !== item?.signers?.length - 1 && ","}
                                </span>
                              );
                            })}
                          )
                        </span>
                        {index !== clientsArray?.filter((ele) => ele.isCorp === true)?.length - 1 && ","}
                      </span>
                    );
                  })}
              </div>
            </div>
          </Form.Item>
        ) : (
          <Form.Item
            name="buyer"
            label={
              <div>
                {/* {clientsArray?.length > 0 ? (
                  <div style={{ display: "flex", alignItems: "center" }} onClick={() => setCorporationModalVisible(true)}>
                    <div style={{ marginLeft: "10px", width: "100%", fontSize: "20px", color: "#1990ff", cursor: "pointer" }}>View / Edit Buyers</div>
                    <span style={{ marginLeft: "10px" }}>
                      <EditTwoTone style={{ fontSize: "20px", color: "#085191", cursor: "pointer" }} />
                    </span>
                  </div>
                ) : ( */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginLeft: "10px", width: "100%", fontSize: "20px", color: "#8e959a", cursor: "pointer" }}>Add Buyers</div>
                  <div style={ delegate && !delegateOwnerId ? { marginLeft: "10px", marginTop: "5px",pointerEvents:"none", }: { marginLeft: "10px", marginTop: "5px", }}>
                    <PlusCircleFilled style={{ fontSize: "22px", color: "#085191", cursor: "pointer" }} onClick={() => setCorporationModalVisible(true)} />
                  </div>
                </div>
                {/* )} */}
              </div>
            }
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {/* <div style={{ visibility: "hidden" }}>
            <Input  placeholder="Select Buyers"></Input>
          </div> */}
          </Form.Item>
        )}
      </Col>

      <Modal open={corporationModalVisible} style={{ top: 20, height: "80vh" }} width="650px" footer={false} title={""} onCancel={handleCancel}>
        <CorpSigners entitySelected={entitySelected} setPage={setPage} showClientListModal={showClientListModal} setShowClientListModal={setShowClientListModal} handleDeleteClientOrCorp={handleDeleteClientOrCorp} entityName={entityName} setEntityName={setEntityName} setDrawerVisible={setDrawerVisible} delegateOwnerId={delegateOwnerId} />

        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          {/* <Button onClick={() => setCorporationModalVisible(false)}>Cancel</Button> */}
          <Button type="primary" onClick={handleSave} disabled={clientsArray?.length ? false : true}>
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { Signers };
