import { List, Modal, Avatar,  Tabs,  Tooltip, Spin,Form } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { accountHandle } from "../state/actions";
import { AiFillPlusCircle } from "react-icons/ai";
import DelegateProperty from "./DelegateProperty";
import { useMediaQuery } from "react-responsive";
import { Switch, Route, useHistory, useLocation} from 'react-router-dom';
import Transactions from "../../PropertyComp/components/Transactions";
import DelegateTo from "./DelegateTo";
import DelegateAssigned from "./DelegateAssigned";
import { TransactionLane } from "../../Rtd";
import { NeedTOKnow } from "../../PropertyComp/components/NeedToKnow";
import TasksAndCalendar from "../../PropertyComp/components/TasksAndCalendar";
import { generateInitials } from "../../Common/utils/extraFunctions";
import SearchPersonByEmail from "../../Common/components/SearchPersonByEmail";

const { confirm } = Modal;
const { TabPane } = Tabs;

const transactionVariables = {
  buying: [],
  sellingProperty: [],
  sellingErts: []
}

export default function Delegate() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { delegateListLoading, getUserDetailsData, delegateListData } = useSelector(
    (state) => state.account
  );
  const activeKey = (pathname === "/account/delegates") ? "1" : ((pathname === "/account/delegates/assignedAccounts") ? "2" : "3");
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [delegateSelected, setDelegateSelected] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState({...transactionVariables});
  const [showBrokerageOptions, setshowBrokerageOptions] = useState(false);
  const [isOutsideBrokerage, setisOutsideBrokerage] = useState(false);

  const [readOnly, setReadOnly] =  useState({
    value: false,
    delegateOwnerId: "",
    delegateId: ""
  });
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const isTab = useMediaQuery({ minWidth:576, maxWidth: 1100 });
  const [form] = Form.useForm();
  

  const cleardelegatesData = () => {
    dispatch(accountHandle.clearDelegateList());
  };
  const showModal = (check) => {
    if (check === "outsideBrokerage") {
      setisOutsideBrokerage(true);
      cleardelegatesData();
    } else {
      setisOutsideBrokerage(false);
      dispatch(accountHandle.getDelegateList(getUserDetailsData?._id));
    }
    setSelectedTransaction({...transactionVariables});
    setIsModalOpen(true);
    setshowBrokerageOptions(false);
  };

  const showBrokerageOptionsModal = () => {
    setReadOnly({
    value: false,
    delegateOwnerId: "",
    delegateId: ""
  });
    setshowBrokerageOptions(true);
  };

  const handleCancelBrokerageOptions = () => {
    setshowBrokerageOptions(false);
  };

  const handleCancel = (check) => {
    setIsModalOpen(false);
    dispatch(accountHandle.clearDelegateList());
  }

  const handleDelegateChange = (allData, data, Id, currentName) => {
    setDelegateSelected(data);
    let selectedUser;
    let isRemoving = data.isDelegate;
    if (!isRemoving) {
      selectedUser = data?.fullName;
    }
    let limited = false
    confirm({
      title: `Are you sure you want to ${isRemoving ? "remove" : "delegate"} ${
        (selectedUser ? selectedUser + " to" : "") || "user from"
      } ${currentName}'s account?`,
      okText: isRemoving ? "Yes" : "All Transactions",
      cancelText: isRemoving ? "No" : <p onClick={() => limited = true}>Limited Transactions</p>,
      closable:true,
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        if (isRemoving) {
          let obj = {};
          if (isRemoving) {
            const previous = [data];
            previous.forEach((element) => {
              if (allData?.allDelegates?.includes(element)) {
                obj.delegateRemoved = element?._id;
                obj.delegateValue = activeKey === "1" ? "DELEGATES_ASSIGNED": "DELEGATES_TO"
              }
            });
          }
          if (data.isDelegate) {
            dispatch(
              accountHandle.updatePersonCreated({
                ...obj
              })
            );
          } else {
            dispatch(
              accountHandle.updatePersonCreated({
                delegatesAssigned: data?._id,
                delegateValue : activeKey === "1" ? "DELEGATES_ASSIGNED": "DELEGATES_TO"
              })
            );
          }
          handleCancel();
        } else {
          confirm({
            title: `Are you sure you want to delegate ${
              (selectedUser ? selectedUser + " to" : "") || "user from"
            } ${currentName}'s account?`,
            okText: "Yes",
            cancelText: "No",
            icon: <ExclamationCircleOutlined />,
            content: "",
            onOk() {
              let obj = {};
              if (isRemoving) {
                const previous = [data];
                previous.forEach((element) => {
                  if (allData?.allDelegates?.includes(element)) {
                    obj.delegateRemoved = element?._id;
                    obj.delegateValue = activeKey === "1" ? "DELEGATES_ASSIGNED": "DELEGATES_TO"
                  }
                });
              }
              if (data.isDelegate) {
                dispatch(
                  accountHandle.updatePersonCreated({
                    ...obj,
                    // personId: Id,
                  })
                );
              } else {
                dispatch(
                  accountHandle.updatePersonCreated({
                    delegatesAssigned: data?._id,
                    delegateValue : activeKey === "1" ? "DELEGATES_ASSIGNED": "DELEGATES_TO"
                  })
                );
              }
              handleCancel();
            },
          });
        }
      },
      onCancel() {
        if (!isRemoving && limited) {
          setSelectedTransaction({...transactionVariables});
          setShowPropertyModal(true);
        }
      },
    });
  };

  const handleDelegate = () => {
    let sellingSideIds = [];
    selectedTransaction.sellingProperty.forEach((ele)=>{
      sellingSideIds.push({propertyId: ele});
    })

    let urlData = {
      delegatesAssigned: delegateSelected?._id,
      personId: getUserDetailsData?._id,
      specificTransaction: true,
      buyingSideIds: selectedTransaction.buying,
      sellingSideIds,
      delegateValue : activeKey === "1" ? "DELEGATES_ASSIGNED": "DELEGATES_TO"
    };
    dispatch(accountHandle.updatePersonCreated(urlData));
    setIsModalOpen(false);
    setShowPropertyModal(false);
    setSelectedTransaction({...transactionVariables})
  };

  const handlePartialDelegate = (id, key) => {
    setReadOnly({
      value: true,
      [key]: id,
    });
    setShowPropertyModal(true);
  };

  const handleSearchByEmail = (values) => {
    dispatch(accountHandle.getDelegateList(values.email, true));
    form.resetFields();
  };

  return (
    <div style={{ height: "85vh", padding: "0px 20px 20px 20px" }}>
      <Modal
        title={
          <span
            style={{ display: "flex", justifyContent: "center", color: "#178DFA",fontWeight:"bold",fontSize:"22px" }}
          >
            DELEGATE OPTIONS
          </span>
        }
        visible={showBrokerageOptions}
        onCancel={handleCancelBrokerageOptions}
        footer={false}
      >
        <div
          style={{
            fontWeight: "bold",
            color: "#085191",
            margin: "auto",
            textAlign: "center",
            fontSize: "22px",
            cursor: "pointer",
          }}
        >
          <p
            style={{ marginBottom: "-1px" }}
            onClick={() => showModal("withinBrokerage")}
          >
            Within Brokerage
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "25%",
              margin: "auto",
            }}
          >
            <span style={{ border: "2px solid grey", flex: 1 }}></span>
            <span style={{ color: "grey", margin: "0 10px" }}>OR</span>
            <span style={{ border: "2px solid grey", flex: 1 }}></span>
          </div>
          <p onClick={() => showModal("outsideBrokerage")}>Outside Brokerage</p>
        </div>
      </Modal>
      <Modal
        zIndex={500}
        style={{ marginTop: "-50px"}}
        title={
          <span
            style={{ display: "flex", justifyContent: "center", color: "#178DFA",fontWeight:"bold",fontSize:"22px" }}
          >
            {isOutsideBrokerage ? "SEND AUTHORIZATION TO" : "ASSIGN DELEGATES"}
          </span>
        }
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={isMobile ? "100%" : isTab ? "75%" : "50%"}
      >
        {delegateListLoading ? (
          <div style={{ width: "100%", minHeight: "500px" }}>
            <Spin
              style={{
                alignItems: "center",
                margin: "auto",
                width: "100%",
                paddingTop: "25%",
              }}
              size="large"
            />
          </div>
        ) : (
          <div style={{ height: "500px", overflow: "auto" }}>
            {isOutsideBrokerage && (
                <SearchPersonByEmail form={form} handleSearchByEmail={ handleSearchByEmail} />
            )}
            <List
              itemLayout="horizontal"
              dataSource={delegateListData || []}
              renderItem={(item) => (
                <>
                  {item?.allDelegates?.map((el, index) => (
                    <List.Item
                      key={index}
                      style={{
                        background: el.isDelegate ? "#93b4d2" : "",
                        cursor: "pointer",
                      }}
                      onClick={(e) =>
                        handleDelegateChange(
                          item,
                          el,
                          item?._id,
                          item?.fullName
                        )
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          el?.profileImg?.original ? (
                            <Avatar
                              style={{ border: "1px solid grey" }}
                              size={50}
                              src={el?.profileImg?.original}
                            />
                          ) : (
                              <Avatar size={50} style={{ background: "#085191" }}>
                              {generateInitials(el?.fullName)}
                            </Avatar>
                          )
                        }
                        title={<span>{el?.fullName}</span>}
                        description={el?.email}
                      />
                    </List.Item>
                  ))}
                </>
              )}
            />
          </div>
        )}
      </Modal>
      {
        showPropertyModal && <DelegateProperty delegateSelected={delegateSelected} showPropertyModal={showPropertyModal} setShowPropertyModal={setShowPropertyModal} handleDelegate={handleDelegate} readOnly={readOnly} selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction}/>
      }
      
      <Switch>
        <Route
          exact
          path="/account/delegates/assignedAccounts"
        >
          <MainTab activeKey={activeKey} showBrokerageOptionsModal={showBrokerageOptionsModal} setDelegateSelected={setDelegateSelected} handlePartialDelegate={handlePartialDelegate} handleCancel={handleCancel}/>
        </Route>
        <Route
            exact
            path={["/account/delegates/transactions/sDashboard/needToKnow/:id", "/account/delegates/transactions/bDashboard/needToKnow/:id"]}
        >
          <NeedTOKnow section="DELEGATE"/>
        </Route>
        <Route
            exact
            path={["/account/delegates/transactions/sTask/:id", "/account/delegates/transactions/bTask/:id", "/account/delegates/transactions/bCalendar/:id", "/account/delegates/transactions/sCalendar/:id"]}
        >
          <TasksAndCalendar section="DELEGATE"/>
        </Route>
        <Route
            exact
            path={["/account/delegates/transactions/sDashboard/:id", "/account/delegates/transactions/bDashboard/:id"]}
        >
          <TransactionLane section="DELEGATE"/>
        </Route>
        <Route
          exact
          path="/account/delegates/transactions"
        >
          <MainTab activeKey={activeKey} showBrokerageOptionsModal={showBrokerageOptionsModal} setDelegateSelected={setDelegateSelected} handlePartialDelegate={handlePartialDelegate} handleCancel={handleCancel}/>
        </Route>
        <Route
          exact
          path="/account/delegates"
        >
          <MainTab activeKey={activeKey} showBrokerageOptionsModal={showBrokerageOptionsModal} setDelegateSelected={setDelegateSelected} handlePartialDelegate={handlePartialDelegate} handleCancel={handleCancel}/>
        </Route>
      </Switch>
    </div>
  );
}

const MainTab = ({ activeKey, showBrokerageOptionsModal, setDelegateSelected, handlePartialDelegate, handleCancel }) => {
  const history = useHistory();
  
  const handleTabClick = (key) => {
    if(key === "1"){
      history.push("/account/delegates");
    }
    else if(key === "2"){
      history.push("/account/delegates/assignedAccounts");
    }
    else if(key === "3"){
      history.push("/account/delegates/transactions");
    }
  };
  
  return <Tabs activeKey={activeKey} onTabClick={handleTabClick} style={{margin: "0px"}}>
    <TabPane
      tab={
        <div
          style={{marginRight:"7px"}}
        >
          {/* DELEGATES ASSIGNED */} Grant Access
          <div
            style={{
              position: "absolute",
              left: "102%",
              top: "13px",
              zIndex: "111",
            }}
          >
            {activeKey === "1" && (
              <Tooltip title="Assign Delegate" trigger={["click", "hover"]}>
                <AiFillPlusCircle
                  //   onClick={showModal}
                  onClick={showBrokerageOptionsModal}
                  style={{
                    color: "#2171B9",
                    margin: "3px 0px 0px 0px",
                    fontSize: "26px",
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
      key="1"
    >
      {
        activeKey === "1" && <DelegateAssigned setDelegateSelected={setDelegateSelected} handlePartialDelegate={handlePartialDelegate} handleCancel={handleCancel}/>
      }
    </TabPane>
    <TabPane
      tab={
        <div style={{marginLeft:"17px", paddingRight: "17px"}}>
          Accounts
        </div>
      }
      key="2"
    >
      {
        activeKey === "2" && <DelegateTo setDelegateSelected={setDelegateSelected} handlePartialDelegate={handlePartialDelegate}/>
      }
    </TabPane>
    <TabPane
      tab={
        <div style={{marginLeft:"17px", paddingRight: "17px"}}>
          <div>Transactions</div>
        </div>
      }
      key="3"
    >
      {
        activeKey === "3" && <div style={{marginTop: "-20px"}}>
          <Transactions section="DELEGATE"/>
        </div>
      }
    </TabPane>
  </Tabs>
}
