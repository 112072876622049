import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Result, Button, Layout, Row, Card } from "antd";
const { Header, Content, Footer } = Layout;

function SuccessChangePassword({ title, subTitle, flag, backEvent }) {
  return (
    <>
      <Result
        status="success"
        title={title} //"Successfully Purchased Contract!"
        subTitle={subTitle} //"Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={
          flag && (
            <Button type="primary" key="console" onClick={backEvent} danger>
              Back
            </Button>
          )
        }
      />
      <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
        <Card style={{ width: "100%" }}>
          <Row align="">
            <div style={{ marginRight: "5px" }}>Click</div>{" "}
            <Link to={"/login"}> here</Link>{" "}
            <div style={{ marginLeft: "5px" }}>
              to return to the Login Panel.
            </div>
          </Row>
        </Card>
      </div>
    </>
  );
}

export { SuccessChangePassword };
