import { ERTSDataApi } from "../utils/api";
import { ERTSConstants } from "./action-types";
import { message } from "antd";
import { documentAction } from "../../DocumentSection/state/actions";

const uploadFileLoading = () => {
  return {
    type: ERTSConstants.POST_PDF_FILE_LOADING,
    ERTS_Loading: true,
  };
};

const uploadFileError = (error) => {
  message.error(
    error?.response?.data?.message ||
    "We encountered an issue while uploading an erts file. Please try again later!"
);
  return {
    type: ERTSConstants.POST_PDF_FILE_ERROR,
    ERTS_Error: true,
  };
};

const uploadFileSuccess = (response) => {
  return {
    type: ERTSConstants.POST_PDF_FILE_SUCCESS,
    ERTSData: response?.data?.info?.transactionData,
  };
};

const resetData = () => {
  return {
    type: ERTSConstants.RESET_ERTS_DATA,
  };
};

const uploadERTSFile =({file, delegateOwnerId, createErts,next,importModalStep,docsModal,setconfirmModal, transactionData, handleCancelErts,delegate, redirectFunc})=>{
    return (dispatch)=>{
        dispatch(documentAction.pdfImportLoading());
        ERTSDataApi.uploadERTSPDF({file, createErts, transactionData, delegateOwnerId})
        .then((response)=>{
            dispatch(documentAction.urlImportSuccess(response));
            if(docsModal && importModalStep === 0){
                setconfirmModal(response?.data?.info?.transactionData?.checkDuplicate?.isDuplicate);
            }
            if(next){
                next();
            }
            if(handleCancelErts){
                handleCancelErts();
                message.success(response?.data?.info?.message)
            }
            if(redirectFunc){
                redirectFunc(response?.data?.info?.transactionData?.propertyId)
            }
            })
        .catch((error)=>{
            dispatch(uploadFileError(error))})
    }
}

export const ERTSAction = {
  uploadFileLoading,
  uploadFileError,
  uploadFileSuccess,
  uploadERTSFile,
  resetData,
};
