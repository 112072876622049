import { Avatar, Col, Form, Select } from 'antd';
import React, { useEffect } from 'react'
import { accountHandle } from '../../Account/state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generateInitialsFromObj } from '../../Common/utils/extraFunctions';
import styles from "../style/DocumentSection.module.css"
import { getFilteredDelegateData } from '../utils/helper';

const DelegateUser = ({onChange, handleOnfocusChange, handleOnBlurChange, fieldEmpty }) => {
    const dispatch = useDispatch();
    const { Option } = Select;
    let delegate = true;
    const { getDelegateData, getDelegateDataLoading } = useSelector((state) => state.account);

    const handleData = (id) => {
      const filteredData = getFilteredDelegateData(id, getDelegateData);
      onChange(filteredData, "delegateBuyer");
    };


    useEffect(()=>{
            dispatch(accountHandle.getDelegateData("DELEGATES_TO",delegate));
    },[]);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Form.Item
        className="lbl"
        name="delegateBuyer"
        label="Select Buyer Agent"
        rules={[
          {
            required: true,
            message: "Please select a buyer",
          },
        ]}
        onFocus={() =>
            handleOnfocusChange({
              documentType: true,
              buyerTypeColor: "yellow",
            })
          }
          onBlur={() => {
            handleOnBlurChange({
              documentType: fieldEmpty.documentType === undefined ? false : true,
              buyerTypeColor: "green",
            });
          }}
      >
       <Select
       allowClear
       placeholder="Select Buyer Agent"
       loading={getDelegateDataLoading}
       onChange={(value) => handleData(value)}
       >
       {getDelegateData?.delegateTo?.map((user) => (
            <Option key={user?._id} value={user?._id}>
             <div style={{ display: "flex", alignItems: "center" }}>
               {user?.profileImg?.original ? <img
                  src={user?.profileImg?.original}
                  alt="img"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                /> :
               <Avatar size={"small"} style={{ background: "#085191", marginRight: "5px", }}>
                <div className={styles.avtarText}>{generateInitialsFromObj(user)}</div>
              </Avatar>}
                <span>{user?.fullName}</span>
             </div>
            </Option>
        ))}
       </Select>
      </Form.Item>
    </Col>
  )
}


export default DelegateUser
