import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import { useState } from "react";
import { documentAction } from "../../DocumentSection/state/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

export default function ListingForm({
  listingModalFields,
  setListingModalFields
}) {
  const dispatch = useDispatch();
  const [emptyField, setEmptyField] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });

  function onChange(value, key) {
    const fieldEmptyUpdated = listingModalFields;
    fieldEmptyUpdated[key] = value;
    setListingModalFields({ ...fieldEmptyUpdated });
  }

  let drawerClose = () => {
    dispatch(documentAction.clearCustomerData());
  };

  const selectOptions = [
    { value: "", label: "None" },
    { value: "East", label: "East" },
    { value: "West", label: "West" },
    { value: "North", label: "North" },
    { value: "South", label: "South" },
    { value: "Northeast", label: "North East" },
    { value: "Northwest", label: "North West" },
    { value: "Southeast", label: "South East" },
    { value: "Southwest", label: "South West" },
  ];
  const onFinish = (v) => {};

  const disabledDate = (current) => {
    const targetDate = moment(listingModalFields.startPeriod, "YYYY-MM-DD");
    const nextDay = targetDate.clone().add(1, "day");
    return current && current.isBefore(nextDay, "day");
  };
  const defaultStartDate = moment();
  return (
    <div style={{ maxHeight:isMobile ? "300px" : '490px', overflow: 'auto',overflowX:"hidden", padding:"0 10px" }}>
      <Form onCancel={drawerClose} layout="vertical" onFinish={onFinish} initialValues={{unitLabel : "Unit "}}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl"
              name="listingStartDate"
              label="Listing Start date"
              rules={[
                {
                  required: true,
                  message: "Please Select Listing Start date",
                },
              ]}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                allowClear
                defaultValue={defaultStartDate}
                onChange={(e, value) => {
                  onChange(value, "startPeriod");
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl"
              name="listingEndDate"
              label="Listing End date"
              initialValue={listingModalFields?.endPeriod ? moment(listingModalFields?.endPeriod, 'YYYY-MM-DD') : null}
              rules={[
                {
                  required: true,
                  message: "Please Select Listing End date",
                },
              ]}
            >
              <DatePicker
                onChange={(e, value) => {
                  onChange(value, "endPeriod");
                }}
                disabled={listingModalFields.startPeriod ? false : true}
                disabledDate={disabledDate}
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="street number"
              label="Street Number"
              className="lbl"
              initialValue={listingModalFields?.streetNumber || ''}
              rules={[
                { required: true, message: "Please Enter Street Number" },
              ]}
              >
              <Input
                allowClear
                type="number"
                onChange={(e) => {
                  onChange(e.target.value, "streetNumber");
                }}
                placeholder="Enter Street Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="street Name"
              label="Street Name"
              className="lbl"
              rules={[{ required: true, message: "Please Enter Street name" }]}
              initialValue={listingModalFields?.streetName || ""}
              onChange={(e) => {
                onChange(e.target.value, "streetName");
              }}
            >
              <Input  placeholder="Enter Street Name"   allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="streetDirPrefix"
              label="Direction Prefix"
              className="lbl1"
              initialValue={listingModalFields?.dirPrefix || undefined}
              // value={emptyField}
            >
              <Select
                placeholder="Select Direction"
                options={selectOptions}
                size="middle"
                allowClear
                style={{
                  fontSize: "16px",
                  "::placeholder": { fontSize: "6px" },
                }}
                onChange={(e) => {
                  onChange(e, "dirPrefix");
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="streetDirSuffix"
              label="Direction Suffix"
              className="lbl1"
              initialValue={listingModalFields?.dirSuffix || undefined}
              // value={emptyField}
            >
              <Select
                placeholder="Select Direction"
                options={selectOptions}
                size="middle"
                allowClear
                style={{
                  fontSize: "16px",
                  "::placeholder": { fontSize: "6px" },
                }}
                onChange={(e) => {
                  onChange(e, "dirSuffix");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {/* <Form.Item
              name="unit"
              label="Unit"
              className="lbl"
              initialValue={listingModalFields?.Unit || ""}
              value={emptyField}
            >
              <Input
                onChange={(e) => {
                  onChange(e.target.value, "Unit");
                }}
                placeholder="Enter Unit"
                allowClear
              />
            </Form.Item> */}
            <Form.Item
              style={{
                marginBottom: 0,
              }}
              className="lbl"
              initialValue={listingModalFields?.Unit || ""}
              // value={emptyField}
            >
              <Form.Item
                label="Unit Label"
                name="unitLabel"
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Input placeholder="Enter unit, apt or suite" 
                  onChange={(e) => {
                    onChange(e.target.value, "unitLabel");
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label="Unit Number"
                name="unitNumber"
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  margin: '0 8px',
                }}
              >
                <Input placeholder="Enter value" 
                  onChange={(e) => {
                    onChange(e.target.value, "unitNumber");
                  }}
                  allowClear
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl1"
              name="city"
              label="City"
              value={emptyField}
              rules={[{ required: true, message: "Please Enter City name" }]}
              initialValue={listingModalFields?.city || ""}
            >
              <Input
                onChange={(e) => {
                  onChange(e.target.value, "city");
                }}
                placeholder="Enter City Name"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl1"
              name="State"
              label="State"
              rules={[{ required: true, message: "Please Enter State name" }]}
              initialValue={listingModalFields?.state || ""}
            >
              <Input
                defaultValue='CO'
                onChange={(e) => {
                  onChange(e.target.value, "state");
                }}
                placeholder="Enter State Name"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl1"
              name="zip"
              label="Zip Code"
              value={emptyField}
              initialValue={listingModalFields?.zip || ""}
              hasFeedback
              rules={[
                { required: true, message: "Please Enter Zip Code" },
                { whitespace: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value?.length > 4 && value?.length < 7) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please Enter valid Zip Code!")
                    );
                  },
                }),
              ]}
            >
              <Input
                type="number"
                maxLength={"6"}
                allowClear
                onChange={(e) => {
                  onChange(e.target.value, "zip");
                }}
                placeholder="Enter Zip Code"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
