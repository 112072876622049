import moment from "moment";

export const convertDateStringToTimeStamp = (dateString) => {
  const date1 = new Date(dateString);
  const timestamp = date1.getTime();
  return timestamp;
};

export const convertTimeStampToDateString = (timeStamp) => {
  const date1 = new Date(timeStamp);
  let dateString = date1.toDateString();
  return dateString;
};


export const getCurrentTimeStamp = (pathname) => {
  if (!pathname) {
    return moment(new Date().setHours(9)).valueOf();
  }

  const pathParts = pathname.split("/");
  if (pathParts.length < 3) {
    return moment(new Date().setHours(9)).valueOf();
  }
  const dateParts = pathParts[2].split("-");
  if (dateParts.length === 3) {
    const [year, month, day] = dateParts.map(Number);
    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      const newDate = new Date(year, month - 1, day);
      newDate.setHours(9);
      return moment(newDate).valueOf();
    }
  }
  return moment(new Date().setHours(9)).valueOf();
};



