import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function SomeThingWentWrong({status,title,subTitle, buttonText,onBackButtonclick }) {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button type="primary" onClick={onBackButtonclick}>
          {buttonText}
        </Button>
      }
    />
  );
}

export { SomeThingWentWrong };
