/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styles from "../styles/Client.module.css";
import { clientAction } from "../state/actions";
import { SaveCancelModal } from "./SaveCancelModal";
import { ProfileImg } from "./ProfileImg";
import { Name } from "./Name";
import { Phone } from "./Phone";
import { Email } from "./Email";
import { Title } from "./Title";
import { Address } from "./Address";
import { Birthday } from "./Birthday";
import { AllEvents } from "./AllEvents";
import { Notes } from "./Notes";
import { CreatedAt } from "./CreatedAt";
import { UpdatedAt } from "./UpdatedAt";
import { Col, Row, Space } from "antd";
import { Loading } from "../../Common/components/Loading";
import { RoleHashtags } from "./RoleHashTag";
import axios from "axios";

function ClientDetails({ selectedData, setDrawerVisible, selectedClient, setPage, setPersonId, setSelectedClient, setShowSearchInput, setValue }) {

  const [active,setActive] = useState(false)
  const dispatch = useDispatch();
  const [modelDrawer, setModelDrawer] = useState({
    status: false,
    component: "",
    title: "",
  });

  const { personDetails, personDetailsLoading, renderPersonDetail } = useSelector(
    (state) => state.client.personDetailReducer,
    shallowEqual
  );

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    dispatch(clientAction.getPerson({clientId: selectedClient, unmounted, source}));

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [selectedClient, renderPersonDetail]);

  const {activeClients} = useSelector((state) => state.client.clientReducer)

  const {updateNotesLoading} = useSelector((state) => state.client.notesReducer)

  const onFinish = (values) => {
    setPersonId(selectedClient);
    dispatch(clientAction.updateName(values, selectedClient, setPage, setSelectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
  };

  const onFinishEmail = (values) => {
    setPersonId(selectedClient);
    dispatch(clientAction.updateEmail(values, personDetails, selectedClient, setPage, setSelectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
  };

  const forProfileClient = (id) => {
    dispatch(clientAction.clearGetClientData());
    setPersonId(id);
    setSelectedClient("");
    setPage([1]);
  }

  useEffect(() => {
    let data = activeClients?.filter((el) => el._id === personDetails?._id)
    if(data.length > 0){
      setActive(true)
    }else{
      setActive(false)
    }
  },[personDetails])

  return (
    <>
      {
        (personDetailsLoading || updateNotesLoading) ? 
          (
            <Loading />
          ) :
          (
            <Row style={{ marginTop: "-20px", position: "relative" }}>
              {modelDrawer.status && (
                <SaveCancelModal
                  modelDrawer={modelDrawer}
                  setModelDrawer={setModelDrawer}
                />
              )}
              <Col span={24}>
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "white",
                    marginBottom: "15px",
                    height: "85px",
                    flexDirection: "column",
                    placeContent: "center",
                    position: "sticky",
                    top: "-10px",
                    zIndex: "1",
                  }}
                >
                  <ProfileImg
                    personDetails={personDetails}
                    setModelDrawer={setModelDrawer}
                    selectedClient={selectedClient}
                    forProfileClient={forProfileClient}
                  />
                  <Col>
                    <Space>
                      <span
                        className={styles.icoplus}
                        onClick={() => setDrawerVisible(true)}
                      >
                        <img src="icons/addcircle.svg" alt="" />
                      </span>
                    </Space>
                  </Col>
                  <Col style={{position:"absolute",right:'50px'}}>
              </Col>
                </Row>

                <Row>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "16px",
                    }}
                  >
                    <Space direction="vertical"   style={{
                      width:"100%",
                    
                    }}>
                      {/* Full Name */}
                      <Name
                        onFinish={onFinish}
                        personDetails={personDetails}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Phone No */}
                      {/* setModelDrawer,personDetails,selectedClient */}
                      <Phone
                        setModelDrawer={setModelDrawer}
                        modelDrawer={modelDrawer}
                        personDetails={personDetails}
                        selectedClient={selectedClient}
                        />
                      <Phone
                        setModelDrawer={setModelDrawer}
                        modelDrawer={modelDrawer}
                        personDetails={personDetails}
                        selectedClient={selectedClient}
                        source='officePhoneNumber'
                      />
                      {/* Email */}
                      <Email
                        personDetails={personDetails}
                        setModelDrawer={setModelDrawer}
                        onFinish={onFinishEmail}
                      />
                      {/* Title */}
                      <Title
                        selectedClient={selectedClient}
                        personDetails={personDetails}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Address */}
                      <Address
                        selectedClient={selectedClient}
                        personDetails={personDetails}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Birthday */}
                      <Birthday
                        selectedClient={selectedClient}
                        personDetails={personDetails}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Events */}
                      <AllEvents
                        personDetails={personDetails}
                        selectedClient={selectedClient}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Notes */}
                      <Notes
                        personDetails={personDetails}
                        selectedClient={selectedClient}
                        setModelDrawer={setModelDrawer}
                      />
                      {/* Created At */}
                      <CreatedAt personDetails={personDetails} />
                      {/* Updated At */}
                      <UpdatedAt personDetails={personDetails} />

                      <RoleHashtags
                          personDetails={personDetails}
                          setModelDrawer={setModelDrawer}
                          selectedClient={(value)=>selectedClient(value)}
                        />
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
      }
    </>
  );
}

export { ClientDetails };
