import React from "react";
import { Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { FaSort } from "react-icons/fa";
import styles from "../styles/smallScreenNav.module.css";
import { getBackgroundColor, handleDateSortClick, transactionDefaultSortFilter } from "../../Common/commondata/commonData";
import { UpDownArrows } from "../../Common/components/CommonMiniComponents";
import { PropertyHandlers } from "../state/actions";

const FilterMenu = ({ toggled, form, filterSort }) => {
    const dispatch = useDispatch()

    const checkStatusChecked = (key) => {
        return filterSort.statusSort.includes(key);
    };

    const checkSourceFilter = (key) => {
        return filterSort.sectionSort.includes(key);
    };

    const handleStatusFilter = (key) => {
      if (filterSort.statusSort.includes(key)) {
        dispatch(PropertyHandlers.changeFilterSort({
            ...filterSort,
          statusSort: filterSort.statusSort.filter((el) => el !== key)
        }))
      } else {
        dispatch(PropertyHandlers.changeFilterSort({
            ...filterSort,
            statusSort: [...filterSort.statusSort, key]
        }))
      }
    };

    const handleSourceFilter = (key) => {
      if (filterSort.sectionSort.includes(key)) {
        dispatch(PropertyHandlers.changeFilterSort({
            ...filterSort,
            sectionSort: filterSort.sectionSort.filter((el) => el !== key)
        }))
      } else {
        dispatch(PropertyHandlers.changeFilterSort({
            ...filterSort,
            sectionSort: [...filterSort.sectionSort, key]
        }))
      }
    };

    const handleResetFilters = () => {
        if(form){
            form.resetFields();
        }
        dispatch(PropertyHandlers.changeFilterSort({
            ...transactionDefaultSortFilter
        }))
    };

    return (
        <>
        <div onClick={(e) => e.stopPropagation()} style={{ height: "300px", width: "320px", display: "flex", fontFamily: "sans-serif" }}>
            {/* Status Filter Section */}
            <div style={{ textAlign: "center", width: "55%" }}>
                <h3 style={{fontWeight: "600"}}>Status</h3>
                {["UNDER_CONTRACT", "ACTIVE", "INPROGRESS", "DRAFT", "TERMINATED", "ARCHIVED", "CLOSED"].map((key) => (
                    <div key={key} style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            style={{ marginRight: "2px" }}
                            onChange={() => handleStatusFilter(key)}
                            checked={checkStatusChecked(key)}
                        />
                        <div
                            style={{
                                maxWidth: "140px",
                                width: "100%",
                                padding: "3px",
                                marginTop: "5px",
                                fontWeight: "600",
                                background: getBackgroundColor({key}),
                                letterSpacing: "0.4px",
                                border: `2px solid ${getBackgroundColor({key, type: "OUTLINE"})}`,
                                borderRadius: "2px"
                            }}
                        >
                            {getBackgroundColor({key, type: "TEXT"})?.value}
                        </div>
                    </div>
                ))}
            </div>

            {/* Source Filter Section */}
            <div style={{ textAlign: "center", width: "50%" }}>
                <div style={{ height: "50%" }}>
                    <h3 style={{fontWeight: "600"}}>Buy/Sell</h3>
                    {["BUYING", "SELLING"].map((key) => (
                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                style={{ marginRight: "2px" }}
                                onChange={() => handleSourceFilter(key)}
                                checked={checkSourceFilter(key)}
                            />
                            <div
                                style={{
                                    border: "2px solid#3d59ab",
                                    maxWidth: "120px",
                                    width: "100%",
                                    padding: "3px",
                                    marginTop: "5px",
                                    fontWeight: "600",
                                    borderRadius: "2px",
                                    letterSpacing: "0.4px",
                                    ...((key==="BUYING") ? {color: "white"} : {color: "#3d59ab"}),
                                    background: getBackgroundColor({key})
                                }}
                            >
                                {getBackgroundColor({key, type: "TEXT"})?.value}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Reset Button */}
                {/* Reset Button */}
                <div className={styles.btnsBox}>
                {toggled && toggled?.filter && (
                    <>
                    <Button
                        onClick={()=>{
                            handleDateSortClick({type: "UPDATED", dispatch, filterSort})
                        }}
                        icon={<UpDownArrows value={"Updated"} type="UPDATED" filterSort={filterSort}/>}
                        size={"small"}
                        className={styles.filterbtns}
                    >
                    </Button>
                    <Button
                        onClick={()=>{
                            handleDateSortClick({type: "CLOSED", dispatch, filterSort})
                        }}
                        icon={<UpDownArrows value={"Closing"} type="CLOSED" filterSort={filterSort}/>}
                        size={"small"}
                        className={styles.filterbtns}
                    >
                    </Button>
                    <Button
                        className={styles.filterbtns}
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                    </>
                )}
                </div>
            </div>
        </div>
        </>
    );
};

export default FilterMenu;
