import { Button, Col, Form, Input, Row } from "antd";
import React from "react";

const SearchPersonByEmail = ({ form, handleSearchByEmail }) => {
  return (
    <Form form={form} onFinish={handleSearchByEmail}>
      <Row justify="center" style={{ padding: "10px" }}>
        <Col xs={20} sm={20} md={18} lg={18} xl={18}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter a valid email address",
                type: "email",
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="Enter email" />
          </Form.Item>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchPersonByEmail;
