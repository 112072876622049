import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  ArrowRightOutlined,
  DownOutlined,
  UpOutlined,
  MoreOutlined,
  CopyOutlined,
  SnippetsOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import dotEnv from "dotenv";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { pricingPageAction } from "./state/action";
import { Loading } from "../Common/components/Loading";
import moment from "moment";
import { AiFillClockCircle } from "react-icons/ai";
import { MenuItem } from "./components/MenuItem";
const { Title } = Typography;
const { confirm } = Modal;
dotEnv.config();

const Pricing = () => {
  const [payment, setPayment] = useState(false);
  const [account, setAccount] = useState(false);
  const [credit, setCredit] = useState(false);
  const [user, setUser] = useState(false);
  const [offer, setOffer] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [manualLoading, setManualLoading] = useState(true);
  const [redeemModalVisible, setRedeemModalVisible] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [planDetailsModalVisible, setPlanDetailsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    checkoutLoading,
    getProductsData,
    getProductsLoading,
    redeemSubscriptionLoading,
  } = useSelector((state) => state.pricingPageReducer);
  const { getUserDetailsData, getUserProfileDataLoading, subscriptionExists } = useSelector(
    (state) => state.account
  );

  const handleViewTransactionHistory = ({cancel, invoice, symbolConfirm, update}) => {
    if(symbolConfirm){
      confirm({
        title: "Any discount received in this annual plan will be charged back if you cancel this subscription.",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        content:
          "Are you sure you want to continue?",
        onOk() {
          dispatch(
            pricingPageAction.viewTransHistoryFun({
              email: getUserDetailsData?.email,
              ...(cancel ? {cancel} : {}),
              ...(invoice ? {invoice} : {}),
              ...(update ? {update} : {})
            })
          );
        },
        onCancel() {},
      });
    }
    else{
      dispatch(
        pricingPageAction.viewTransHistoryFun({
          email: getUserDetailsData?.email,
          ...(cancel ? {cancel} : {}),
          ...(invoice ? {invoice} : {}),
          ...(update ? {update} : {})
        })
      );
    }
  };

  const handleManageSubscription = () => {
    dispatch( pricingPageAction.viewTransHistoryFun() );
  }

  useEffect(() => {
    dispatch(pricingPageAction.getProductsFun({newlyAdded: false}));
    setManualLoading(false);
  }, []);

  let currentPlan = getUserDetailsData?.subscriptionData?.activePlan;
  let endDate = getUserDetailsData?.subscriptionData?.endDate;
  let startDate = getUserDetailsData?.subscriptionData?.startDate;
  let purchasedBy = getUserDetailsData?.subscriptionData?.purchasedBy?.fullName;

  const handleRedeem = (value) => {
    dispatch(pricingPageAction.redeemSubscriptionFun({
      planCode: value["Plan code"]
    }, handleRedeemModal));
  };

  const createSubscription = (ele) => {
    let payload;
    
    payload = {
      priceId: ele.default_priceId,
      quantity: 1
    };
   
    dispatch(pricingPageAction.createCheckoutFun(payload));
  };
  const handleRedeemModal = () => {
    setRedeemModalVisible(!redeemModalVisible)
    form.resetFields();
  };

  const handlePlanDetailsModalCancel = () => {
    setPlanDetailsModalVisible(false);
  };

  const validSubscriptionMessage = () => {
    return <CommonMessage message={getUserDetailsData?.subscriptionMessage}/>
  }

  const disablePlan = (getUserDetailsData, ele) => {
    return ((getUserDetailsData?.subscriptionData?.commonStatus !== "CANCELLED") && getUserDetailsData?.subscriptionData?.planDetails?.planId && (getUserDetailsData?.subscriptionData?.planDetails?.planId !== ele.default_priceId))
  }

  const handleSubscriptionSelect = (link) => {
    window.location.replace(link)
  }

  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "18px" }}>
            Plan Details
          </div>
        }
        onOk={handlePlanDetailsModalCancel}
        onCancel={handlePlanDetailsModalCancel}
        visible={planDetailsModalVisible}
        width={600}
        footer={null}
      >
        <div
          style={{
            width: "100%",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backgroundColor: "#8d958036",
            borderRadius: "20px",
            padding: "20px 60px 20px 40px",
          }}
        >
          <div className="planDetailsModalDiv">
            <p>Active Plan:</p>
            <p>{currentPlan} </p>
          </div>
          <div className="planDetailsModalDiv">
            <p>Purchased By:</p>
            <p>{purchasedBy} </p>
          </div>
          <div className="planDetailsModalDiv">
            <p>Start Date: </p>
            <p> {moment.unix(startDate).format("MMMM D, YYYY hh:mm A")}</p>{" "}
          </div>
          <div className="planDetailsModalDiv">
            <p>End Date: </p>

            <p> {moment.unix(endDate).format("MMMM D, YYYY hh:mm A")}</p>
          </div>
        </div>
      </Modal>

      <Modal
        title="Please enter your plan code"
        onOk={handleRedeem}
        onCancel={handleRedeemModal}
        visible={redeemModalVisible}
        footer={false}
      >
        {
          redeemSubscriptionLoading ? <Loading /> : <Form form={form} layout="horizontal" onFinish={handleRedeem}>
            <Form.Item
              label=""
              name="Plan code"
              hasFeedback
              rules={[
                { required: true, message: "Please enter a plan code" },
                { whitespace: true },
              ]}
            >
              <Input placeholder="Enter subscription code" allowClear />
            </Form.Item>

            <Form.Item style={{display: "flex", justifyContent: "center"}}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={redeemSubscriptionLoading}
              >
                Redeem
              </Button>
            </Form.Item>
          </Form>
        }
      </Modal>

      {manualLoading ||
      checkoutLoading ||
      getUserProfileDataLoading ||
      getProductsLoading ? (
        <Loading />
      ) : (
        <div
          style={{ height: "calc(100vh - 110px)", overflow: "auto" }}
          id="pricingPageContainer"
        >
          {true && (
            <section
              style={{
                width: "95%",
                margin: "auto",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <div></div>
              <div>
                {/* <h1
                  style={{
                    fontSize: "25px",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Choose the plan that’s right for you.
                </h1>
                <div style={{paddingBottom: "5px"}}>
                  Have a plan code? <span style={{textDecoration: "underline", textDecorationColor: "#006AFF", color: "#006AFF", cursor: "pointer"}} onClick={handleRedeemModal}>Redeem it here</span>
                </div> */}
                { validSubscriptionMessage() }
              </div>
              <div>
                {currentPlan && (
                  <Dropdown
                    trigger={["click", "hover"]}
                    overlay={
                      <MenuItem
                        setPlanDetailsModalVisible={setPlanDetailsModalVisible}
                      />
                    }
                  >
                    <MoreOutlined
                      key="ellipsis"
                      style={{
                        fontSize: "26px",
                        color: "#08c",
                        marginTop: "10px",
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            </section>
          )}
          <div>
            <section
              style={{
                width: "95%",
                margin: "auto",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {
                getProductsData?.activePlans?.map((plan)=>{
                  return <div style={{width: "350px", display: "flex", flexDirection: "column", gap: "20px", border: "1px solid #d4d4d4", borderRadius: "10px", padding: "15px 20px", margin: "15px", boxShadow: "3px 3px 3px #ccc"}}>
                    <div>
                      <div style={{fontWeight: "700", fontSize: "1.1rem"}}>{ plan?.planDisplayName }</div>
                      {
                        plan?.manageLink && <div onClick={handleManageSubscription} style={{textDecoration: "underline", cursor: "pointer"}}>Manage subscription</div>
                      }
                    </div>
                    <div style={{fontWeight: "600", fontSize: "0.9rem"}}>
                      <div>Start date: {moment(plan?.startDate).format("MMMM D, YYYY hh:mm A")}</div>
                      <div>End date: {moment(plan?.periodEndDate).format("MMMM D, YYYY hh:mm A")}</div>
                    </div>
                  </div>
                })
              }
            </section>
            <div style={{textAlign: "center", fontWeight: "600", fontSize: "1.1rem", marginTop: "25px"}}>{getProductsData?.templates ? <span><FieldTimeOutlined /> Available Plans</span> : ""}</div>
            <section
              style={{
                width: "95%",
                margin: "auto",
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                gap: "20px"
              }}
            >
              {getProductsData?.templates?.map((ele, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      width: "350px",
                      boxShadow: "3px 3px 3px #ccc",
                      padding: "15px 20px",
                      border: "1px solid #d4d4d4",
                      borderRadius: "10px"
                      // backgroundColor: ( (getUserDetailsData?.subscriptionData?.commonStatus !== "CANCELLED") ? ((getUserDetailsData?.subscriptionData?.planDetails?.planId === ele.default_priceId) ? "#c0e6f8" : "white") : "white")
                    }}
                  >
                    <div
                      style={{
                        height: "160px"
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "22px",
                          height: "40px",
                        }}
                      >
                        {ele.name}
                      </h1>
                      <text
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "100px",
                        }}
                      >
                        {ele.description}
                      </text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "20px",
                      }}
                    >
                      <h1
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        {ele.discontedPrice}
                      </h1>
                      <h1
                        style={{
                          paddingLeft: "5px",
                          color: "#085191",
                          height: "60px"
                        }}
                      >
                        {ele.percent_off ? <span><span style={{textDecoration: "line-through", fontWeight: "bold"}}>${ele.monthlyOriginalPrice || ele.yearlyOriginalPrice}</span> <span style={{fontWeight: "bold"}}>${ele.monthlyPrice || ele.yearlyPrice}</span> {ele.monthlyPrice ? "/month" : "/year"} </span> : <span><span style={{fontWeight: "bold"}}>${ele.monthlyPrice}</span>/month</span>}
                      </h1>
                    </div>
                    <h3 style={{ marginTop: "20px" }}>{ele?.featuresHeading}</h3>
                    {ele.features.map((el, i) => (
                      <div key={i} style={{ marginTop: "22px" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            fontWeight: "500",
                          }}
                        >
                          <CheckOutlined
                            style={{
                              marginRight: "10px",
                              marginTop: "10px",
                            }}
                          />
                          <p>{el}</p>{" "}
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={() => handleSubscriptionSelect(ele?.link)}
                      style={{
                        width: "100%",
                        height: "50px",
                        borderRadius: "8px",
                        backgroundColor: "#006AFF",
                        color: "white",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <div style={{fontSize: "18px", letterSpacing: "1px"}}>
                        { ele.btnText }
                      </div>
                    </button>
                  </div>
                );
              })}
            </section>
          </div>
          {/* <section
            style={{
              width: "95%",
              margin: "auto",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
              borderRadius: "10px",
            }}
          >
            {getProductsData.map((ele, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "350px",
                    borderRight: "1px solid lightgrey",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    backgroundColor: ( (getUserDetailsData?.subscriptionData?.commonStatus !== "CANCELLED") ? ((getUserDetailsData?.subscriptionData?.planDetails?.planId === ele.default_priceId) ? "#c0e6f8" : "white") : "white")
                  }}
                >
                  <div
                    style={{
                      height: "160px",
                      marginTop: "20px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "22px",
                        height: "40px",
                      }}
                    >
                      {ele.name} {(getUserDetailsData?.subscriptionData?.commonStatus !== "CANCELLED") ? (getUserDetailsData?.subscriptionData?.planDetails?.planId === ele?.default_priceId ? <CheckCircleOutlined style={{marginLeft: "10px", color: "green"}}/> : "") : ""}
                    </h1>
                    <text
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        height: "100px",
                      }}
                    >
                      {ele.description}
                    </text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "20px",
                    }}
                  >
                    <h1
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                      {ele.discontedPrice}
                    </h1>
                    <h1
                      style={{
                        paddingLeft: "5px",
                        color: "#085191",
                        height: "60px"
                      }}
                    >
                      {ele.percent_off ? <span><span style={{textDecoration: "line-through", fontWeight: "bold"}}>${ele.monthlyOriginalPrice || ele.yearlyOriginalPrice}</span> <span style={{fontWeight: "bold"}}>${ele.monthlyPrice || ele.yearlyPrice}</span> {ele.monthlyPrice ? "/month" : "/year"} </span> : <span><span style={{fontWeight: "bold"}}>${ele.monthlyPrice}</span>/month</span>}
                      <span className="astrikLegend">{ele.showSymbol}</span>
                    </h1>
                  </div>
                  {
                    ((getUserDetailsData?.subscriptionData?.commonStatus !== "CANCELLED") && ((getUserDetailsData?.subscriptionData?.planDetails?.planId === ele.default_priceId)) ? 
                    <div style={{fontSize: "17px", display: "flex", justifyContent: "space-evenly"}}>
                      <div style={{width: "400px"}}>
                        <div onClick={()=>handleViewTransactionHistory({cancel: true, symbolConfirm: ele.showSymbol})} style={{color: "red", textDecoration: "underline", textDecorationColor: "red", cursor: "pointer"}}>Cancel</div>
                        <div onClick={()=>handleViewTransactionHistory({invoice: true})}style={{color: "#006AFF", textDecoration: "underline", textDecorationColor: "#006AFF", cursor: "pointer"}}>Get Invoice</div>
                      </div>
                      <div style={{width: "400px", paddingLeft: "15px", borderLeft: "1px solid #8c8c8c"}}>
                        <div onClick={()=>handleViewTransactionHistory({update: true})}style={{color: "#006AFF", textDecoration: "underline", textDecorationColor: "#006AFF", cursor: "pointer"}}>Update Payment</div>
                      </div>
                    </div> : <button
                    onClick={() => createSubscription(ele)}
                    style={{
                      width: "100%",
                      height: "50px",
                      borderRadius: "8px",
                      backgroundColor: ((disablePlan(getUserDetailsData, ele)) ? "grey" : "#006AFF"),
                      color: "white",
                      border: "none",
                      cursor: ((disablePlan(getUserDetailsData, ele)) ? "not-allowed" : "pointer"),
                    }}
                    disabled={disablePlan(getUserDetailsData, ele)}
                  >
                    <div style={{fontSize: "18px", letterSpacing: "1px"}}>
                     { ele.btnText }
                    </div>
                  </button>)
                  }
                  <h3 style={{ marginTop: "20px" }}>{ele?.featuresHeading}</h3>
                  {ele.features.map((el, i) => (
                    <div key={i} style={{ marginTop: "22px" }}>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          fontWeight: "500",
                        }}
                      >
                        <CheckOutlined
                          style={{
                            marginRight: "10px",
                            marginTop: "10px",
                          }}
                        />
                        <p>{el}</p>{" "}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
            
          </section> */}
          {/* <div style={{display: "flex", justifyContent: "center", color: "grey", alignItems: "center", fontWeight: "500", fontSize: "0.9rem"}}><span className="astrikLegend" style={{fontSize: "1.7rem", marginTop: "8px", marginRight: "5px"}}>* </span> Annual commitment required. Early cancellation fees apply.</div> */}
          {/* <section
            style={{
              width: "80%",
              margin: "auto",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <Title level={1}>Leading the way in enterprise security</Title>
            <div
              style={{
                width: "50%",
                margin: "auto",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <Title level={4}>
                In addition to data encryption in transit and at rest, we
                operate comprehensive compliance and assurance programmes.
              </Title>
            </div>{" "}
          </section> */}
          {/* <section
            style={{
              width: "30%",
              margin: "auto",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              textDecoration: "underline",
              color: "cornflowerblue",
            }}
          >
            <a href="#">
              Learn more about security
              <ArrowRightOutlined
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              />
            </a>
          </section> */}
          {/* <section
            style={{
              width: "80%",
              margin: "auto",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <Title level={1}>Frequently asked questions</Title>
          </section> */}

          {/* <section
            style={{
              width: "80%",
              margin: "auto",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <hr />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setPayment(!payment);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                What are my payment options – credit card and/or invoicing?
              </text>

              <div style={{ fontSize: "20px", fontWeight: "500" }}>
                {payment ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {payment ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  You can use your credit card to pay for any subscription. If
                  you sign up for an annual subscription and plan to spend at
                  least ₹375,000, we can invoice you annually. Visit the Help
                  Centre for more information or Contact us to start the
                  process.
                </text>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAccount(!account);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                Our team has several accounts that are not being used actively
                but we don’t want to disable. Do we still have to pay for them?
              </text>

              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                {account ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {account ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  Every day we check to see if everyone on your team is active.
                  If they have been inactive for a period of time, we stop
                  charging you for that person and add pro-rata credit to your
                  account for the time you’ve already paid. You’ll always and
                  only be charged for what you use – fair and square!
                </text>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCredit(!credit);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                My team have credit. How do we use it?
              </text>

              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                {credit ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {credit ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  Once your team take out a subscription, we’ll redeem your
                  credit balance until it runs out. We won’t start charging your
                  subscription to your credit card until then. As long as you
                  sign up for a subscription before the expiry date, that credit
                  is yours to use until it’s all gone.
                </text>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setUser(!user);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                We need to add new users to our team. How will we be charged for
                that?
              </text>

              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                {user ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {user ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  We’ll apply a one-off, pro-rata charge to your credit card to
                  cover your new team member’s account for the remainder of the
                  current billing period.
                  <div style={{ marginTop: "20px" }}>
                    As an example, suppose your team is on the ‘Pro
                    subscription’, which costs ₹655 per active user per month
                    when billed monthly. You add a new team member ten days into
                    your billing period, and there are 20 remaining days in the
                    month. The per-day cost for each user is ₹655 / 30, so we
                    will make a one-time charge of ₹436.67 (₹655 / 30 days * 20
                    days) to your team’s credit card on file..
                  </div>
                </text>
              </div>
            ) : (
              ""
            )}

            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setOffer(!offer);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                My team want to cancel their subscription. How can we do that?
                Can we get a refund?
              </text>

              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                {offer ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {offer ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  Team owners can change the team’s subscription level. There
                  are two options when downgrading from a paid subscription to
                  the Free subscription:
                  <div style={{ marginTop: "20px" }}>
                    <li>
                      You can downgrade to the Free subscription immediately. If
                      there is more than one day until your renewal date, we
                      will adjust the remaining balance on your account pro-rata
                      and hold it for your team as a credit. You can use that
                      credit towards any reactivation of your paid subscription
                      in the future.
                    </li>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <li>
                      We can automatically downgrade you to the Free
                      subscription at your next renewal date. Your subscription
                      will remain active at its current level until the end of
                      the billing period that you’ve already paid for.
                    </li>
                  </div>
                </text>
                <div style={{ marginTop: "20px" }}>
                  We do not offer refunds.
                </div>
              </div>
            ) : (
              ""
            )}

            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setDiscount(!discount);
              }}
            >
              <text
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                Do you offer discounts for charities/not-for-profit
                organisations or educational institutions?
              </text>

              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                {discount ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
            {discount ? (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "18px",
                }}
              >
                <text
                  style={{
                    fontSize: "17px",
                    color: "darkslategrey",
                  }}
                >
                  We’ll apply a one-off, pro-rata charge to your credit card to
                  cover your new team member’s account for the remainder of the
                  current billing period. Slack wants to support people in the
                  world who are doing good things. That’s why we offer special
                  discount pricing for qualifying charities/not-for-profit
                  organisations and educational institutions.
                </text>
                <div
                  style={{
                    color: "cornflowerblue",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  <a href="#">Slack for Charities </a>
                  <ArrowRightOutlined style={{ marginLeft: "10px" }} />
                </div>
                <div
                  style={{
                    color: "cornflowerblue",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  <a href="#">Slack for Education</a>{" "}
                  <ArrowRightOutlined style={{ marginLeft: "10px" }} />
                </div>
              </div>
            ) : (
              ""
            )}
          </section> */}

          {/* <section
            style={{
              width: "80%",
              margin: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <hr />
            <div style={{ fontSize: "18px", marginTop: "20px" }}>
              <text style={{ fontSize: "18px", marginTop: "20px" }}>
                Get more information on
                <a
                  href="https://app.slack.com/plans/T01C47DA6AY?geocode=en-in"
                  style={{
                    color: "cornflowerblue",
                    textDecoration: "underline",
                    marginLeft: "10px",
                  }}
                >
                  billing at Slack.
                </a>
              </text>
            </div>
          </section> */}
        </div>
      )}
    </>
  );
};

const CommonMessage = ({message}) => {
  return <>
    <p
      style={{
        color: "#BB5504",
        display: "flex",
        margin: "auto",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AiFillClockCircle style={{ fontSize: "20px",}} /> {message}
    </p>
  </>
}

export default Pricing;
