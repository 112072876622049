import { Spin, Table } from 'antd';
import React, { useEffect } from 'react';
import { CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import styles from "../style/rtd.module.css"
import { useState } from 'react';

// import { clientScreenHandle } from './state/actions';
import { EmptyData } from '../../Common/components/EmptyData';
import { transactionLaneDataHandle } from '../state/actions';
import moment from 'moment';

const DocumentSign = ({id,offerIds,role}) => {
  const { clientPortalData,setLoading } = useSelector((state) => state.rtdTransactionLane);
  const { getUserDetailsData } = useSelector((state) => state.account);
  const [isHovered, setIsHovered] = useState(null);
  const [isHovered2, setIsHovered2] = useState(null);
  const arrowContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };


  const [signedDoc, setSignedDoc] = useState([]);
  const [unSignedDoc, setUnSignedDoc] = useState([]);
  const convertTimestampToMMDDYYYY = (timestamp) => {
    let date = moment(timestamp).format("MM/DD/YYYY");
    return date;
  }
  const convertTimestampTotime = (timestamp) => {
    const currentTime = moment(timestamp).format('h:mm A');
    return currentTime;
  }
  useEffect(()=>{
    let signed = []
    let unSigned = [];
    clientPortalData?.forEach((ele,index) =>{
      if((ele?.signedDetail?.signed === false) || ((ele?.signedObjectionDetail?.signPermissionGranted === true) && (ele?.signedObjectionDetail?.signed === false))){
        const timestamp = ele?.sentDateMiliBuyer || ele?.sentDateMiliSeller;
        const formattedDate = convertTimestampToMMDDYYYY(timestamp);
        const currentTime=convertTimestampTotime(timestamp)
        ele.timeStamp =formattedDate + " " + currentTime;
        unSigned.push(ele);
      }
      else{
        const timestamp = ele?.signedObjectionDetail?.timeStamp || ele?.signedDetail?.timeStamp;
        const formattedDate = convertTimestampToMMDDYYYY(timestamp);
        const currentTime=convertTimestampTotime(timestamp)
        ele.timeStamp =formattedDate + " " + currentTime ;
        signed.push(ele);
      }
    })
    setSignedDoc(signed);
    setUnSignedDoc(unSigned);
  }, [ clientPortalData])
  return (
    <div
    className={styles.documentContainer}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height:"8%",
          backgroundColor: " #0078d4",
          alignItems:"center",
          padding: "2px",
          marginTop:"-20px"
        }}
      >
        <div style={{ width: "20.33%", textAlign: "center" ,fontWeight:"bold"}} className={styles.heading}>Action</div>
        <div style={{ width: "36.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>Document</div>
        <div style={{ width: "40.33%", textAlign: "center",fontWeight:"bold" }} className={styles.heading}>Date/Time</div>
      </div>
      <div style={{ width: "100%",  height: "92%" }}>
      {setLoading?<div style={{ width: "100%", fontSize: "15px", height:"50%", color: "black",textAlign:"center",paddingTop:"10%"}}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px", height:"50%", color: "black", overflowY: "auto" }}>
          {(unSignedDoc&& unSignedDoc?.length) ? unSignedDoc?.map((ele,index) => (
              <div
                    key={index}

                style={{
                  display: "flex",
                  backgroundColor: isHovered===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  alignItems:"center",
                  padding:"3px"
                }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}

              >
              <div style={{ width: "20.33%", fontSize: "15px", display: "flex", justifyContent: "center" }}>
                <div style={arrowContainerStyle}>
                  <div style={{ width: "40px", backgroundColor: ele?.ispdfClone || ele?.isPdf || !ele?.isSignatureRequired ? "lightgray" : "yellow", height: "20px", display: "flex", alignItems: "center" }}>
                    <text style={{ color: "black", fontSize: "12px", width: "100%", textAlign: "center", fontWeight: "bold" }}>{ele?.isSignatureRequired ? "Sign" : "View"}</text>
                  </div>
                  <div className={ele?.ispdfClone || ele?.isPdf || !ele?.isSignatureRequired ? styles.arrowStyle2 : styles.arrowStyle}></div>
                </div>
              </div>
              <div style={{ width: "36.33%", textAlign: "center", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                {ele?.contractName}
              </div>
              <div style={{ width: "40.33%", textAlign: "center" }}>{ele?.timeStamp}</div>
            </div>
            )
          ):<EmptyData/>}
        </div>}
        <hr style={{ color: "#0078d4" }} />

        {setLoading?<div style={{ width: "100%", fontSize: "15px",height: "47%", color: "black",textAlign:"center",paddingTop:"10%"}}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px",height: "43%", color: "black", overflowY: "auto" }}>
          {(signedDoc && signedDoc.length) ? signedDoc?.map((ele,index) => (
              <div
              key={index}
              style={{
                  display: "flex",
                  backgroundColor: isHovered2===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  alignItems:"center"
                }}
                onMouseEnter={() => setIsHovered2(index)}
                onMouseLeave={() => setIsHovered2(null)}

               >
                <div style={{ width: "20.33%", fontSize: "20px", color: "green", textAlign:"center"}}>
                  <CheckCircleOutlined />
                </div>
                <div style={{ width: "36.33%", textAlign: "center",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden"  }}>
                {ele?.contractName}</div>
                <div style={{ width: "40.33%", textAlign: "center" }}> {ele?.timeStamp} </div>
              </div>
            )
          ):<EmptyData />}
        </div>}
      </div>
    </div>
  );
}

export { DocumentSign };
