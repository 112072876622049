import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  pastCLientDetails: [],
  pastCLientLoading: false,
  pastCLientError: null,
};

const pastClientReducer = (state = initialState, action) => {
  switch (action.type) {
    // past Client
    case clientConstants.PAST_CLIENTS_LOADING:
      return {
        ...state,
        pastCLientDetails: action.pastCLientDetails,
        globalClientLoading: true,
      };
    case clientConstants.PAST_CLIENTS_SUCCESS:
      return {
        ...state,
        pastCLientDetails: action.pastCLientDetails,
        globalClientLoading: false,
      };
    case clientConstants.PAST_CLIENTS_FAILURE:
      return {
        ...state,
        pastCLientError: action.pastCLientError,
        pastCLientLoading: action.pastCLientLoading,
      };
    default:
      return state;
  }
};
export { pastClientReducer };
