import React from "react";
import { Progress } from "antd";
function CompletedPercentage({ progress }) {
  return (
    <div style={{ marginTop: "-23px" }}>
      <Progress
        type="circle"
        strokeColor={{
          "0%": "#108ee9",
          "100%": "#87d068",
        }}
        percent={progress}
        // width={180}
      />
    </div>
  );
}
export { CompletedPercentage };
