import { combineReducers } from "redux";
import { reminderReducer } from "./reducers/reminderReducer";
import { notesReducer } from "./reducers/notesReducer";
import { scheduleReducer } from "./reducers/scheduleReducer";
import { newsletterReducer } from './reducers/newsletterReducer'
import {supportReducer } from "./reducers/supportReducer";

const landingPageReducer = combineReducers({
  reminderReducer,
  notesReducer,
  scheduleReducer,
  newsletterReducer,
  supportReducer,
});

export { landingPageReducer };
