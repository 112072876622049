import { documentConstants } from "../action-types";

const initialState = {
  getCbsrId: "",
};

const CbsrReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.CBSR_CONTRACT_ID: {
      return {
        ...state,
        getCbsrId: action.payload,
      };
    }

    default:
      return state;
  }
};
export { CbsrReducer };
