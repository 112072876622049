import React from "react";
import { IoIosCreate } from "react-icons/io";
import { MdOutlineUploadFile } from "react-icons/md";
const CreatedDoraDocsModalContent = ({ handleCreateNew, handleUploadNew }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "5px",
        width: "51%",
        margin: "auto",
        paddingLeft: "30px",
      }}
    >
      <p
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          fontSize: "20px",
          color: "#2976be",
          cursor: "pointer",
        }}
        onClick={handleCreateNew}
      >
        <IoIosCreate size={25} /> Create New
      </p>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          fontSize: "20px",
          color: "#2976be",
          cursor: "pointer",
        }}
        onClick={handleUploadNew}
      >
        <MdOutlineUploadFile size={25} /> Upload PDF
      </p>
    </div>
  );
};

export default CreatedDoraDocsModalContent;
