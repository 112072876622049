import { combineReducers } from "redux";
import { buildContractReducer } from "./reducers/buildContractReducer";
import { categoryReducer } from "./reducers/categoryReducer";
import { CbsrReducer } from "./reducers/CbsrReducer";
import { clearDataReducer } from "./reducers/clearDataReducer";
import { createCustomerReducer } from "./reducers/createCustomerReducer";
import { draftDocumentReducer } from "./reducers/draftDocumentsReducer";
import { offerReducer } from "./reducers/offerReducer";
import { onFocusReducer } from "./reducers/onFocusReducer";
import { CorpReducer } from "./reducers/CorpReducer";
import { PropertySearchReducer } from "./reducers/PropertySearchReducer";
import { realtorReducer } from "./reducers/realtorReducer";
import { searchBuyerReducer } from "./reducers/searchBuyerReducer";
import { urlImportReducer } from "./reducers/urlImportReducer";

const documentSection = combineReducers({
  buildContractReducer,
  categoryReducer,
  CbsrReducer,
  clearDataReducer,
  createCustomerReducer,
  draftDocumentReducer,
  offerReducer,
  onFocusReducer,
  CorpReducer,
  PropertySearchReducer,
  realtorReducer,
  searchBuyerReducer,
  urlImportReducer,
});

export { documentSection };
