import { landingPageConstants } from "../action-types";

const initialState = {
    data: [],
    getNewsletterLoading: false,
    getNewsletterPageDataError: null,
}

const newsletterReducer = (state = initialState, action) => {
    switch (action.type) {
        case landingPageConstants.GET_NEWSLETTER_LOADING:
            return {
                ...state,
                getNewsletterLoading: true,
                getNewsletterPageDataError: false,
            };
        case landingPageConstants.GET_NEWSLETTER_PAGE_DATA_SUCCESS:
            return {
                ...state,
                getNewsletterLoading: false,
                data: action.payload,
                getNewsletterPageDataError: false,
            }
        case landingPageConstants.GET_NEWSLETTER_PAGE_DATA_FAILURE:
            return {
                ...state,
                getNewsletterLoading: false,
                getNewsletterPageDataError: true,
            }
        default:
            return state;
    }
}

export { newsletterReducer };