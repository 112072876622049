import {  toolsConstants } from "../action-types";

const initialState = {
 getemailDripConstantData: [],
 getemailDripConstantRealtorData:[],
 getemailDripConstantLoading: false,
 getemailDripConstanterror: false,
};

const emailDripReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_LOADING:
      return {
        ...state,
        getemailDripConstantLoading:action.getemailDripConstantLoading
      };
    case toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_SUCCESS:
      return {
        ...state,
        getemailDripConstantData: action.getemailDripConstantData,
        getemailDripConstantLoading:action.getemailDripConstantLoading
       
      };
      case toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_FAILURE:
        return {
            ...state,
            getemailDripConstanterror: action.getemailDripConstanterror
          };
          case toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_REALTOR_SUCCESS:
            return {
              ...state,
              getemailDripConstantRealtorData:action.getemailDripConstantRealtorData,
              getemailDripConstantLoading:action.getemailDripConstantLoading
            }
    default:
      return state;
  }
};
export { emailDripReducer };