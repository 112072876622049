import { Tooltip, Typography } from "antd";
import React from "react";
import { FaBirthdayCake } from "react-icons/fa";
import styles from "../styles/Client.module.css";
import { BirthDateDetails } from "./BirthDateDetails";

const Birthday = ({ personDetails, selectedClient, setModelDrawer }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Birthday"} placement="left" >
          <FaBirthdayCake className={styles.textAlignment} />
        </Tooltip>
      </div>

                    <div
                      className={styles.iconsbox}
                      onClick={() =>
                        setModelDrawer({
                          status: true,
                          component: (
                            <BirthDateDetails
                              setModelDrawer={setModelDrawer}
                              data={personDetails}
                              selectedClient={selectedClient}
                            />
                          ),
                          title: "BIRTHDAY DETAILS",
                        })
                      }
                    >
                      <div className={styles.iconsclient}>
                        <img
                          src="icons/client-details-birthday.svg"
                          alt=""
                        ></img>
                      </div>
                      <div
                        className={styles.userfeild}
                        style={{ marginLeft: "-10%" }}
                      >
                        <Text  className={styles.userfeild}>
                          {" "}
                          {personDetails.birthDate &&
                          personDetails.birthDate !== "NaN"
                            ? [
                              ('0' + ((new Date(personDetails.birthDate)).getMonth() + 1)).slice(-2),
                              ('0' + (new Date(personDetails.birthDate)).getDate()).slice(-2),
                              (new Date(personDetails.birthDate)).getFullYear()
                            ].join('-')
                            : "Select Birthday"}
                        </Text>
                      </div>
                    </div>
                  </Text>
  )
}

export { Birthday };
