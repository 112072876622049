import { appConstants } from "./action-types";

const initialState = {
  appTitle: "test",
  getNotificationData: [],
  totalrecords:null,
  getNotificationLoading: null,
  getNotificationError: null,
  paginationdata:[],
 updatenotification:true,
  delteNotificationData: null,
  delteNotificationLoading: null,
  delteNotificationError: null,

  getNotificationCountData: {},
  getNotificationCountLoading: null,
  getNotificationCountError: null,

  updateNotificationData: null,
  updateNotificationLoading: null,
  updateNotificationError: null,

  manualLoading:false,

  clientScreenLogout:false,


  getLocationLoading: null,
  getLocationError: null,

  retsDataData: null,
  retsDataLoading: null,
  retsDataError: null,

  helpData:[],
  helpDataLoading:false,
  helpDataError:null,



  demoMode: false,
  customerDeskLoading: {
    status: false,
    message: ""
  },
  customerDeskError: {
    status: false,
    message: ""
  },
  customerDeskSuccess: {
    status: false,
    message: ""
  },
  loadOpacityLoader: false,

  isAppUpdateAvailable: false,
  isAppUpdateAvailableLoading: false,
};

const app = (state = initialState, action = {}) => {
  switch (action.type) {
  
    case appConstants.DEMO_MODE_SUCCESS:
      return {
        ...state,
        demoMode: action.demoMode,
      };
    case appConstants.UPDATE_APP_TITLE:
      return {
        ...state,
        appTitle: action.appTitle,
      };
    case appConstants.SET_MANUAL_LOADING:
      return {
        ...state,
        manualLoading: action.manualLoading,
      };
      case appConstants.CLIENT_SCREEN_LOGOUT:
        return {
          ...state,
          clientScreenLogout: action.clientScreenLogout,
        };
    case appConstants.NOTIFICATION_SUCCESS:
      return {
        ...state,
        getNotificationData: action.getNotificationData.data,
        totalrecords:action.getNotificationData.totalRecords,
       paginationdata:action.source==="delete"?action.getNotificationData.data:[...state.paginationdata,...action.getNotificationData.data],
        getNotificationLoading: action.getNotificationLoading,
        getNotificationError: null,
        updatenotification:false
      };
    case appConstants.NOTIFICATION_FAILURE:
      return {
        ...state,
        getNotificationData: [],
        getNotificationLoading: action.getNotificationLoading,
        getNotificationError: action.getNotificationError,
        updatenotification:false,
      };
      case appConstants.NOTIFICATION_LOADING:
        return {
          ...state,
          getNotificationLoading:true,
          getNotificationError:false,
          updatenotification:false,
        }
    case appConstants.DELETE_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        paginationdata:action.paginationdata,
        deleteNotificationData: action.deleteNotificationData,
        deleteNotificationLoading: action.deleteNotificationLoading,
        deleteNotificationError: null,
        updatenotification:false,
      };

    case appConstants.NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        getNotificationCountData: action.getNotificationCountData,
        getNotificationCountLoading: action.getNotificationCountLoading,
        getNotificationCountError: null,

      };
    case appConstants.NOTIFICATION_COUNT_FAILURE:
      return {
        ...state,
        getNotificationCountData: {},
        getNotificationCountLoading: action.getNotificationCountLoading,
        getNotificationCountError: action.getNotificationCountError,

      };
    case appConstants.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateNotificationData: action,
        updateNotificationLoading: action.updateNotificationLoading,
        updateNotificationError: null,

      };
    case appConstants.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        updateNotificationData: [],
        updateNotificationLoading: action.updateNotificationLoading,
        updateNotificationError: action.updateNotificationError,
      };
      case appConstants.HELP_DATA_SUCCESS:
        return {
          ...state,
          helpData: action.helpData,
          helpDataLoading: action.helpDataLoading,
          helpDataError: null,
        };
      case appConstants.HELP_DATA_LOADING:
        return {
          ...state,
          helpDataLoading: action.helpDataLoading,
        };
      case appConstants.HELP_DATA_ERROR:
        return {
          ...state,
          helpData: [],
          helpDataLoading: action.helpDataLoading,
          helpDataError: action.helpDataError,
        };
      case appConstants.CLIENT_DESK_LOADING: 
        return {
          ...state,
          customerDeskLoading: {
            status: true,
            message: action.message
          },
          customerDeskError: {
            status: false,
            message: ""
          },
          customerDeskSuccess: {
            status: false,
            message: ""
          }
        }
      case appConstants.CLIENT_DESK_ERROR: 
        return {
          ...state,
          customerDeskLoading: {
            status: false,
            message: ""
          },
          customerDeskSuccess: {
            status: false,
            message: ""
          },
          customerDeskError: {
            status: true,
            message: action.message
          }
        }
      case appConstants.CLIENT_DESK_SUCCESS: 
        return {
          ...state,
          customerDeskLoading: {
            status: false,
            message: ""
          },
          customerDeskSuccess: {
            status: true,
            message: action.message
          },
          customerDeskError: {
            status: false,
            message: ""
          }
        }
    case appConstants.LOAD_OPACITY_LOADER:
      return {
        ...state,
        loadOpacityLoader: action.loadOpacityLoader,
      };
    case appConstants.APP_UPDATE_VERSION_LOADING:
      return {
        ...state,
        isAppUpdateAvailableLoading: true
      }
    case appConstants.APP_UPDATE_VERSION_ERROR:
      return {
        ...state,
        isAppUpdateAvailableLoading: false
      }
    case appConstants.APP_UPDATE_VERSION_SUCCESS:
      return {
        ...state,
        isAppUpdateAvailable: action.isAppUpdateAvailable,
        isAppUpdateAvailableLoading: false,
      }
    default:
      return state;
  }
};
export { app };
