/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout, Alert, Result } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { accountHandle } from "../../Account/state/actions";
import dotEnv from "dotenv";
import { ClientNavbar } from "./ClientNavbar";
const { Content } = Layout;
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * @function ClientPrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

export const ClientPrivateRoute = ({
  component: Component,
  roleDisplay,
  networkConnection,
  ...rest
}) => {
  const dispatch = useDispatch();
  let accessToken = localStorage.getItem("clientAuth");
  const { getUserDetailsLoading, getUserDetailsError } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(accountHandle.getClientDetails());
    }
  }, [accessToken]);

  const { manualLoading, clientScreenLogout } = useSelector((state) => state.app);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          accessToken ? (
            <>
              {getUserDetailsLoading || manualLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : getUserDetailsError ? (
                <Result
                  status="404"
                  title="404"
                  subTitle="Not Authorized"
                />
              ) : (
                <div
                  style={{
                    height: "100vh",
                  }}
                >
                  <ClientNavbar />
                  <Layout id="mainLayoutClient">
                    <Content
                      style={{
                        backgroundColor: "rgb(255 255 255)",
                        padding: "0 0rem 0 0rem",
                      }}
                    >
                      {networkConnection && (
                        <div>
                          <Alert message="No internet" type="error" />
                        </div>
                      )}
                      <Component
                        networkConnection={networkConnection}
                        {...props}
                      />
                    </Content>
                  </Layout>
                </div>
              )}{" "}
            </>
          ) : (
              clientScreenLogout ? (
              <Result
                status="success"
                title="Thank you for using RE-Sure."
              />
              ) :
              <Result
                status="404"
                title="404"
                subTitle="Page Not Found"
              />
          )
        }
      />
    </>
  );
};
