import React from "react";
import { CurrentDocumentTransaction } from "./CurrentDocumentTransaction";
import { DraftDocumentTransaction } from "./DraftDocumentTransaction";
import { PastDocumentTransaction } from "./PastDocumentTransaction";

const TransactionDetailComponent = ({
  id,
  handleMenuChange,
  newRedirectClientToOffer,
  setSelectedClientData,
}) => {
  return (
    <div>
      <DraftDocumentTransaction
        id={id}
        handleMenuChange={handleMenuChange}
        newRedirectClientToOffer={newRedirectClientToOffer}
        documents="DRAFT"
      />
      <CurrentDocumentTransaction
        id={id}
        handleMenuChange={handleMenuChange}
        setSelectedClientData={setSelectedClientData}
        newRedirectClientToOffer={newRedirectClientToOffer}
        documents="CURRENT"
      />
      <PastDocumentTransaction
        id={id}
        handleMenuChange={handleMenuChange}
        documents={"PAST"}
      />
    </div>
  );
};

export default TransactionDetailComponent;
