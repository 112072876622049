import React from "react";
import { Menu } from "antd";
import {
  CalendarOutlined,
  FileTextOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { PropertyHandlers } from "../../PropertyComp/state/actions";

function ClientListingModal({
  selectedItem,
  id,
  morphedHandleMenuChange,
  item,
  setSelectedClientData,
}) {
  const dispatch = useDispatch();
  const handleClendar = () => {
    let urlData = {
      urlData: {
        offerId: id,
        key: localStorage.getItem("userAuth"),
        persistId: null,
        redirectTo: "webApp",
      },
    };
    dispatch(PropertyHandlers.urlShorthandApiCalling(urlData));
  };
  const handleDocument = () => {
    setSelectedClientData(selectedItem);
    dispatch(transactionLaneDataHandle.getTransactionLaneMethod(id));
  };
  return (
    <Menu onClick={(event) => morphedHandleMenuChange(event, selectedItem)}>
      <Menu.Item
        key="0"
        icon={
          <ApartmentOutlined
            style={{ color: "green", fontSize: "2rem", fontWeight: "bolder" }}
          />
        }
        style={{ fontSize: "2rem", color: "rgba(0, 0, 0, 0.45)" }}
      >
        Tasks
      </Menu.Item>
      {/* <Menu.Item
        key="1"
        icon={
          <EyeOutlined
            style={{ color: "green", fontSize: "22px", fontWeight: "bolder" }}
          />
        }
      >
        View Contract
      </Menu.Item> */}
      <Menu.Item
        key="2"
        icon={
          <FileTextOutlined
            style={{ color: "green", fontSize: "2rem", fontWeight: "bolder" }}
          />
        }
        style={{ fontSize: "2rem", color: "rgba(0, 0, 0, 0.45)" }}
        disabled={item?.status === "SUBMITTED" ? true : false}
        onClick={handleDocument}
      >
        Document
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={
          <CalendarOutlined
            style={{ color: "green", fontSize: "2rem", fontWeight: "bolder" }}
          />
        }
        style={{ fontSize: "2rem", color: "rgba(0, 0, 0, 0.45)" }}
        onClick={handleClendar}
      >
        Calendar
      </Menu.Item>
      {/* <Menu.Item
        key="4"
        icon={
          <DollarCircleOutlined
            style={{ color: "green", fontSize: "2rem", fontWeight: "bolder" }}
          />
        }
        style={{ fontSize: "2rem", color: "rgba(0, 0, 0, 0.45)" }}
      >
        Offer Table
      </Menu.Item> */}
    </Menu>
  );
}
export { ClientListingModal };
