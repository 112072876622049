import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// const personSignUpRequest = () => {
//   let accessTokan = localStorage.getItem("userAuth");

//   return axios.get(`${apiURL}/person`, {
//     params: {
//       skip: 0,
//       limit: 50,
//     },
//     headers: {
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

const personSignUpRequest = (data) => {
  // let accessTokan = localStorage.getItem("userAuth");

  return axios.post(`${apiURL}/person/register`, data, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

const userForgetPasswordRequest = (data) => {
  let mail = { emailId: data.email };
  return axios.post(`${apiURL}/person/forgetPasswordInti`, mail, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

const userForgetPasswordCodeVerificationRequest = (data, email) => {
  let code = { code: data, emailId: email };
  return axios.post(`${apiURL}/person/codeVerification`, code, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

const resetPasswordSaveNewPasswordRequest = (data, email, code) => {
  let payloadData = { password: data, emailId: email, code };
  return axios.post(`${apiURL}/person/passwordChange`, payloadData, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

const userLogInApiRequest = (data) => {
  return axios.post(`${apiURL}/person/login`, data, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

const userLogOutApiRequest = (data) => {
  const accessToken = localStorage.getItem("userAuth");
  const userResponse = JSON.parse(localStorage.getItem("userResponse")) || {};
  const deviceToken = userResponse.deviceToken || accessToken;
  return axios.post(`${apiURL}/person/logout`, {deviceToken}, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getAssociationData = () => {
  
  return axios.get(`${apiURL}/getAssociationNoAuth`,{
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  })
}

const getPriceDetails = () => {
  return axios.get(`${apiURL}/getAllPricingAvailable`,{
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  })
}

// const changeStatusRequest = (id, status) => {
//   let accessTokan = localStorage.getItem("auth");
//   return axios.put(
//     `${apiURL}/person/${id}`,
//     {
//       isActive: !status ? true : false,
//     },
//     {
//       headers: {
//         accept: "application/json",
//         authorization: `bearer ${accessTokan}`,
//       },
//     }
//   );
// };

// const verifyEmailRequest = (id) => {
//   let accessTokan = localStorage.getItem("auth");
//   return axios.put(
//     `${apiURL}/person/${id}`,
//     {
//       isEmailVerified: true,
//     },
//     {
//       headers: {
//         accept: "application/json",
//         authorization: `bearer ${accessTokan}`,
//       },
//     }
//   );
// };

export const userDataApi = {
  personSignUpRequest,
  userLogInApiRequest,
  userLogOutApiRequest,
  // changeStatusRequest,
  // verifyEmailRequest,
  userForgetPasswordRequest,
  userForgetPasswordCodeVerificationRequest,
  resetPasswordSaveNewPasswordRequest,
  getAssociationData,
  getPriceDetails,
};
