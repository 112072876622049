import React from 'react'
import { Button, Checkbox, Tooltip } from "antd";
import { FileImageOutlined, FileTextOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import { createOfferContractTypes, isSellingNotExecutedFunc } from '../../Common/commondata/commonData';


const PropertyDocsTable = ({ documents, role, item, handleOPenDocs, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen }) => {
    return (
        <table className="licenseTable" style={{ width: "100%", border: 0 }}>
            <thead style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
                <tr>
                    <th>File Name</th>
                    <th>Creation Date</th>
                    <th>Shared Folder</th>
                    <th>Show in dashboard</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {documents?.map((ele) => (
                    <tr key={ele?._id}>
                        <td
                            onClick={() => {
                                ele.isPdf === true
                                    ? window.open(ele.pdfLink, "_blank")
                                    : ele.fileType === "IMAGE" || ele.fileType === "PDF"
                                        ? handleOPenDocs(ele)
                                        : handlePropertyDocs(ele);
                            }}
                            style={{
                                fontWeight: "bolder",
                                color: "blue",
                                minWidth: "250px",
                                width: "65%",
                                cursor: "pointer",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: isSmallScreen ? "13px" : "16px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {ele.fileType === "IMAGE" ? (
                                        <FileImageOutlined
                                            style={{
                                                color: "#ecd540",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                            }}
                                        />
                                    ) : ele.fileType === "PDF" || ele.isPdf ? (
                                        <FaRegFilePdf
                                            style={{
                                                color: "red",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                            }}
                                        />
                                    ) : ele.offerDocument && ele.contractType === "LCAES" ? (
                                        <FileTextOutlined
                                            style={{
                                                color: "#74b3ed",
                                                marginRight: "5px",
                                                marginLeft: "20px",
                                            }}
                                        />
                                    ) : (
                                        <FileTextOutlined
                                            style={{
                                                color: "#74b3ed",
                                                marginRight: "5px",
                                            }}
                                        />
                                    )}
                                    <span>
                                        {ele.offerDocument ? ele.documentName : ele.fileName || ""}
                                        {ele.offerDocument && !ele?.offerId && !["ERTS", "LCAES"].includes(ele.contractType) && (
                                            <span style={{ color: "grey", fontSize: "12px" }}>
                                                {" (Without Buyers)"}
                                            </span>
                                        )}
                                    </span>
                                </div>
                                {ele?.transactionData && (
                                    <div
                                        style={{
                                            color: "grey",
                                            fontSize: "11px",
                                            marginLeft: "24px",
                                        }}
                                    >
                                        {ele.contractType === "ERTS" &&
                                            ele.transactionData &&
                                            ele.transactionData.ListingPeriodBeginDate && (
                                                <span>
                                                    {moment(ele.transactionData.ListingPeriodBeginDate).format(
                                                        "MM/DD/YYYY"
                                                    )}
                                                </span>
                                            )}
                                        {ele.contractType === "ERTS" &&
                                            ele.transactionData &&
                                            ele.transactionData.ListingPeriodEndByDate && (
                                                <>
                                                    {" "}
                                                    -{" "}
                                                    {moment(
                                                        ele.transactionData.ListingPeriodEndByDate
                                                    ).format("MM/DD/YYYY")}
                                                </>
                                            )}
                                        <span style={{ marginLeft: "19px" }}>
                                            {ele.contractType === "LCAES" &&
                                                ele.transactionData &&
                                                ele.transactionData.ListingPeriodEndByDate && (
                                                    <span>Ending Date </span>
                                                )}
                                            {ele.contractType === "LCAES" &&
                                                ele.transactionData &&
                                                ele.transactionData.ListingPeriodEndByDate && (
                                                    <span>
                                                        {" "}
                                                        -{" "}
                                                        {moment(ele.transactionData.ListingPeriodEndByDate).format(
                                                            "MM/DD/YYYY"
                                                        )}
                                                    </span>
                                                )}
                                        </span>
                                    </div>
                                )}
                                <div
                                    style={{
                                        color: "grey",
                                        fontSize: "11px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    {ele.fileType
                                        ? `Uploaded by: ${ele.personId?.fullName}`
                                        : ""}
                                </div>
                            </div>
                        </td>

                        <td
                            style={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "center",
                                minWidth: "50px",
                                width: "10%",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            <div>
                                {moment(ele.createdDateMili).format("MM/DD/YYYY")}
                            </div>
                            <div>
                                {moment(ele.createdDateMili).format("hh:mm A")}
                            </div>
                        </td>

                        <td
                            style={{
                                fontWeight: "bolder",
                                textAlign: "center",
                                minWidth: "70px",
                                width: "10%",
                                color: "#085191",
                            }}
                        >
                            {!ele?.contractId && (
                                role === "SELLER" && item?.offerId ? (
                                    <Checkbox
                                        checked={ele?.isShared}
                                        onChange={(e) => {
                                            handleShareUnshareFileConfirm(ele, e.target.checked);
                                        }}
                                        style={{
                                            color: ele.isShared ? "white" : "black",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                ) : role === "BUYER" && ele?.showSeller ? (
                                    <Checkbox
                                        checked={ele?.isShared}
                                        onChange={(e) => {
                                            handleShareUnshareFileConfirm(ele, e.target.checked);
                                        }}
                                        style={{
                                            color: ele?.isShared ? "white" : "black",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                ) : (
                                    <Tooltip
                                        title={
                                            role === "SELLER" && !item?.offerId
                                                ? "Cannot share: Accepted offer does not exist."
                                                : role === "BUYER" && !ele?.showSeller
                                                    ? "Please send this offer to seller agent to enable sharing."
                                                    : ""
                                        }
                                        placement="right"
                                        style={{ width: "50px" }}
                                    >
                                        <Checkbox
                                            checked={false}
                                            disabled={true}
                                            style={{
                                                color: "black",
                                                borderRadius: "5px",
                                                cursor: "not-allowed",
                                            }}
                                        />
                                    </Tooltip>
                                )
                            )}
                        </td>

                        <td
                            style={{
                                textAlign: "center",
                                minWidth: "100px",
                                width: "15%",
                            }}
                        >
                            {ele?.offerId &&
                                ele?.offerDocument &&
                                ele?.contractId &&
                                !["ERTS"].includes(ele?.contractType) && (
                                    <Checkbox
                                        checked={ele?.isCreatedInRtd}
                                        onChange={(e) =>
                                            handleToggleDashboard(ele, e.target.checked)
                                        }
                                        disabled={
                                            createOfferContractTypes.includes(ele?.contractType) &&
                                            item?.status === "ACCEPTED"
                                        }
                                        style={{
                                            color: "black",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                )}
                        </td>

                        <td
                            style={{
                                textAlign: "center",
                                minWidth: "70px",
                                width: "10%",
                                color: "#085191",
                            }}
                        >
                            {!(["ERTS"].includes(ele?.contractType) && isSellingNotExecutedFunc({item})) &&
                                !(createOfferContractTypes.includes(ele?.contractType) &&
                                    !["INPROGRESS", "SUBMITTED", "DRAFT"].includes(item?.status)) && (
                                    <Tooltip title="Delete" placement="right" style={{ width: "50px" }}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                                handleDeletePropertyDocs(
                                                    ele,
                                                    ele?.RTDInitiator
                                                        ? "contractDoc"
                                                        : "uploadedDoc"
                                                )
                                            }
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "red",
                                                border: "none",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PropertyDocsTable
