import { message } from "antd";
import { accountHandle } from "../../Account/state/actions";
import { pricingPageApi } from "../utils/api";
import { pricingPageConstants } from "./actionTypes";

const createCheckoutLoading = (status) => {
  return {
    type: pricingPageConstants.CREATE_CHEKOUT_LOADING,
  };
};
const createCheckoutSuccess = (response) => {
  return {
    type: pricingPageConstants.CREATE_CHEKOUT_SUCCESS,
    payload: response,
  };
};

const createCheckoutError = (error) => {
  return {
    type: pricingPageConstants.CREATE_CHEKOUT_FAILURE,
  };
};

const createCheckoutFun = (data, history) => {
  return (dispatch, getState) => {
    dispatch(createCheckoutLoading(true));
    pricingPageApi
      .createCheckOutRequest(data)
      .then((response) => {
        window.location.href = response?.data?.info.sessionUrl;
        dispatch(createCheckoutSuccess(response?.data?.info));

        // history.push(response?.data?.info.sessionUrl);
      })
      .catch((error) => {
        dispatch(createCheckoutError(error));
        message.error(error?.response?.data?.message || "");
      });
  };
};

// const viewTransHistoryLoading = (status) => {
//   return {
//     type: pricingPageConstants.VIEW_CUSTOMER_PORTAL_LOADING,
//   };
// };
const viewTransHistorySuccess = (response) => {
  return {
    type: pricingPageConstants.VIEW_CUSTOMER_PORTAL_SUCCESS,
    payload: response,
  };
};

const viewTransHistoryError = (error) => {
  return {
    type: pricingPageConstants.VIEW_CUSTOMER_PORTAL_FAILURE,
  };
};

const viewTransHistoryFun = () => {
  return (dispatch, getState) => {
    dispatch(createCheckoutLoading(true));
    // dispatch(viewTransHistoryLoading(true));
    pricingPageApi
      .transationViewRequest()
      .then((response) => {
        window.location.href = response?.data?.info.sessionUrl;
        // window.open(response?.data?.info.sessionUrl);
        dispatch(viewTransHistorySuccess(response?.data?.info));
      })
      .catch((error) => {
        dispatch(viewTransHistoryError(error));
        message.error(error?.response?.data?.message || "");
      });
  };
};

const getProductsLoading = (status) => {
  return {
    type: pricingPageConstants.GET_PRODUCTS_LOADING,
  };
};
const getProductsSuccess = (response) => {
  return {
    type: pricingPageConstants.GET_PRODUCTS_SUCCESS,
    payload: response,
  };
};

const getProductsError = (error) => {
  return {
    type: pricingPageConstants.GET_PRODUCTS_FAILURE,
  };
};

const getProductsFun = () => {
  return (dispatch, getState) => {
    dispatch(getProductsLoading(true));
    pricingPageApi
      .getProductsRequest()
      .then((response) => {
        dispatch(getProductsSuccess(response?.data?.info));
      })
      .catch((error) => {
        dispatch(getProductsError(error));
      });
  };
};

const redeemSubscriptionLoading = (status) => {
  return {
    type: pricingPageConstants.REDEEM_SUBSCRIPTION_LOADING,
  };
};
const redeemSubscriptionSuccess = (response) => {
  return {
    type: pricingPageConstants.REDEEM_SUBSCRIPTION_SUCCESS,
    payload: response,
  };
};

const redeemSubscriptionError = (error) => {
  return {
    type: pricingPageConstants.REDEEM_SUBSCRIPTION_FAILURE,
  };
};

const redeemSubscriptionFun = (data, handleRedeemModal) => {
  return (dispatch, getState) => {
    dispatch(redeemSubscriptionLoading(true));
    pricingPageApi
      .redeemSubscriptionRequest(data)
      .then((response) => {
        message.success(response?.data?.info?.message);
        dispatch(redeemSubscriptionSuccess(response?.data?.info));
        handleRedeemModal();
        dispatch(getProductsFun());
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "");
        dispatch(redeemSubscriptionError(error));
      });
  };
};

export const pricingPageAction = {
  createCheckoutFun,
  viewTransHistoryFun,
  getProductsFun,
  redeemSubscriptionFun
};
