import { Form, Input, Modal } from 'antd'
import React from 'react'
import styles from "../styles/smallScreenNav.module.css";
import { SearchOutlined } from "@ant-design/icons";
import FilterMenu from './FilterMenu';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { PropertyHandlers } from '../state/actions';

const SmallScreenFilters = ({ isModalVisible, setToggled, toggled, dispatch, filterSort }) => {
  const [form] = Form.useForm();
  const isMax = useMediaQuery({maxWidth: 355});


    // debounce logic to call search api
  const debouncedFunction = _.debounce((value) => {
    dispatch(PropertyHandlers.changeFilterSort({
      ...filterSort,
      search: value
    }));
  }, 1000);

  const handleChange = (event) => {
    const value = event.target.value;
    debouncedFunction(value);
  };

    return (
        <Modal
            visible={isModalVisible}
            onCancel={() => setToggled({ ...toggled, filter: false })}
            footer={false}
            title={<span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", color: "grey" }}>FILTER / SORT</span>}
            style={isMax ? {marginTop:"-50px",padding:"0px",}:{maxWidth:"350px"}}
        >
            <div className={styles.filterContainer}>
                {/* search box  */}
                <div className={styles.searchInputBox}>
                  <Form form={form} initialValues={{search:filterSort?.search}}>
                    <Form.Item name="search" style={{ marginBottom: "0px" }}>
                      <Input
                        allowClear
                        placeholder="search here..."
                        suffix={<SearchOutlined />}
                        style={{ maxWidth: "400px" }}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Form>
                </div>
                {/* filter box  */}
                <div className={styles.filterBox}>
                    <FilterMenu toggled={toggled} form={form} filterSort={filterSort} />
                </div>
            </div>
        </Modal>
    );
}

export default SmallScreenFilters;
