import { Modal, Typography } from "antd";
import React from "react";

const SaveCancelModal = ({ modelDrawer, setModelDrawer }) => {
  const Text = Typography;
  return (
    <Modal
      visible={modelDrawer.status}
      title={
        <Text style={{ color: "#1890ff", textDecorationLine: "underline" }}>
          {modelDrawer.title}
        </Text>
      }
      okText="Save"
      cancelText="Cancel"
      footer={false}
      onCancel={() =>
        setModelDrawer({ status: false, component: "", title: "" })
      }
    >
      {modelDrawer.component}
    </Modal>
  );
};

export { SaveCancelModal };
