import React from 'react';

const Tutorilas = () => {
  return (
    <div>
      <div
        style={{
          color: "grey",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
          margin: "auto",
          width: "auto",
          position: "sticky",
          top: "0",
          zIndex: "100",
          background: "#fff",
          padding:"10px",
          borderBottom:"1px solid grey",
        }}
      >
        TUTORIALS
      </div>
      <div style={{ margin: "auto",border:"2px solid  grey",borderRadius:"5px",fontSize:"18px",fontWeight:"bold",color:"grey",padding:"4px",textAlign:"center",marginTop:"10px"}}>
        <p style={{}}>How to add a client?</p>
        <iframe
          title="How to add a client?"
          allow="fullscreen"
          src='https://www.loom.com/embed/fdb5f4223bcc462fb79babb3e6b82be1?sid=a83405ad-19c3-4011-97df-e46896b12985'
          width='100%'
          height="500px"
        />
      </div>
      <div style={{ margin: "auto",border:"2px solid  grey",borderRadius:"5px",fontSize:"18px",fontWeight:"bold",color:"grey",padding:"4px",textAlign:"center",marginTop:"10px"}}>
        <p style={{}}>How do I edit a client's information?</p>
        <iframe
          title="How do I edit a client's information?"
          allow="fullscreen"
          src='https://www.loom.com/embed/361bb5d9bfee45d9b26f10a928fab21f?sid=457bb8f1-b0eb-4dd8-b582-4670398746b8'
          width='100%'
          height="500px"
        />
      </div>
      <div style={{ margin: "auto",border:"2px solid  grey",borderRadius:"5px",fontSize:"18px",fontWeight:"bold",color:"grey",padding:"4px",textAlign:"center",marginTop:"10px"}}>
        <p style={{}}>How do I submit an offer?</p>
        <iframe
          title="How do I submit an offer?"
          allow="fullscreen"
          src='https://www.loom.com/embed/20ad7bf37bb04ac8bc4bfc09e69884cf?sid=6301d5f0-6eba-48c4-a7b6-e230adf2162d'
          width='100%'
          height="500px"
        />
      </div>
    </div>
  );
};

export { Tutorilas };
