import { transactionLaneConstants } from "./action-types";

const initialState = {
  getTransactionLaneData: [],
  getTransactionLaneLoading: false,
  getTransactionLaneError: false,
  getTransactionLaneErrorMsg: null,
  updateDocumentLoading: false,
  updateDocumentSucess: null,
  updateDocumentFailure: null,
  data: [],

  getIndividualTransactionError: false,
  triggerIndividualTransactionFetch:[],
  getIndividualTransactionData: {},
  getIndividualTransactionErrorMsg: null,

  setLoading: false,
  setError: "",
  clientPortalData: [],

  propertUploadDocs:null,
  propertDocsLoading: false,
  propertDocsError: null,

  getPropertyDocsData: [],
  getPropertyDocsLoading: false,
  getPropertyDocsError: false,
  getPropertyDocsErrorMsg: null,

  getTitleCompanySelectedData: null,
  getTitleCompanyDataLoading: true,
  getTitleCompanyDataError: false,
  getTitleCompanyDataErrorMsg: null,

  doraDocsUrlShorthandLoading: false,
  doraDocsUrlShorthandFailure: false,

  addEmailLoading: false,
  addEmailError: false,
  addEmailSuccess: {},

  getDoraDocumentsData: null,
  getDoraDocumentsLoading: true,
  getDoraDocumentsError: false,
  getDoraDocumentsErrorMsg: null,

  getTitleCompanyData: null,
  getTitleCompanyLoading: true,
  getTitleCompanyError:false,

  refreshTitleCompany: null,
  updateSuccessRtd: []
};

const rtdTransactionLane = (state = initialState, action) => {
  switch (action.type) {
    case transactionLaneConstants.GET_TRANSACTION_LANE_LOADING:
      return {
        ...state,
        getTransactionLaneLoading: true,
        getTransactionLaneError: false,
      };

    case transactionLaneConstants.GET_TRANSACTION_LANE_SUCCESS:
      return {
        ...state,
        getTransactionLaneData: action.getTransactionLaneData,
        getTransactionLaneLoading: false,
        getTransactionLaneError: false
      };
    case transactionLaneConstants.GET_TRANSACTION_LANE_FAILURE:
      return {
        ...state,
        getTransactionLaneData: [],
        getTransactionLaneLoading: false,
        getTransactionLaneError: true,
        getTransactionLaneErrorMsg: action.errorMsg,
      };
    case transactionLaneConstants.ADD_EMAIL_LOADING:
      return {
        ...state,
        addEmailLoading: true,
        addEmailError: false
      };

    case transactionLaneConstants.ADD_EMAIL_SUCCESS:
      return {
        ...state,
        addEmailLoading: false,
        addEmailError: false,
        addEmailSuccess: { message: "successful!" }
      };
    case transactionLaneConstants.ADD_EMAIL_ERROR:
      return {
        ...state,
        addEmailLoading: false,
        addEmailError: true
      };
    case transactionLaneConstants.GET_DORA_DOCUMENTS_LOADING:
      return {
        ...state,
        getDoraDocumentsData: null,
        getDoraDocumentsLoading: true,
        getDoraDocumentsError: false,
      };
    case transactionLaneConstants.GET_DORA_DOCUMENTS_SUCCESS:
      let filterdDoraDocs = action.getDoraDocumentsData?.filter(el => el.contractType !== "CO" && el.contractType !== "MH_CO") || [];
      return {
        ...state,
        getDoraDocumentsData: filterdDoraDocs,
        getDoraDocumentsLoading: false,
        getDoraDocumentsError: false,
      };
    case transactionLaneConstants.GET_DORA_DOCUMENTS_FAILURE:
      return {
        ...state,
        getDoraDocumentsData: null,
        getDoraDocumentsLoading: false,
        getDoraDocumentsError: true,
        getDoraDocumentsErrorMsg: action.errorMsg,
      };
    case transactionLaneConstants.GET_TITLE_COMPANY_DATA_LOADING:
      return {
        ...state,
        getTitleCompanySelectedData: null,
        getTitleCompanyDataLoading: true,
        getTitleCompanyDataError: false,
      };
    case transactionLaneConstants.GET_TITLE_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        getTitleCompanySelectedData: action.titleCompanyselecteddata,
        getTitleCompanyDataLoading: false,
        getTitleCompanyDataError: false,
      };
    case transactionLaneConstants.GET_TITLE_COMPANY_DATA_ERROR:
      return {
        ...state,
        getTitleCompanySelectedData: [],
        getTitleCompanyDataLoading: false,
        getTitleCompanyDataError: true,
        getTitleCompanyDataErrorMsg: action.errorMsg,
      };

    case transactionLaneConstants.GET_PROPERTY_DOCS_LOADING:
      return {
        ...state,
        getPropertyDocsLoading: true,
        getPropertyDocsError: false
      };
    case transactionLaneConstants.GET_PROPERTY_DOCS_SUCCESS:
      return {
        ...state,
        getPropertyDocsData: action.getPropertyDocsData,
        getPropertyDocsLoading: false,
        getPropertyDocsError: false,
      };
    case transactionLaneConstants.GET_PROPERTY_DOCS_FAILURE:
      return {
        ...state,
        getPropertyDocsData: [],
        getPropertyDocsLoading: false,
        getPropertyDocsError: true,
        getPropertyDocsErrorMsg: action.errorMsg,
      };

    case transactionLaneConstants.ADD_TITLE_OFFICE_LOADING:
      return {
        ...state,
        getTitleCompanyDataLoading: true,
        getTitleCompanyDataError: false
      };
    case transactionLaneConstants.ADD_TITLE_OFFICE_SUCCESS:
      return {
        ...state,
        getTitleCompanyDataLoading: false,
        getTitleCompanyDataError: false
      };
    case transactionLaneConstants.ADD_TITLE_OFFICE_ERROR:
      return {
        ...state,
        getTitleCompanyDataLoading: false,
        getTitleCompanyDataError: true,
        getTitleCompanyDataErrorMsg: action.errorMsg,
      };

    case transactionLaneConstants.GET_TITLE_COMPANY_LOADING:
      return {
        ...state,
        getTitleCompanyData: null,
        getTitleCompanyLoading: true,
        getTitleCompanyError: null,
      };
    case transactionLaneConstants.GET_TITLE_COMPANY_SUCCESS:
      return {
        ...state,
        getTitleCompanyData: action.titleCompanydata,
        getTitleCompanyLoading: false,
        getTitleCompanyError: null,
      };
    case transactionLaneConstants.GET_TITLE_COMPANY_ERROR:
      return {
        ...state,
        getTitleCompanyData: [],
        getTitleCompanyLoading: false,
        getTitleCompanyError: action.errorMsg,
      };

    case transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_SUCCESS:
      return {
        ...state,
        getIndividualTransactionError: false,
        getIndividualTransactionData: action.getIndividualTransactionData,
      };
    case transactionLaneConstants.TRIGGER_INDIVIDUAL_TRANSACTION_FETCH:
      return {
        ...state,
        triggerIndividualTransactionFetch: []
      };
    case transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_FAILURE:
      return {
        ...state,
        getIndividualTransactionError: true,
        getIndividualTransactionErrorMsg: action.errorMsg,
        getIndividualTransactionData: []
      };

    case transactionLaneConstants.UPDATE_DOCUMENT_LOADING:
      return {
        ...state,
        updateDocumentLoading: true,
        updateDocumentFailure: false,
      };

    case transactionLaneConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updateDocumentFailure: false,
        updateDocumentSucess: true,
        updateDocumentLoading: false,
      };

    case transactionLaneConstants.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        updateDocumentFailure: action.error,
        updateDocumentSucess: false,
        updateDocumentLoading: false,
      };
      case  transactionLaneConstants.CLIENT_PORTAL_LOADING:
      return {
        ...state,
        setLoading:true,
        setError: "",
        clientPortalData: []
      };
    case transactionLaneConstants.CLIENT_PORTAL_ERROR:
      return {
        ...state,
        setLoading: action.setLoading,
        setError: action.setError,
      };
    case  transactionLaneConstants.CLIENT_PORTAL_SUCCESS:

      return {
        ...state,
        setLoading: false,
        setError: false,
        clientPortalData:action.payload,
      };

      case  transactionLaneConstants.EMAIL_FILES_LOADING:
        return {
          ...state,
          emailFilesLoading:true,
          emailFilesError: "",
          emailFilesData:[]
        };
      case transactionLaneConstants.EMAIL_FILES_ERROR:
        return {
          ...state,
          emailFilesLoading: action.emailFilesLoading,
          emailFilesError: action.emailFilesError,
        };
      case  transactionLaneConstants.EMAIL_FILES_SUCCESS:

        return {
          ...state,
          emailFilesLoading: false,
          emailFilesError: false,
          emailFilesData:action.payload,
        };

        case transactionLaneConstants.UPLOAD_DOCUMENT_LOADING:
          return {
            ...state,
            propertDocsLoading: true,
            propertDocsError: null,
          };
        case transactionLaneConstants.UPLOAD_DOCUMENT_SUCCESS:
          return {
            ...state,
            propertUploadDocs: action.propertUploadDocs,
            propertDocsLoading: false,
            propertDocsError: null,
          };
        case transactionLaneConstants.UPLOAD_DOCUMENT_FAILURE:
          return {
            ...state,
            propertUploadDocs: [],
            propertDocsLoading: false,
            propertDocsError: null,
          };
    case transactionLaneConstants.RESET_RTD_DATA:
      return {
        ...initialState
      };
    case transactionLaneConstants.REFRESH_TITLE_COMPANY:
      return {
        ...state,
        refreshTitleCompany: { message: "refreshing...." }
      };
    case transactionLaneConstants.UPDATE_SUCCESS_RTD:
      return {
        ...state,
        updateSuccessRtd: []
      };
    case transactionLaneConstants.RESET_TRANSACTION_LANE_DATA:
        return{
            ...state,
            getTransactionLaneData: [],
            getPropertyDocsData: [],
        }
    default:
      return state;
  }
};

export { rtdTransactionLane };
