import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { documentAction } from "../state/actions";
import { PropertyTable } from "./PropertyTable";
import "../style/DocumentForm.css";
import { PropertySearchModal } from "./PropertySearchModal";
import { useMediaQuery } from "react-responsive";
import { Form } from "antd";

function PropertySearch({
  handleCancelPropertySearch,
  fieldEmpty,
  setFieldIsEmpty,
  setisownerDetailModal,
  redirectFromClient
}) {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 5;
  const [addressData, setAddressData] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { backKey } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );

  let handleBackButton = () => {
    dispatch(documentAction.onBackbuttonClick());
  };

  return (
    <>
      {backKey ? (
        <>
          <PropertyTable
            handleBackButton={handleBackButton}
            handleCancelPropertySearch={handleCancelPropertySearch}
            skip={skip}
            setSkip={setSkip}
            limit={limit}
            addressData={addressData}
            setFieldIsEmpty={setFieldIsEmpty}
            fieldEmpty={fieldEmpty}
            setisownerDetailModal={setisownerDetailModal}
            redirectFromClient={redirectFromClient}
          />
        </>
      ) : (
        <div style={{height:isMobile ? "100%" : "550px"}}>
        <PropertySearchModal
          fieldEmpty={fieldEmpty}
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setAddressData={setAddressData}
        />
        </div>
      )}
    </>
  );
}
export { PropertySearch };
