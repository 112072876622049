/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Layout, Col, Row } from "antd";
import { clientAction } from "./state/actions";
import { ClientList } from "./components/ClientList";
import { DocumentryRoot } from "./components/DocumentryRoot";
import { ClientDetails } from "./components/ClientDetails";
import styles from "./styles/Client.module.css";
import "./styles/client.css";
import { EmptyData } from "../Common/components/EmptyData";
const { Content } = Layout;

function Client(props) {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const query = new URLSearchParams(window.location?.search);
  const queryClientId = query?.get("reqId");
  const queryKey = query?.get("key");
  const [documentType, setDocumentType] = useState(false);
  const [selectedClintData, setSelectedClientData] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editChangeButtonClick, seteditChangeButtonClick] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedData, setSelectedData] = useState("");
  const [getClientDetail, setGetClientDetail] = useState();
  const [newRedirectClientToOffer, setNewRedirectClientToOffer] = useState();
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [value, setValue] = useState("");
  const [personId, setPersonId] = useState(queryClientId ? queryClientId : state?.clientId ? state.clientId : "");
  const [page, setPage]=useState([1]);

  // const { getClientData } = useSelector((state) => state.client.clientReducer);
  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);

  useEffect(()=>{
    // dispatch(clientAction.clearGetClientData());
    window.history.replaceState({}, document.title);
    // setSelectedClient("");
    if (queryClientId) {
      setPersonId(queryClientId)
      setPage([1])
    }
  }, [queryClientId])
  


  let editHandle = () => {
    seteditChangeButtonClick(!editChangeButtonClick);
  };

  let selectClientHandle = (item) => {
    setSelectedClient(item._id);
    setNewRedirectClientToOffer(item);
    setSelectedData(item);
    setDocumentType(false);
  };

  return (
    <>
      <Layout >
        <Content style={{
                paddingLeft: "8px",
                paddingRight: "8px",
               width: "100%",
              }}>
          <Row gutter={[2, 2]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                paddingLeft: "0px",
                paddingRight: "8px",
                // border: "2px solid blue",
                width: "100%",
               
              }}
            >
              <div className={styles.clientSectionContainer}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <div className="mozScrollbar clientListContainer1">
                    <ClientList
                      setNewRedirectClientToOffer={setNewRedirectClientToOffer}
                      setSelectedClient={setSelectedClient}
                      selectClientHandle={selectClientHandle}
                      selectedClient={selectedClient}
                      setGetClientDetail={setGetClientDetail}
                      position={position}
                      setPosition ={setPosition}
                      personId={personId}
                      setPersonId={setPersonId}
                      page={page}
                      setPage={setPage}
                      showSearchInput={showSearchInput}
                      setShowSearchInput={setShowSearchInput}
                      value={value} setValue={setValue}
                      queryClientId={queryClientId}
                    />
                  </div>
                </Col>
                
                {editChangeButtonClick ? (
                    {
                      /* <ClientProfile
                      setDocumentType={setDocumentType}
                      id={"61b880461e0daa93c3c14a0b"}
                    /> */
                    }  ) : (
                          <>
                          <Col xs={24} sm={24} md={18} lg={18} xl={12}>
                        <div className="clientListContainer mozScrollbar">
                          <DocumentryRoot
                            setSelectedClientData={setSelectedClientData}
                            selectedClintData={selectedClintData}
                            setDocumentType={setDocumentType}
                            documentType={documentType}
                            selectedClient={selectedClient}
                            setSelectedClient={setSelectedClient}
                            getClientDetail={getClientDetail}
                            newRedirectClientToOffer={newRedirectClientToOffer}
                            setPosition={setPosition}
                            queryKey={queryKey}
                            setPage={setPage}
                            page={page}
                          />
                        </div>
                      </Col>
                      <Col span={12} xs={24} sm={24} md={12} lg={8} xl={6}>
                        <div className="clientListContainer mozScrollbar">
                          {selectedClient && selectedClient.length > 0 ? (
                            <ClientDetails
                              selectedClient={selectedClient}
                              selectedData={selectedData}
                              // getClientData={getClientData}
                              editHandle={editHandle}
                              setDrawerVisible={setDrawerVisible}
                              setPage={setPage}
                              setPersonId={setPersonId}
                              setSelectedClient={setSelectedClient}
                              setShowSearchInput={setShowSearchInput}
                              setValue={setValue}
                            />
                          ) : (
                            <EmptyData />
                          )}
                        </div>
                      </Col>
                      </>
                    )}
            
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export { Client };
