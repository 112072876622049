import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSignUpAction } from "../state/actions";
import styles from "../styles/User.module.css";
import { useState } from "react";
import { FirstPage } from "./FirstPage";
import { useMediaQuery } from "react-responsive";
import {  Redirect } from "react-router-dom";
import {  Modal } from "antd";
import { SignupPrices } from "./SignupPrices";


function UserSignUp() {
  const dispatch = useDispatch();

  const { userSignupData } = useSelector((state) => state.userSignup);
  const [firstPageData, setFirstPageData] = useState();
  const [isPriceModalVisible,setIsPriceModalVisible] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const spot1 = useMediaQuery({ minWidth:991, maxWidth: 1100 });
  const isTab = useMediaQuery({maxWidth: 750})

  let onFinish = (event) => {
    let viaValue = localStorage.getItem("via");
    const signUpData = {
      firstName: event.firstName,
      lastName: event.lastName,
      email: event.email.toLowerCase(),
      phoneNumber:event.phoneNumber,
      password: event.password,
      ...(event.association ? {association : event.association} : {}),
      ...(viaValue?.length ? {viaSignupKeyUsed:viaValue} : {}),
      // birthDate: event.birthDate,
    };
    dispatch(userSignUpAction.personSignUp(signUpData));
  };

  // const handleSignIn = () => {
  //   histroy.replace("/login");
  // };

  return userSignupData ? (
    // <SignupVerification />
    <Redirect
      to={{
        pathname: "/login",
        state: { email: userSignupData && userSignupData.email },
      }}
    />
  ) : (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "30",
          background: "white",
          width: "100%",
          padding: isMobile || spot1 ? "2rem 2rem 0rem 2rem" : "2rem 5rem 0rem 5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            minHeight: "auto",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1 className={styles.custFormH1}>Sign Up</h1>
          </div>
          {/* <div>
            <h1 className={styles.custFormOr}>Or</h1>
          </div>
          <div>
            <Link to="/login">
              {" "}
              <h1 className={styles.custFormSingIn}>Sign In</h1>
            </Link>
          </div> */}
        </div>
            <h2 className={styles.custFormH2}>
              Sign Up to try our amazing services.&nbsp;
            <span>
              <span onClick={() => setIsPriceModalVisible(true)} style={{color: '#8cc2ff', cursor: 'pointer'}}>Click here</span>
              &nbsp;to check our pricing
            </span>
            </h2>
      </div>
      <div
        style={{
          padding: isMobile || spot1 ? "0rem 2rem" : "0rem 5rem",
        }}
      >
        <FirstPage
          firstPageData={firstPageData}
          onFinish={onFinish}
          // onNexPageClick={onNexPageClick}
        />
        {/* {nextpage ? (
          <FirstPage
            firstPageData={firstPageData}
            onNexPageClick={onNexPageClick}
          />
        ) : (
          <SecondPage onFinish={onFinish} onPrePageClick={onPrePageClick} />
        )} */}
      </div>

      {/* Modal to check prices */}
      <Modal open={isPriceModalVisible} footer={false} title={<p style={{color: "#1990ff",textAlign:"center",fontSize:"24px",fontWeight:"bold"}}>PRICES</p>} onCancel={() => setIsPriceModalVisible(false)} width={isTab ? "95%" : "700px"} style={{marginTop:"-50px"}}>
        <SignupPrices/>
      </Modal>
    </div>
  );
}
export { UserSignUp };
