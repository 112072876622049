import { toolsConstants } from "../action-types";

const initialState = {
  templateJSX: "",
  templateJSXLoading: false,
  templateJSXError: null,
};

const TemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolsConstants.GET_TEMPLATE_JSX_SUCCESS:
      return {
        ...state,
        templateJSX: action.templateJSXData,
        templateJSXLoading: action.templateJSXLoading,
      };
    case toolsConstants.GET_TEMPLATE_JSX_ERROR:
      return {
        ...state,
        templateJSXError: action.templateJSXError,
        templateJSXLoading: action.templateJSXLoading,
      };
    default:
      return state;
  }
};

export { TemplateReducer };
