module.exports = function () {
  return `<div class='flex-box justify-content'>
    <div class='flex-box'>
        <div>
            <button id='bold'>B</button>
        </div>
        <div>
            <button id='italic'>I</button>
        </div>
        <div>
            <button id='underlined'>U</button>
        </div>
        <div class='tooltip' data-text='Font size'>
            <input class='top-bar-input' placeholder='16' type='number' id='size-font'/>
        </div>
        <div class='tooltip' data-text='text'>
            <input type='color' id='color_1'/>
        </div>

        <div class='empty'></div>
        <div class='empty'></div>

        <div class='tooltip' data-text='fill'>
            <input type='color' id='bgcolor_1' value='#FFFFFF'/>
        </div>

        <div class='empty'></div>

        <div class='tooltip' data-text='top'>
            <input type='number' placeholder='t' class='top-bar-input' id='top_1' />
        </div>
        <div class='tooltip' data-text='left'>
            <input type='number' placeholder='l' class='top-bar-input' id='left_1'/>
        </div>
        <div class='tooltip' data-text='right'>
            <input type='number' placeholder='r' class='top-bar-input' id='right_1'/>
        </div>
        <div class='tooltip' data-text='bottom'>
            <input type='number' placeholder='b' class='top-bar-input' id='bottom_1'/>
        </div>

        <div class='empty'></div>

        <div class='tooltip' data-text='width'>
            <input class='top-bar-input' placeholder='width' type='number' id='width_1'/>
        </div>
        <div class='tooltip' data-text='height'>
            <input class='top-bar-input' placeholder='height' type='number' id='height_1'/>
        </div>

        <div class='empty'></div>
        <div class='empty'></div>

        <div>
            <button id='word-break_1'>break</button>
        </div>

        <div class='empty'></div>
        <div class='empty'></div>
        <div class='empty'></div>
        <div class='empty'></div>
    </div>

    <div class='flex-box'>
        <div>
            <button id='remove_1'><img src='https://cdn-icons-png.flaticon.com/512/1345/1345874.png' width='25px' height='25px'/></button>
        </div>

        <div><button id='propChange_1'><img src='https://cdn-icons-png.flaticon.com/512/18/18442.png' width='25px' height='25px'/></button></div>
    </div>
</div>
<div class='flex-box-1'>
    <div class='flex-box'>

    </div>
    <div  data-text='text'>
        <input class='text-input' id='text_1'/>
    </div>

    <div class='empty'></div>
</div>
`;
};
