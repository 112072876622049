import React, { useState } from "react";
import { TreeSelect } from "antd";

const { TreeNode } = TreeSelect;

function TreeSelector({
  data,
  setPropertyType,
  onChangeValue,
  setPropertyTypeError,
}) {
  const [value, setValue] = useState("66ab7a6a7bfcc2a4deedb08d" || ""); // new CBSR 2024

  const onChange = (value) => {
    setValue(value);
    onChangeValue(value);
  };
  const onChanges = (value) => {
    onChangeValue(value);
  };

  const handleSelect = (value, node, extra) => {
    setPropertyType(value);
  };
  return (
    <TreeSelect
      // placeholder="Select Property type"
      showSearch
      style={{ width: "100%" }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      allowClear
      onClear={() => {
        setPropertyTypeError(true);
      }}
      treeDefaultExpandAll
      onChange={onChange}
      //   treeCheckStrictly={true}
      onSelect={handleSelect}
    >
      {data &&
        data.map((ele, index) => {
          return (
            <TreeNode
              key={ele.name}
              value={ele.name}
              title={ele.name}
              selectable={false}
              onChange={onChanges}
              disabled={
                ele?.isDisabled
              }
              style={{
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              {ele.subCategoryArray &&
                ele.subCategoryArray.map((childElement, childIndex) => {
                  return (
                    <TreeNode
                      disabled={
                        childElement?.isDisabled
                      }
                      key={childElement._id}
                      value={childElement.contractId}
                      title={childElement.name}
                      onChange={() => onChanges(childElement)}
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    />
                  );
                  //     {/* {childElement.docArray &&
                  //       childElement.docArray.map((docEle, docIndex) => (
                  //         <TreeNode value={docEle.name} title={docEle.name} />
                  //       ))} */}
                  //   {/* </TreeNode> */}
                })}
            </TreeNode>
          );
        })}
    </TreeSelect>
  );
}
export { TreeSelector };
