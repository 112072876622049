export const buildRedirectPath = ({ dest, role, urlId, offerId, adminURL }) => {
  switch (dest) {
    case "RTD":
      return role === "sellerAgent" ? `/transactions/sDashboard/${urlId}` : `/transactions/bDashboard/${urlId}`;
    case "offerTable":
    case "OMD":
      return role === "sellerAgent" ? `${adminURL}/offer/${urlId}${offerId ? `?offer=${offerId}` : ""}` : "";
    case "PRE_RTD":
      return `${adminURL}/offer/${urlId}/rtd/${offerId}`;
    case "BUG_REPORT":
      return `/bugsReport`;
    case "transaction":
      return `/transactions`;
    case "client":
      return `/contacts`;
    default:
      return "";
  }
};

export const handleValidSession = ({ dest, redirectpathname, history, searchQuery }) => {
  if (["offerTable", "PRE_RTD", "OMD"].includes(dest)) {
    window.location.href = redirectpathname || "/";
  } else {
    history.push({ pathname: redirectpathname || "/", search: dest === "RTD" ? "" : searchQuery });
  }
};
