import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  memories: null,
  memoriesLoading: false,
  memoriesError: null,

  deleteMemoriesData: [],
  deleteMemoriesLoading: false,
  deleteMemoriesError: null,

  renderImage: []
};

const memoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // memories
    case clientConstants.RENDER_IMAGE: {
      return {
        ...state,
        renderImage: []
      };
    }
    case clientConstants.MEMORY_DETAILS_LOADING:
      return {
        ...state,
        memories: null,
        memoriesLoading: true,
        memoriesError: false,
      };

    case clientConstants.MEMORY_DETAILS_SUCCESS: {
      return {
        ...state,
        memories: action.memories,
        memoriesLoading: false,
        memoriesError: false,
      };
    }
    case clientConstants.MEMORY_DETAILS_FAILURE:
      return {
        ...state,
        memoriesError: action.memoriesError,
        memoriesLoading: false,
      };
    // update the memory i.e: Memory Notes
    case clientConstants.UPDATE_MEMORY_LOADING: {
      return {
        ...state,
        memories: null,
        memoriesError: false,
      };
    }
    case clientConstants.UPDATE_MEMORY_SUCCESS: {
      return {
        ...state,
        memories: action.memories,
        globalClientLoading: false,
        memoriesError: false,
      };
    }
    case clientConstants.UPDATE_MEMORY_FAILURE:
      return {
        ...state,
        memoriesError: action.memoriesError,
        memoriesLoading: false,
      };
    // delete memories
    case clientConstants.DELETE_MEMORIES_LOADING: {
      return {
        ...state,
        deleteMemoriesData: [],
        globalClientLoading: true,
        deleteMemoriesError: false,
      };
    }
    case clientConstants.DELETE_MEMORIES_SUCCESS: {
      return {
        ...state,
        deleteMemoriesData: action.deleteMemoriesData,
        globalClientLoading: false,
        deleteMemoriesError: false,
      };
    }
    case clientConstants.DELETE_MEMORIES_FAILURE:
      return {
        ...state,
        deleteMemoriesError: action.deleteMemoriesError,
        deleteMemoriesLoading: false,
      };
    default:
      return state;
  }
};
export { memoriesReducer };
