import { Button, Modal, Steps } from 'antd'
import React from 'react'
import { useState } from 'react';
import { CompanyTitle } from './CompanyTitle';
import { TitleOffice } from './TitleOffice';
import { transactionLaneDataHandle } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import {TitleRepsCloser} from './TitleRepsCloser';
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Step } = Steps;
const TitleCompanyDetails = ({setShowcompanymodal, selectedStatus, section}) => {
    const delegate = (section === "DELEGATE");
    const dispatch=useDispatch()
    const { getIndividualTransactionData, getTitleCompanySelectedData } = useSelector((state) => state.rtdTransactionLane);
    const [companyModalStep, setCompanyModalStep] = useState({
        step: 0,
        inBuilt: true,
        selectedBy: (selectedStatus==="SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
        titleCompany: "",
        titleOffice: "",
        titleCloser: "",
        salesRep: ""
    });
    const delegateSide = delegate && selectedStatus;

    const handleTitleCompanyCancel = () => {
        setCompanyModalStep({
        step: 0,
        inbuilt: true,
        selectedBy: "",
        titleCompany: "",
        titleOffice: "",
        titleCloser: "",
        salesRep: ""
        });
        setShowcompanymodal(false);
    }

    const prev = () => {
        const currentStep = companyModalStep.step - 1;
        setCompanyModalStep({
        ...companyModalStep,
        step: currentStep
        })
    };

    const next = () => {
        const currentStep = companyModalStep.step + 1;
        setCompanyModalStep({
        ...companyModalStep,
        step: currentStep
        });
    };

    const handlesave=()=>{
        const selectedOfferId = getIndividualTransactionData?.offerId || null;
        
        let payload = {
            offerId: selectedOfferId,
            inBuilt: companyModalStep?.inBuilt,
            selectedBy: companyModalStep?.selectedBy,
            ...(companyModalStep?.titleOffice?._id ? {titleOfficeId: companyModalStep?.titleOffice?._id} : {}),
            ...(companyModalStep?.titleCloser?._id ? {titleCloserId: companyModalStep?.titleCloser?._id} : {}),
            ...(companyModalStep?.salesRep?._id ? {salesRepId: companyModalStep?.salesRep?._id} : {})
        }

        if(!companyModalStep?.inBuilt){
        payload = {
            ...payload,
            ...(companyModalStep?.titleCompany?.officeLogo ? {titleCompanyLogo: companyModalStep.titleCompany.officeLogo} : {}),
            ...(companyModalStep?.titleCompany?.name ? {titleCompanyName: companyModalStep.titleCompany.name} : {}),
            ...(companyModalStep?.titleOffice?.name ? {titleOfficeName: companyModalStep.titleOffice.name} : {}),
            ...(companyModalStep?.titleOffice?.email ? {titleOfficeEmail: companyModalStep.titleOffice.email} : {}),
            ...(companyModalStep?.titleOffice?.address ? {titleOfficeAddress: companyModalStep.titleOffice.address} : {}),
            ...(companyModalStep?.titleOffice?.phoneNumber ? {titleOfficePhone: companyModalStep.titleOffice.phoneNumber} : {}),
            ...(companyModalStep?.titleOffice?.faxNumber ? {titleOfficeFaxNo: companyModalStep.titleOffice.faxNumber} : {}),
            ...(companyModalStep?.titleCloser?.name ? {titleCloserName: companyModalStep.titleCloser.name} : {}),
            ...(companyModalStep?.titleCloser?.email ? {titleCloserEmail: companyModalStep.titleCloser.email} : {}),
            ...(companyModalStep?.titleCloser?.phoneNumber ? {titleCloserPhone: companyModalStep.titleCloser.phoneNumber} : {}),
            ...(companyModalStep?.salesRep?.name ? {salesRepName: companyModalStep.salesRep.name} : {}),
            ...(companyModalStep?.salesRep?.email ? {salesRepEmail: companyModalStep.salesRep.email} : {}),
            ...(companyModalStep?.salesRep?.phoneNumber ? {salesRepPhone: companyModalStep.salesRep.phoneNumber} : {}),
        }
        }

        dispatch(transactionLaneDataHandle.addTitleOffice({payload, delegateSide, delegateOwnerId: delegateSide && getIndividualTransactionData?.currentAgentId?._id}))

        handleTitleCompanyCancel();
    }

    const steps = [
        {
        title:  <div style={{fontSize:"16px"}}>Title Company</div>,//onClick={()=>handelDirectClick(0)}
        content: (
            <CompanyTitle
              setCompanyModalStep={setCompanyModalStep}
              companyModalStep={companyModalStep}
            />
        ),

        },
        {
        title:  <div><text style={{fontSize:"16px"}}>Title Office</text></div>,//{...(companyModalStep.titleCompany ? {onClick: ()=>handelDirectClick(1)} : {})}
        content: (
            <TitleOffice
              companyModalStep={companyModalStep}
              setCompanyModalStep={setCompanyModalStep}
            />
        ),
        },
        {
        title:  <div><text style={{fontSize:"16px"}}>Title Rep/Closer</text></div>,//{...(companyModalStep.titleOffice ? {onClick: ()=>handelDirectClick(2)} : {})}
        content: (
            <TitleRepsCloser
                companyModalStep={companyModalStep}
                setCompanyModalStep={setCompanyModalStep}
            />
        )
        },
    ];

    return (
        (getTitleCompanySelectedData && getTitleCompanySelectedData.titleCompanyName && (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)) ? 
            <Modal
                title={<div style={{display:"flex",alignItems:"center"}}><ExclamationCircleOutlined style={{color:"yellowgreen" ,fontSize:"20px",marginRight:"10px"}} />Title Company Already Selected</div>}
                open={true}
                onCancel={handleTitleCompanyCancel}
                style={{ marginTop: "-50px" }}
                footer={false}
            > 
                {/* need testing discussion */}
                <TitleCompanyEditModal
                    titleCompany={getTitleCompanySelectedData?.titleCompanyName}
                    firstAgent={ ((selectedStatus==="SELLER") && (getTitleCompanySelectedData?.selectedBy==="SELLER_AGENT")) ? getIndividualTransactionData?.sellerAgentIds?.[0]?.fullName : getIndividualTransactionData?.buyerAgentIds?.[0]?.fullName}
                    selectedBy={getTitleCompanySelectedData?.selectedBy}
                />
            </Modal> :
            <Modal
                width={"700px"}
                open={true}
                onCancel={handleTitleCompanyCancel}
                title={
                    <span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>
                    { ((companyModalStep?.step >= 1) && (companyModalStep?.titleCompany))  ? (
                        <div style={{ display: "flex", alignItems: "center",height:"35px"}}>
                        <div style={{alignItems: "center", height: companyModalStep?.titleCompany?.name === "Land Title" ? "55px":"40px"}}>
                            <img
                            src={companyModalStep?.titleCompany?.officeLogo}
                            style={{
                                width: "auto",
                                height: "100%",
                                objectFit: "contain",
                                maxWidth: "100%"
                            }}
                            alt=""
                            />
                        </div>
                        <text style={{ fontSize: "22px", fontWeight: "bold", paddingLeft: "10px", textDecoration: "underline" }}>{companyModalStep?.titleCompany?.name}</text>{" "}
                        </div>
                    ) : (
                        <text style={{ fontWeight: "bold" }}>ADD TITLE COMPANY</text>
                    )}
                    </span>
                }
                footer={false}
                style={{ marginTop: "-50px" }}
            >
                <div >
                    <Steps current={companyModalStep.step}>
                    {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                    ))}
                    </Steps>

                    <div className="steps-content" style={{ marginTop: "20px",height:"420px"}}>
                    {steps[companyModalStep.step]?.content}
                    </div>

                    <div className="steps-action" style={{ marginTop: "25px" }}>
                    {companyModalStep.step > 0 && (
                        <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => prev()}
                        >
                        Previous
                        </Button>
                    )}
                    {companyModalStep.step === 0 && (
                        <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={handleTitleCompanyCancel}
                        >
                        Cancel
                        </Button>
                    )}
                    {(companyModalStep.step < steps.length - 1)  && (
                        <Button
                        type="primary"
                        disabled={(((companyModalStep.step === 0) && (!companyModalStep.titleCompany)) || ((companyModalStep.step === 1) && (!companyModalStep.titleOffice)))}
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => next()}
                        >
                        Next
                        </Button>
                    )}
                    {
                        ((companyModalStep.step === 1) || (companyModalStep.step === 2)) && (
                        <Button
                            type="primary"
                            onClick={handlesave}
                            disabled={(((!companyModalStep.titleCompany) || (!companyModalStep?.titleOffice?.email) || companyModalStep.titleCloser || companyModalStep.salesRep) && (companyModalStep.step !== 2)) || ((!companyModalStep.titleCloser && !companyModalStep.salesRep) && (companyModalStep.step === 2))}
                        >
                            Save
                        </Button>
                        )
                    }
                    </div>
                </div>
            </Modal>
    )
}

const TitleCompanyEditModal = ({ titleCompany, firstAgent, selectedBy }) => {
    return (
      <div>
          <p style={{ color: "gray", fontSize: "16px", fontWeight: "500" }}>
          <span style={{ color: "#1890ff", fontWeight: "bold", fontStyle: "italic" }}>{titleCompany}</span> has already been ordered by{' '}
          <span style={{ color:selectedBy==="BUYER_AGENT"? "#ffce51": "#2976be", fontWeight: "bold" }}>{firstAgent}</span>. If a change is required, please
          contact <span style={{ color:selectedBy==="BUYER_AGENT"? "#ffce51": "#2976be", fontWeight: "bold" }}>{firstAgent}</span>.
        </p>
      </div>
    );
};

export {TitleCompanyDetails}
