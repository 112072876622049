import { documentConstants } from "../action-types";

const initialState = {
  createCustomerData: null,
  createCustomerLoading: true,
  createCustomerError: null,
  updateSafetyDriveClientDetails: false,
  zipId: null,
  clientType: null,
  safetyDriveClientDetailsSuccessCount: 0,
};

const createCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createCustomerData: action.createCustomerData,
        createCustomerLoading: action.createCustomerLoading,
        personError: null,
      };
    case documentConstants.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        createCustomerData: [],
        createCustomerLoading: action.createCustomerLoading,
        createCustomerError: action.createCustomerError,
      };
      case documentConstants.CREATE_CUSTOMER_LOADING:
      return {
        ...state,
        createCustomerData: [],
        createCustomerLoading: action.createCustomerLoading,
        createCustomerError: action.createCustomerError,
      };
    case documentConstants.CLEAR_CUSTOMER_CREATE_SUCCESS_DATA: {
      return {
        ...state,
        createCustomerLoading: true,
      };
    }
    case documentConstants.RESET_CUSTOMER_DATA:
      return {
        ...state,
        createCustomerData: [],
        createCustomerError: null,
      };
    case documentConstants.RESET_CREATE_CUSTOMER:
      return {
        ...state,
        createCustomerLoading: true,
      };
    case documentConstants.UPDATE_SAFETY_DRIVE_CLIENT_DETAILS:
      return {
        ...state,
        updateSafetyDriveClientDetails: action.updateSafetyDriveClientDetails,
        zipId: action.zipId,
        clientType: action.clientType
      };    
    default:
      return state;
  }
};
export { createCustomerReducer };
