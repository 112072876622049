import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { clientAction } from "../state/actions";
import { useRef } from "react";

function NameDetails({ data, onFinish }) {
  const dispatch = useDispatch();
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        firstName: data.firstName,
        middleName:
          data.middleName && data.middleName ? data.middleName : "",
        lastName: data.lastName ? data.lastName : "",
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "Please Enter First Name!" }]}
      >
        <Input ref={inputRef} placeholder="Enter First Name" allowClear autoFocus/>
      </Form.Item>
      <Form.Item label="Middle Name" name="middleName">
        <Input placeholder="Enter Middle Name" allowClear />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Please Enter Last Name!" }]}
      >
        <Input placeholder="Enter Last Name" allowClear />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { NameDetails };
