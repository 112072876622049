import { Layout } from 'antd'
import Transactions from './components/Transactions';
import { Switch, Route } from 'react-router-dom';
import { TransactionLane } from '../Rtd';
import TasksAndCalendar from "./components/TasksAndCalendar";
import { NeedTOKnow } from './components/NeedToKnow';

const NewPropertyDesign = () => {

    const { Content } = Layout;
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={["/transactions/sTask/:id","/transactions/bTask/:id","/transactions/sCalendar/:id","/transactions/bCalendar/:id"]}
                    component={TasksAndCalendar}
                />
                <Route
                    exact
                    path={["/transactions/sDashboard/needToKnow/:id", "/transactions/bDashboard/needToKnow/:id"]}
                    component={NeedTOKnow}
                />
                <Route
                    exact
                    path={["/transactions/sDashboard/:id", "/transactions/bDashboard/:id"]}
                    component={TransactionLane}
                />
                <Route exact path="/transactions">
                    <Layout
                        style={{
                            background: "#fff",
                        }}
                    >
                        <Content>
                            <Transactions />
                        </Content>
                    </Layout>
                </Route>
            </Switch>
        </>
    )
}

export default NewPropertyDesign
