import { Empty } from "antd";
import React from "react";

const EmptyData = () => {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span> </span>} />
  );
};

export { EmptyData };
