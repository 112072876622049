import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { App } from "./App";
import "antd/dist/antd.min.css";
import { removeSelection } from "./Tools/components/Document";
import "./App.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

let start = new Date().getTime();
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
let end = new Date().getTime();

serviceWorkerRegistration.register();
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.ready.then((registration) => {
//     registration.unregister().then(() => {
//       //reloading the page to clear the service worker cache
//       window.location.reload(true);
//     });
//   });
// }


console.log("benchmark(Time to load) :", end - start);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

function closeResiduals() {
  document.body.addEventListener("click", function () {
    document.getElementById("top-bar")?.remove();
    removeSelection();
  });
}
closeResiduals();
